import { axiosClient } from './axiosClient'
import { handleServerError } from 'utils/handleError'

export const getSettings = async () => {
  try {
    const { data } = await axiosClient.get('/setting')
    return data
  } catch (error) {
    return handleServerError(error)
  }
}
