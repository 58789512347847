import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'

type Attachments<T> = { attachments: T }

export type OccurrencyData = {
  email: string
  htmlMessage: string
} & Attachments<File[]>

export type PolicyEmailData = {
  email: string
  htmlMessage: string
} & Attachments<File>

export type BrokerAppointmentEmailData = {
  email: string
  htmlMessage: string
} & Attachments<File[]>

export const sendOccurenceEmail = async (data: OccurrencyData) => {
  const form = new FormData()
  form.append('email', data.email)
  form.append('htmlMessage', data.htmlMessage)
  data.attachments.forEach(file => {
    form.append('attachments', file)
  })
  try {
    return await axiosClient.post('/email/occurrence', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}

export const sendPolicyEmail = async (data: PolicyEmailData) => {
  const form = new FormData()
  form.append('email', data.email)
  form.append('htmlMessage', data.htmlMessage)
  form.append('attachments', data.attachments)

  try {
    return await axiosClient.post('/email/simulator', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}

export const sendBrokerAppointmentEmail = async (
  data: BrokerAppointmentEmailData,
) => {
  const form = new FormData()
  form.append('email', data.email)
  form.append('htmlMessage', data.htmlMessage)
  data.attachments.forEach(file => {
    form.append('attachments', file)
  })
  try {
    return await axiosClient.post('/email/broker-appoinment', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}

export const sendUserInviteEmail = async ({
  email,
  htmlMessage,
}: {
  email: string
  htmlMessage: string
}) => {
  try {
    return await axiosClient.post('/email/client-invite', {
      email,
      htmlMessage,
    })
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}

export const sendRecoveryEmail = async ({
  email,
  htmlMessage,
}: {
  email: string
  htmlMessage: string
}) => {
  try {
    return await axiosClient.post('/email/recovery', {
      email,
      htmlMessage,
    })
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}

export const sendEmail = async ({
  email,
  htmlMessage,
  subject,
}: {
  email: string
  htmlMessage: string
  subject: string
}) => {
  try {
    return await axiosClient.post('/email', {
      email,
      htmlMessage,
      subject,
    })
  } catch (error) {
    throw error
  }
}
