import { FilledButton, Wrapper } from 'components'
import { Download } from 'components/Icons'
import { locale } from 'locale'
import { Link } from 'react-router-dom'

export const Campaign = ({ content, image }: { content: any; image: any }) => {
  const { title, body } = content
  const formatedBody = JSON.parse(body)

  if (formatedBody.length === 0) {
    return null
  }

  return (
    <div className="py-8 bg-dashboardCampaign">
      <Wrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-8">
          <div>
            <h2 className="font-mont text-brand pb-1">Campanha</h2>
            <h1 className="text-mango font-mont pb-4">{title}</h1>
            {formatedBody.map((item: any, index: number) => {
              switch (item.type) {
                case 'header':
                  return (
                    <h3
                      key={`campaign${index}`}
                      className="font-mont text-white font-medium text-lg pb-4"
                    >
                      {item.data.text}
                    </h3>
                  )

                case 'fileList':
                  return (
                    <div key={`campaign${index}`} className="w-full">
                      <p className="text-white whitespace-nowrap font-lato text-md leading-7 pb-4 w-full">
                        {item.data.title}
                      </p>
                      <Link
                        className="inline-flex items-center"
                        to={item.data.items[0].file}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="text-brand w-4 h-4 inline-block">
                          <Download />
                        </span>
                        <span className="font-mont text-sm text-brand px-4 uppercase">
                          {item.data.items[0].label}
                        </span>
                      </Link>
                    </div>
                  )

                default:
                case 'paragraph':
                  return (
                    <p
                      key={`campaign${index}`}
                      className="text-white font-lato text-md leading-7 pb-4"
                    >
                      {item.data.text}
                    </p>
                  )
              }
            })}
          </div>
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:items-end">
              <img src={image} alt="CampaignImage" />
              <FilledButton
                className="text-sm "
                url="/communications/campaign"
                label={locale.seeAllCampaigns}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
