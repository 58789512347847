import { locale } from 'locale'
import { formatDate } from 'utils/dates'
import { FilledButton } from 'components'

import { BodyItem, ListFileItemType } from 'types/editor'
import { AttachmentType } from 'types/attachment'
import LOGO from 'emails/images/logo.png'
type CampaignsDataType = {
  attachments: AttachmentType[]
  body: string
  id: number
  publish_at: string
  title: string
  type: string
}

export const Campaigns = ({ data }: { data: CampaignsDataType[] }) => {
  return (
    <div className="flex items-start justify-start flex-wrap gap-4">
      {data.map((campaign, index) => {
        const image = campaign.attachments.filter(attach => attach.url)[0]
        const body = JSON.parse(campaign.body)
        const fileList = body.filter(
          (item: BodyItem) => item.type === 'fileList',
        )

        return (
          <div
            key={`campaign${index}`}
            className="block w-full md:w-52 min-h-72 shadow-md rounded-lg"
          >
            <img
              className="w-full object-scale-down block m-0 rounded-t-lg "
              src={image?.url ?? LOGO}
              alt={image?.file_name! ?? 'campaign image'}
            />
            <div className="flex flex-col py-6 gap-4">
              <h3 className="font-lato text-base font-medium text-brand text-center">
                {campaign.title}
              </h3>
              <p className="font-mont text-sm text-center">
                {formatDate(campaign.publish_at).dashed}
              </p>
              <div className="flex justify-center">
                {fileList[0]?.data.items.map(
                  (attachment: ListFileItemType, index: number) => {
                    return (
                      <FilledButton
                        key={`download_${index}`}
                        label={locale.download}
                        url={attachment.file}
                      />
                    )
                  },
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
