import React, { useContext, useEffect, useState } from 'react'

import { setCookie, deleteCookie, getCookie } from 'cookies-next'
import { AuthContextType } from 'types'
import { getMe } from 'services/user'
import { clientSignIn, loginRequest } from 'services/auth'
import { useMutation } from '@tanstack/react-query'
import { AppContext } from './AppContext'

export const AuthContext = React.createContext<AuthContextType>({
  currentUser: null,
  isSignedIn: false,
  // eslint-disable-next-line unused-imports/no-unused-vars
  login: ({ username, password }: any) => Promise.resolve(),
  // eslint-disable-next-line unused-imports/no-unused-vars
  clientLogin: ({ nif, password }: any) => Promise.resolve(),
  isLoginLoading: false,
  logout: () => {},
  user: {},
})

export const AuthProvider = ({ children }: { children: any }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const { getPageSettings } = useContext(AppContext)

  const isSignedIn = !!getCookie('acessToken')

  const { mutateAsync: login, isPending } = useMutation({
    mutationKey: ['login'],
    mutationFn: async ({
      username,
      password,
    }: {
      username: string
      password: string
    }) => await loginRequest({ username, password }),
    onSuccess: (response: any) => {
      if (response?.accessToken) {
        setCookie('acessToken', response.accessToken)
      }
    },
  })

  const { mutateAsync: clientLogin, isPending: isClientLoginPending } =
    useMutation({
      mutationKey: ['clientLogin'],
      mutationFn: async ({
        nif,
        password,
      }: {
        nif: string
        password: string
      }) => await clientSignIn({ nif, password }),
      onSuccess: (response: any) => {
        if (response?.accessToken) {
          setCookie('acessToken', response.accessToken)
          handleFetchUser()
        }
      },
    })

  const logout = () => {
    deleteCookie('acessToken')
    setCurrentUser(null)
  }

  const handleFetchUser = async () => {
    if (isSignedIn && currentUser === null) {
      const user = await getMe()
      setCurrentUser(user)
      await getPageSettings()
    }
  }

  useEffect(() => {
    handleFetchUser()
  }, [isSignedIn, currentUser])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isSignedIn,
        login,
        clientLogin,
        isLoginLoading: isPending || isClientLoginPending,
        logout,
        user: {},
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
