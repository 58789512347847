import { useState } from 'react'
import { AccordionItemType, BodyItemData } from 'types/editor'

export const Accordion = ({ data }: { data: BodyItemData }) => {
  const initialState = (data.items && data.items.map(() => -1)) || []
  const [open, setOpen] = useState(initialState)
  const after =
    'after:block after:w-2 after:h-2 after:border-t-brand after:border-t-[1px] after:border-r-[1px] after:border-r-brand after:origin-center after:transition-all after:duration-700 after:ease-in-out'

  const handleAccordion = (index: number) => {
    setOpen(prevState => {
      if (!prevState.includes(index)) {
        return [...prevState, index]
      } else {
        return prevState.filter(item => item !== index)
      }
    })
  }

  return (
    <>
      <h3 className="pb-4 font-lato text-sm font-medium leading-4 text-darkBlue">
        {data.title}
      </h3>
      {data.items?.map((item, index) => (
        <div
          key={`process_accordion_item_${index}`}
          className="pb-4 mb-4 border-b-2 border-dashed border-cloudyBlue"
        >
          <button
            key={`process_accordion_item_title_${index}`}
            onClick={() => handleAccordion(index)}
            className={`flex items-center justify-between w-full pb-2 font-lato text-base leading-7 font-bold text-black text-left cursor-pointer ${after} ${
              open.includes(index)
                ? 'after:-rotate-45'
                : 'after:rotate-[135deg]'
            }`}
          >
            {(item as AccordionItemType).label}
          </button>
          <p
            key={`process_accordion_item_text_${index}`}
            className={`font-lato text-base leading-7 font-normal text-black transition-all duration-700 ease-in-out overflow-hidden ${
              open.includes(index) ? 'max-h-52' : 'max-h-0'
            }`}
          >
            {(item as AccordionItemType).text}
          </p>
        </div>
      ))}
    </>
  )
}
