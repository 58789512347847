import axios, { AxiosError } from 'axios'
import { APP_BASE_CONFIG } from 'config'

import { deleteCookie, getCookie } from 'cookies-next'

export const axiosClient = axios.create({
  baseURL: APP_BASE_CONFIG.BASE_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
})

axiosClient.interceptors.request.use(req => {
  const token = getCookie('acessToken')
  if (token) {
    req.headers.Authorization = `Bearer ${token}`
  }
  return req
})

axiosClient.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      deleteCookie('acessToken')
      return (window.location.href = '/login')
    }
    throw error
  },
)
