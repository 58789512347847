import { Logo } from 'components/Icons/logo'
import { Wrapper } from 'components/Layout'

export const HeaderPublic = () => {
  return (
    <header className="divide-y-8  divide-solid divide-brand border-b-8 border-brand">
      <Wrapper>
        <div className="py-6">
          <div className="w-[150px] h-[82px]">
            <Logo />
          </div>
        </div>
      </Wrapper>
    </header>
  )
}
