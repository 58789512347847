export const formatCurrency = (amount: number): string => {
  const hasDecimal = amount % 1 !== 0

  if (hasDecimal) {
    return amount.toLocaleString('pt-PT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else {
    return amount.toLocaleString('pt-PT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }
}
