import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'

export const getDashboard = async () => {
  try {
    const { data } = await axiosClient.get('/dashboard')
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getGroup = async () => {
  try {
    const { data } = await axiosClient.get('/group')
    return data
  } catch (error) {
    return handleServerError(error)
  }
}
