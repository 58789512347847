export const MenuCommunications = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
    <path
      fill='currentcolor'
      d='M32.2 13L16.36 6l-.18-.08a1 1 0 00-.49-.16c-.45 0-.64.42-.73.62l-.06.13c-.11.25-.27.61-.41 1s-.22.48-.33.75a10.93 10.93 0 01-2.51 3.9 9.58 9.58 0 01-6.1 3.1l-.32.05a.55.55 0 00-.38.28.92.92 0 00.1.8l7.33 15.8a1.41 1.41 0 001.29.81 1.52 1.52 0 00.63-.14h.06A1.45 1.45 0 0015 31L9.46 18.58a7.85 7.85 0 005.18-1.51l9.18 9.2a1.16 1.16 0 00.86.36 1.56 1.56 0 001.09-.49 1.36 1.36 0 00.14-2l-9.21-9.26a7.45 7.45 0 001.52-5l12.71 5.9a1.39 1.39 0 00.57.12 1.46 1.46 0 001.31-.9v-.08a1.53 1.53 0 00.07-1.09 1.34 1.34 0 00-.68-.83z'
    />
    <path
      fill='currentcolor'
      d='M14.3 5.36a.5.5 0 00-.19-.48 6.32 6.32 0 00-3.84-1.29 7.19 7.19 0 00-4 1.23l-.54-.34a1.26 1.26 0 00-.33-1.11 1.32 1.32 0 00-1.83 0 1.29 1.29 0 000 1.83 1.31 1.31 0 00.85.38l.26.68a7.22 7.22 0 00-.86 8 .51.51 0 00.44.26c8.5-.52 10.03-9.07 10.04-9.16z'
    />
  </svg>
);
