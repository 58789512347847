import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'
import { Client } from 'types/clients'

export const getClientById = async (id: string) => {
  try {
    const { data } = await axiosClient.get(`client/${id}`)
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const clientCheck = async ({
  email,
  phone,
  nif,
}: {
  email: string
  phone: string
  nif: string
}) => {
  try {
    const { data } = await axiosClient.get('isaac/client-check', {
      params: {
        email,
        phone,
        nif,
      },
    })
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const createClient = async (
  body: Pick<Client, 'email' | 'phone' | 'name' | 'nif'>,
): Promise<Client> => {
  try {
    const { data } = await axiosClient.post('client', body)
    return data
  } catch (error) {
    return handleServerError(error).error as any
  }
}

export const updateClient = async ({
  id,
  data,
}: {
  id: number
  data: Partial<Client>
}) => {
  try {
    const { data: client } = await axiosClient.patch(`client/${id}`, data)
    return client
  } catch (error) {
    return handleServerError(error)
  }
}
