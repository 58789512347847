export const FormTextArea = ({
  formik,
  name,
  label,
  required,
  placeholder,
  extraClasses,
  onChange,
}: {
  formik: any;
  name: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  extraClasses?: string;
  onChange?: () => void;
}) => {
  const { error, touched } = formik.getFieldMeta(name);
  return (
    <div className={`w-full mb-3 ${extraClasses}`}>
      {label && (
        <label className='font-lato text-sm leading-5 text-darkBlue pb-2 block'>
          {label}
          {required && <span className='text-brand'>*</span>}
        </label>
      )}
      <textarea
        className={`font-lato text-sm font-semibold text-black px-4 leading-[48px] w-full rounded border h-28 ${
          error ? 'border-error' : 'border-black'
        } bg-transparent outline-none placeholder:text-grey focus:outline-none disabled:bg-lightGrey disabled:cursor-not-allowed`}
        value={formik.getFieldProps(name).value}
        placeholder={placeholder}
        onChange={
          onChange
            ? onChange
            : (value) => formik.setFieldValue(name, value.target.value)
        }
      />
      {error && touched && (
        <span className='py-2 block w-full font-lato text-xs '>{error}</span>
      )}
    </div>
  );
};

/*
export const FormTextarea = ({formik, label, name, isSmall, ...rest}) => {
    const field = formik.getFieldProps(name);
    const {error, touched} = formik.getFieldMeta(name);
    return (
      <Group {...rest}>
        {label && <Label {...rest}>{label}</Label>}
        <Textarea
          {...field}
          error={error === undefined ? false : true}
          disabled={rest.disabled}
          isSmall={isSmall}
        />
        {error && touched && <Error>{error}</Error>}
      </Group>
    );
  };


  export const Textarea = styled.textarea`
  font-family: Lato;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.colors.darkBlue};
  padding: 1rem;
  text-align: left;
  line-height: 1.5rem;
  margin: ${props => (props.margin ? props.margin : '0')};
  height: ${props => (props.height ? props.height : 'auto')};
  width: 100%;
  border-radius: 4px;
  border: ${props =>
    props.error
      ? `1px solid ${theme.colors.error}`
      : `1px solid ${theme.colors.darkBlue}`};
  background: transparent;
  outline: none;
  resize: none;

  ::placeholder {
    color: ${theme.colors.grey};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }

  &[disabled] {
    background-color: ${theme.colors.lightGrey};
    cursor: not-allowed;
  }
`;
*/
