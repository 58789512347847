import { useState } from 'react'
import { Loader, Modal, Wrapper } from 'components'
import { BrokerForm } from 'components/Form/brokerForm'
import { ReviewIntro } from './reviewIntro'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getBrokerByReviewToken } from 'services/broker'

export const ReviewBroker = () => {
  const { reviewToken } = useParams()
  const [reviewData, setReviewData] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['getBrokerByReviewToken', reviewToken],
    queryFn: () => getBrokerByReviewToken(reviewToken!),
  })

  if (isLoading || !data) {
    return <Loader />
  }

  return (
    <>
      {reviewData ? (
        <div className="w-full flex-1 py-12">
          <Wrapper>
            <Modal onClose={() => {}}>
              <h2 className="font-mont font-medium text-xl leading-4 text-brand py-4">
                Revisão de dados
              </h2>
              <div className="flex flex-col gap-y-4 font-lato font-normal text-base text-darkGrey">
                <p>
                  Os seus dados corrigidos foram enviados para a Innovarisk e em
                  breve irá obter uma resposta.
                </p>
                <p>Obrigado.</p>
              </div>
            </Modal>
          </Wrapper>
        </div>
      ) : (
        <div className="w-full flex-1 py-12">
          <Wrapper>
            <div className="flex flex-col gap-y-8 justify-center items-start divide-y-2 divide-cloudyBlue md:grid md:grid-cols-2 md:grid-rows-none md:divide-y-0 md:divide-x-2">
              <ReviewIntro observations={data?.observations} />
              <BrokerForm
                type="review"
                broker={data}
                reviewToken={reviewToken}
                onSuccess={() => setReviewData(true)}
              />
            </div>
          </Wrapper>
        </div>
      )}
    </>
  )
}
