import { axiosClient } from './axiosClient'

export const loginRequest = async ({
  username,
  password,
}: {
  username: string
  password: string
}) => {
  try {
    const { data } = await axiosClient.post('/auth/signin', {
      username,
      password,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const changePasswordRequest = async ({
  newPassword,
}: {
  newPassword: string
}) => {
  try {
    const { data } = await axiosClient.post('/auth/change-password', {
      newPassword,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const resetPassword = async ({
  token,
  newPassword,
}: {
  token: string
  newPassword: string
}) => {
  try {
    const { data } = await axiosClient.post('/auth/reset-password', {
      token,
      newPassword,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const recoverPassword = async (
  username: string,
  type: 'broker' | 'client',
): Promise<{ email: string; recovery_token: string }> => {
  try {
    const { data } = await axiosClient.post('/auth/recover-password', {
      username,
      type,
    })

    return data
  } catch (error) {
    throw new Error('Por favor verifique o email inserido')
  }
}

export const clientSignIn = async ({
  nif,
  password,
}: {
  nif: string
  password: string
}) => {
  try {
    const { data } = await axiosClient.post('/auth/client/signin', {
      nif,
      password,
    })
    console.log({ data })
    return data
  } catch (error) {
    throw error
    console.log({ error })
  }
}
