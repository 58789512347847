import {
  FilledButton,
  FormPassword,
  LoaderInline,
  PasswordHelper,
  passwordValidator,
} from 'components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { locale } from 'locale'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { changePasswordRequest } from 'services/auth'

const validationPassword = Yup.object().shape({
  newPassword: Yup.string()
    .required(locale.required)
    .min(6, locale.passwordMin),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword')],
    'Passwords têm de ser iguais',
  ),
})

export const EditPassword = ({
  notify,
}: {
  notify: (value?: string, type?: string) => void
}) => {
  const [newPassword, setNewPassword] = useState<string>('')

  const { mutateAsync: handlePassword, isPending: isPendingPasswordChange } =
    useMutation({
      mutationKey: ['changePassword'],
      mutationFn: async ({ newPassword }: { newPassword: string }) =>
        await changePasswordRequest({ newPassword }),
      onSuccess: () => notify(locale.passwordUpdated, 'success'),
      onError: err => notify(err?.message),
    })

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationPassword,
    validateOnBlur: false,
    validateOnChange: true,
    validate: values => setNewPassword(values.newPassword),
    onSubmit: async values => {
      const { caps, length, normal, number, special } = passwordValidator(
        values.newPassword,
      )

      if (!caps || !length || !normal || !number || !special)
        return notify(
          'A nova password não está dentro do padrão necessário',
          'error',
        )

      if (values.newPassword !== values.confirmPassword)
        formik.setErrors({ confirmPassword: 'Passwords têm de ser iguais' })

      await handlePassword({ newPassword: values.confirmPassword })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="py-8 text-darkBlue text-xl">{locale.passwordChange}</h2>

      <FormPassword
        name="newPassword"
        label={locale.newPassword}
        formik={formik}
      />

      <FormPassword
        name="confirmPassword"
        label={locale.confirmPassword}
        formik={formik}
      />

      {isPendingPasswordChange ? (
        <LoaderInline />
      ) : (
        <FilledButton type="submit" label={locale.submit} />
      )}

      <PasswordHelper password={newPassword} />
    </form>
  )
}
