import { useContext } from 'react'

import { AuthContext } from '../../contexts/AuthContext'
import { AppContext } from 'contexts/AppContext'
import { Loader } from 'components/Loader'
import { Navbar } from 'components/Navbar'
import { MenuHome, MenuProcesses, MenuProducts } from 'components/Icons'
import { SubMenuType } from 'types/navbar'
import { LocaleType, locale } from 'locale'
import { Client } from 'types/clients'

const HeaderClient = () => {
  const { logout, currentUser } = useContext(AuthContext)
  const { products, process } = useContext(AppContext)
  const user = currentUser as unknown as Client

  let paymentsSubMenu: SubMenuType = {}
  let productSubMenu: SubMenuType = {}

  if (process) {
    paymentsSubMenu.type = 'pagamentos'
    paymentsSubMenu.label =
      'Toda a informação sobre a regularização dos seguros.'
    paymentsSubMenu.columns = [
      {
        label: locale.process.plural + ' ' + locale.administrative,
        items: process.content
          .filter(process => process.slug === 'pagamentos')
          .map(process => ({ label: process.title, slug: process.slug })),
      },
    ]

    const groupBy = (input: any, key: string) => {
      return input.reduce((acc: any, currentValue: any) => {
        let groupKey = currentValue[key]
        if (!acc[groupKey]) {
          acc[groupKey] = []
        }
        acc[groupKey].push(currentValue)
        return acc
      }, {})
    }

    const productColumns = groupBy(products, 'type')
    const productsSubmenu = Object.entries(productColumns).map(
      ([key, value]: [key: string, value: any]) => {
        return {
          label: locale[key as LocaleType] as string,
          items: value.map((product: any) => {
            return {
              label: product.short_title ?? product.title,
              slug: product.slug,
            }
          }),
        }
      },
    )

    productSubMenu = {
      type: 'produto',
      label: 'Conheça a nossa oferta de produtos',
      columns: productsSubmenu,
    }
  }

  if (!paymentsSubMenu && !productSubMenu && !currentUser) {
    return <Loader />
  }

  return (
    <Navbar
      activeItem={window && window.location.pathname}
      topBar={{
        items: [
          {
            label: [user?.name, user?.nif].filter(n => n).join(' - '),
            url: '/profile',
          },
        ],

        onLogout: logout,
      }}
      bottomBar={[
        {
          label: 'Produtos',
          url: '#',
          hasSubMenu: true,
          submenu: productSubMenu,
          icon: MenuProducts,
        },
        {
          label: 'Consultas',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: '',
            label: 'Toda a informação.',
            columns: [
              {
                label: 'Consultas',
                items: [
                  {
                    label: 'Apólices',
                    slug: 'policies',
                  },
                  {
                    label: 'Recibos',
                    slug: 'invoices',
                  },
                ],
              },
            ],
          },
          icon: MenuHome,
        },
        {
          label: 'Sinistros',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'sinistros',
            label: 'Toda a informação sobre sinistros.',
            columns: [
              {
                label: 'Participação de sinistro',
                items: [
                  {
                    label: 'Participação de sinistro',
                    slug: 'sinistros',
                  },
                ],
              },
            ],
          },
          icon: MenuProcesses,
        },
      ]}
    />
  )
}

export default HeaderClient
