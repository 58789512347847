import { useContext } from 'react'
import { AuthContext } from 'contexts/AuthContext'
import { PolicyBroker } from './policyBroker'
import { PolicyClient } from './policyClient'

export const Policy = () => {
  const { currentUser } = useContext(AuthContext)

  return <>{currentUser?.role ? <PolicyBroker /> : <PolicyClient />}</>
}
