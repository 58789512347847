import { useContext, useState } from 'react'
import { locale } from 'locale'
import {
  Badge,
  Dropzone,
  FileGroup,
  FileType,
  FilledButton,
  Loader,
  LoaderInline,
  Wrapper,
  useNotification,
} from 'components'
import TransparentButton from 'components/Buttons/transparent'
import { Download, Send } from 'components/Icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  getPolicyDocumentation,
  getQuotations,
  sendPolicyDocuments,
} from 'services/policies'
import { AuthContext } from 'contexts/AuthContext'
import { useParams } from 'react-router-dom'
import { formatCurrency } from 'utils/currency'
import { sendPolicyEmail } from 'services/email'
import { SimulatorEmail } from 'emails/simulator'
import { render } from '@react-email/render'

const initialFiles: FileType[] = []

const MAX_FILES = 2

export const Cotation = () => {
  const { id } = useParams() as { id: string }
  const { notify } = useNotification()
  const [files, setFiles] = useState(initialFiles)
  const { currentUser } = useContext(AuthContext)

  const { data, isLoading } = useQuery({
    queryKey: ['getQuotation', id],
    enabled: !!id,
    retry: false,
    queryFn: () =>
      getQuotations({
        params: {
          policyNo: +id,
          contactNo: currentUser?.contact_no!,
          brokerNo: currentUser?.broker?.broker_no!,
        },
      }),
  })

  const { mutateAsync: downloadDocs, isPending: downloadDocsPending } =
    useMutation({
      mutationKey: ['downloadDocs'],
      mutationFn: async () =>
        await getPolicyDocumentation({
          policyNo: +id,
          eventNo: data[0]?.LastEvent,
        }),
      onSuccess: data => handleDownload(data),
      onError: () => notify('Erro ao fazer download', 'error'),
    })

  const { mutateAsync: sendDocsEmail, isPending: emailDocsPending } =
    useMutation({
      mutationKey: ['sendDocsEmail'],
      mutationFn: async () =>
        await getPolicyDocumentation({
          policyNo: +id,
          eventNo: data[0]?.LastEvent,
        }),
      onSuccess: data => sendByEmailHandler(data),
      onError: () => notify('Erro ao fazer download', 'error'),
    })

  const handleDownload = (data: ArrayBuffer) => {
    const blob = new Blob([data], { type: 'application/pdf' })
    window.open(URL.createObjectURL(blob))
  }

  const sendByEmailHandler = async (data: ArrayBuffer) => {
    if (currentUser && data) {
      const blob = new Blob([data], { type: 'application/pdf' })
      const file = new File([blob], `simulacao_apolice_${id}.pdf`, {
        type: 'application/pdf',
        lastModified: Date.now(),
      })

      const html = render(
        <SimulatorEmail
          name={currentUser.forename!}
          surname={currentUser.surname!}
        />,
        {
          pretty: true,
        },
      )

      const values = {
        email: currentUser.email,
        attachments: file,
        htmlMessage: html,
      }

      try {
        await sendPolicyEmail(values)
        notify('Email enviado com sucesso', 'success')
      } catch (error) {
        error instanceof Error && notify(error.message, 'error')
      }
    } else {
      notify('No file')
    }
  }

  const { mutateAsync: uploadFiles } = useMutation({
    mutationKey: ['uploadDocs'],
    mutationFn: async () =>
      await sendPolicyDocuments({ policyNo: +id, files: files as File[] }),
  })

  const isDownloadPending = downloadDocsPending || emailDocsPending

  if (isLoading) {
    return <Loader />
  }

  const onAddFile = async (acceptedFiles: FileType[]) => {
    if (files.length < MAX_FILES) {
      setFiles(prevFiles => [...prevFiles, ...acceptedFiles])
    }
  }

  const onRemoveFile = (item: FileType) => {
    const newFiles = files.filter((file: FileType) => file.name !== item.name)
    setFiles(newFiles)
  }

  /* const questions = data.policies_view[0].product?.questions
  const answers = data.policies_view[0].answers
  const status = data.policies_view[0].policy_status
  const allowSubmission = data.policies_view[0].user?.broker?.allow_submission */

  const status = data[0]['Estado'] // getStatus(status)

  const renderPolicyData = () => {
    const policy = data[0]
    const policyDetails = Object.keys(policy).map(key => {
      // we don't want to show the LastEvent key
      if (key === 'LastEvent') return

      const premiumKeys = ['Prémio Anual', 'Capital Seguro']
      const value = premiumKeys.includes(key)
        ? formatCurrency(policy[key])
        : policy[key]

      return {
        label: key,
        value,
      }
    })
    return (
      <table className="border-collapse w-full border-none">
        <tbody>
          {policyDetails
            //need to filter out the undefined values (LastEvent key)
            .filter(Boolean)
            .map(({ label, value }: any, index: number) => (
              <tr key={`row${index}`} className="cursor-default h-8">
                <td className="text-grey font-lato text-sm pl-4">{label}</td>
                <td className="text-end text-black font-lato text-sm font-semibold pr-4 border-t border-lightGrey">
                  {value}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    )
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <h2 className="uppercase text-sm font-medium text-darkBlue">Cotação</h2>
        <h1 className="pb-10 text-xxxl font-medium text-brand">
          {data[0]['Produto'] || 'Produto indisponível'}
        </h1>
        <h2 className="pb-10 text-xl font-medium font-mont text-darkBlue">
          Confirmar os dados para emitir a proposta
        </h2>
        <div className="flex flex-col gap-y-8 justify-center items-start divide-y-2 divide-cloudyBlue md:grid md:grid-cols-2 md:grid-rows-none md:divide-y-0 md:divide-x-2">
          <div className="w-full md:pr-8">
            {renderPolicyData()}
            <div className="flex justify-between items-center bg-lightpink px-4 py-4 mt-7 text-lg font-mont font-medium">
              <span className="flex justify-between w-full">
                <p>Prémio total</p>
                <p>{formatCurrency(data[0]['Prémio Anual'])}</p>
              </span>
            </div>
          </div>
          <div className="pt-8 md:pl-8">
            <div className="mb-10">
              <h4 className="text-lato text-sm text-darkBlue pb-4 font-medium uppercase">
                {locale.status}
              </h4>
              <Badge label={status} status={status.toLowerCase()} />
            </div>
            <div className="mb-10">
              <h4 className="text-lato text-sm text-darkBlue pb-4 font-medium uppercase">
                {locale.messages}
              </h4>
              <p>{locale.cotation.message['draft']}</p>
            </div>

            <>
              <div className="mb-10">
                <h4 className="text-lato text-sm text-darkBlue pb-4 font-medium uppercase">
                  DOWNLOAD DA COTAÇÃO
                </h4>

                {isDownloadPending ? (
                  <LoaderInline />
                ) : (
                  <div className="h-auto flex gap-8 text-brand text-sm uppercase">
                    <span className="flex gap-2 items-center">
                      <Download extraClasses="w-3" />
                      <TransparentButton
                        label="Download"
                        action={downloadDocs}
                        className="!text-sm !rounded !py-2 !border-none"
                        disabled={downloadDocsPending}
                      />
                    </span>

                    <span className="flex gap-1 items-center">
                      <Send extraClasses="w-4" />
                      <TransparentButton
                        label="Enviar por email"
                        action={sendDocsEmail}
                        className="!text-sm !rounded !py-2 !border-none"
                        disabled={emailDocsPending}
                      />
                    </span>
                  </div>
                )}
              </div>

              <div className="mb-10 bg-lightGrey p-6">
                <h4 className="text-lato text-sm text-darkBlue pb-4 font-medium uppercase">
                  CONVERTER COTAÇÃO EM APÓLICE
                </h4>
                <p className="pb-2">
                  Para finalizar a colocação da apólice deve inserir a
                  Proposta/Cotação assinada pelo Cliente (assinatura deve ser
                  feita na página 4)
                </p>
                <Dropzone
                  maxFiles={MAX_FILES}
                  maxSize={5242880}
                  fileTypes={{ 'images/*': ['.png', '.jpeg', '.pdf'] }}
                  onAddFile={onAddFile}
                />
                <div className="flex flex-col gap-y-4 mb-4">
                  <FileGroup
                    files={files}
                    onRemove={(file: FileType) => onRemoveFile(file)}
                  />
                </div>

                <FilledButton label="Submeter" action={uploadFiles} />
              </div>
            </>

            {(status === 'submitted' ||
              status === 'draft' ||
              status === 'expired' ||
              status === 'pending') && (
              <>
                <div className="mb-10 bg-lightGrey p-6">
                  <h4 className="text-lato text-sm text-darkBlue pb-4 font-medium uppercase">
                    VOLTAR A SIMULAR
                  </h4>
                  {true && (
                    <TransparentButton
                      className="text-sm font-medium leading-8 !text-brand hover:!text-rustyRedTwo border-brand hover:border-rustyRedTwo rounded-md"
                      label="Simular"
                    />
                  )}
                </div>
                <div className="mb-10 bg-lightGrey p-6">
                  <h4 className="text-lato text-sm text-darkBlue pb-4 font-medium uppercase">
                    APAGAR SIMULAÇÃO
                  </h4>
                  {true && (
                    <TransparentButton
                      className="text-sm font-medium leading-8 !text-brand hover:!text-rustyRedTwo border-brand hover:border-rustyRedTwo rounded-md"
                      label="Apagar Simulação"
                      action={() => {}}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
