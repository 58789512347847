import { useQuery } from '@tanstack/react-query'

import { AppContext } from 'contexts/AppContext'
import { useContext } from 'react'
import { getTrainings } from 'services/trainings'
import { TrainingParams } from 'types/training'

export const useTrainings = ({ params }: { params: TrainingParams }) => {
  const { categories } = useContext(AppContext)

  const {
    data: trainings,
    isLoading: trainingIsLoading,
    error: errorFetchingTrainings,
  } = useQuery({
    initialData: [],
    retry: false,
    queryKey: ['getTrainings', params],
    queryFn: () => getTrainings({ params }),
  })

  /* Helper function to list categories */
  const formatTrainingCategories = (categoriesIds: number[]) => {
    const namedCategories = categories
      .filter(e => categoriesIds.includes(e.id))
      .map(e => e.name)
      .join(', ')

    return !!namedCategories.length ? namedCategories : '---'
  }

  return {
    trainings,
    loading: trainingIsLoading,
    errorFetchingTrainings,
    formatTrainingCategories,
  }
}
