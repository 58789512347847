import { locale } from 'locale'
import { Loader, Table } from 'components'
import { Pdf } from 'components/Icons/pdf'
import { formatDate } from 'utils/dates'
import { AttachmentType } from 'types/attachment'
import { Link } from 'react-router-dom'

export const Others = ({ loading, data }: { loading: boolean; data: any }) => {
  if (loading) {
    return <Loader />
  }

  return (
    <Table
      columns={[
        {
          label: locale.title,
          property: 'title',
          sortable: true,
          format: item => item.title,
        },
        {
          label: locale.publishAt,
          property: 'publish_at',
          sortable: true,
          format: item => formatDate(item.publish_at).dashed,
        },
        {
          label: 'Tipo',
          property: 'type',
          sortable: true,
          format: item => {
            return item.type === 'information' ? 'Flash' : 'Newsletter'
          },
        },
        {
          label: locale.download,
          property: 'attachments',
          sortable: false,
          format: item => {
            return (
              <div className="flex justify-start">
                {item?.attachments?.map(
                  (attachment: AttachmentType, index: number) => {
                    return (
                      <Link
                        className="inline-block cursor-pointer w-4 h-4"
                        key={'pdf' + index}
                        target="_parent"
                        type="link"
                        title={attachment.file_name!}
                        to={attachment.url}
                      >
                        <Pdf />
                      </Link>
                    )
                  },
                )}
              </div>
            )
          },
        },
      ]}
      data={data}
    />
  )
}
