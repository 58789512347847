export const Add = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
    <g fill='none' fillRule='evenodd' transform='translate(4 4)'>
      <mask id='b' fill='#fff'>
        <path
          id='a'
          d='M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm0-2c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18zm-1-25v6h-6v2h6v6h2v-6h6v-2h-6v-6h-2z'
        />
      </mask>
      <g mask='url(#b)'>
        <path fill='currentcolor' d='M-4-4h48v48H-4z' />
      </g>
    </g>
  </svg>
);
