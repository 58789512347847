import { useContext } from 'react'

import { AuthContext } from '../../contexts/AuthContext'
import ProfileBroker from './ProfileBroker'
import ProfileClient from './ProfileClient'
import { Loader } from 'components'

export const Profile = () => {
  const { currentUser } = useContext(AuthContext)

  if (!currentUser) return <Loader />

  return <>{currentUser?.role ? <ProfileBroker /> : <ProfileClient />}</>
}
