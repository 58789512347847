import { FilledButton } from 'components/Buttons'
import { Wrapper } from 'components/Layout'
import { useState } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'

function Fallback({ error }: { error: Error }) {
  const navigate = useNavigate()
  const [errorVisible, setErrorVisible] = useState(false)
  return (
    <div role="alert">
      <Wrapper>
        <>
          <h1 className="font-mont text-xxxl py-8 text-brand">
            Ocorreu um Erro
          </h1>
          <p>Ocorreu um erro inesperado. Por favor, tente novamente.</p>

          <div className="flex gap-2 mt-4">
            <FilledButton
              action={() => setErrorVisible(!errorVisible)}
              label={errorVisible ? 'Esconder Detalhes' : 'Ver detalhes'}
            />
            <FilledButton
              action={() => navigate('/')}
              label="Voltar à Pagina Inicial"
            />
          </div>
        </>
        <code>
          <p
            className={`${errorVisible ? 'min-h-1 opacity-100 mt-4 bg-gray-400 p-5 rounded-xl' : 'h-0 opacity-0'} mt-6 transition-all duration-75 ease-in-out `}
          >
            {error.toString()}
          </p>
        </code>
      </Wrapper>
    </div>
  )
}

export const ErrorBoundary = ({ children }: any) => {
  return (
    <ReactErrorBoundary FallbackComponent={Fallback}>
      {children}
    </ReactErrorBoundary>
  )
}
