import { Modal } from 'components/Modal'
import { WrapperSmall } from 'components/Layout'
import { FilledButton } from 'components/Buttons'
import { NotificationContainer, useNotification } from 'components/Notification'
import { FormInput, LoaderInline } from 'components'
import { Nullable } from 'types'
import { useFormik } from 'formik'
import { useMutation } from '@tanstack/react-query'
import { recoverPassword } from 'services/auth'
import { sendRecoveryEmail } from 'services/email'
import { render } from '@react-email/components'
import { RecoverPasswordEmail } from 'emails/invites'
import { APP_BASE_CONFIG } from 'config'

const RecoverPassword = ({
  onClose,
  loginType,
}: {
  onClose: () => void
  loginType: 'broker' | 'client'
}) => {
  const { BASE_URL } = APP_BASE_CONFIG
  const { notifications, notify } = useNotification()

  const { mutateAsync: handleRecoveryEmail, isPending: recoverEmailPending } =
    useMutation({
      mutationKey: ['sendRecoveryEmail'],
      mutationFn: ({
        email,
        htmlMessage,
      }: {
        email: string
        htmlMessage: string
      }) => sendRecoveryEmail({ email, htmlMessage }),
      onSuccess: () => {
        notify('Email enviado com sucesso')
        onClose()
      },
      onError: error => notify(error.message),
    })

  const { mutateAsync: handleRecovery, isPending: recoverRequestPending } =
    useMutation({
      mutationKey: ['recoverPasswordRequest'],
      mutationFn: async (email: string) =>
        await recoverPassword(email, loginType),
      onSuccess: async data => {
        if (data) {
          const { email, recovery_token } = data
          const htmlMessage = render(
            <RecoverPasswordEmail token={recovery_token} url={BASE_URL!} />,
          )
          await handleRecoveryEmail({
            email,
            htmlMessage,
          })
        }
      },
      onError: error => notify(error.message),
    })

  const isPendingRequest = recoverEmailPending || recoverRequestPending

  const formik = useFormik<{ recoveryEmail: Nullable<string> }>({
    initialValues: {
      recoveryEmail: null,
    },

    onSubmit: async values =>
      await handleRecovery(values.recoveryEmail as string),
  })

  return (
    <Modal onClose={onClose}>
      <div className="p-8">
        <NotificationContainer notifications={notifications} />
        <WrapperSmall>
          <h2 className="font-mont text-xl font-medium text-brand pb-4">
            Recuperar palavra-passe
          </h2>
          <form
            onSubmit={e => {
              e.preventDefault()
              formik.submitForm()
            }}
          >
            <FormInput
              formik={formik}
              name="recoveryEmail"
              type="email"
              label="Email"
            />
            {isPendingRequest ? (
              <LoaderInline />
            ) : (
              <FilledButton
                className="shadow-md uppercase w-1/3"
                label="submeter"
                type="submit"
              />
            )}
          </form>
        </WrapperSmall>
      </div>
    </Modal>
  )
}

export default RecoverPassword
