import { AxiosError } from 'axios'
import { HandledError } from 'types'

export const handleServerError = (error: unknown): HandledError => {
  if (error instanceof AxiosError) {
    throw new Error(
      `${error.response?.data.message} status: ${error.response?.status}`,
    )
  }
  if (error instanceof Error) {
    throw new Error(`${error.message} status: 500`)
  }

  throw new Error('An error occurred, status 500')
}
