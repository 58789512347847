import { ProtectedRoute } from './components'
import { Cotation } from 'pages/Cotations/Cotation'
import { Cotations } from 'pages/Cotations'
import { Invoice } from 'pages/Invoices/Invoice'
import { Invoices } from 'pages/Invoices'
import { Policies } from 'pages/Policies'
import { Policy } from 'pages/Policies/Policy'
import { Process } from 'pages/Process'
import { ProfileBroker } from './pages/ProfileBroker'
/* import { RegisterTeamAdmin } from './pages/RegisterTeamAdmin' */
import { RegisterUser } from './pages/RegisterUser'
import {
  Trainings,
  PastTrainings,
  Tutorials,
  Certificates,
} from 'pages/Trainings'
import { Register } from 'pages/Register/Broker'
import { RegisterClient } from 'pages/Register/Client'
import { Simulators } from 'pages/Simulators'
import { ChangePassword } from 'pages/ChangePassword'
import { Communications } from 'pages/Communications'
import { Dashboard } from 'pages/Dashboard'
import { Login } from 'pages/Login'
import { Product } from 'pages/Product'
import { Profile } from 'pages/Profile'

import { ErrorBoundary } from 'components/ErrorBoundary'
import { NotFound } from 'pages/NotFound'
import { ReviewBroker } from 'pages/ReviewBroker'

export const routes = [
  {
    path: '/login',
    element: <Login />,
    errorBoundary: () => (
      <ErrorBoundary>
        <Login />
      </ErrorBoundary>
    ),
  },
  {
    path: '/register',
    element: <Register />,
    errorBoundary: () => (
      <ErrorBoundary>
        <Register />
      </ErrorBoundary>
    ),
  },
  {
    path: '/review-broker/:reviewToken',
    element: <ReviewBroker />,
    errorBoundary: () => (
      <ErrorBoundary>
        <ReviewBroker />
      </ErrorBoundary>
    ),
  },
  {
    path: '/client-register',
    element: <RegisterClient />,
    errorBoundary: () => (
      <ErrorBoundary>
        <RegisterClient />
      </ErrorBoundary>
    ),
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Dashboard />
      </ErrorBoundary>
    ),
  },
  {
    path: '/reset-password/:token',
    element: <ChangePassword />,
    errorBoundary: () => (
      <ErrorBoundary>
        <ChangePassword />
      </ErrorBoundary>
    ),
  },
  {
    path: '/register-user/:id',
    element: <RegisterUser />,
    errorBoundary: () => (
      <ErrorBoundary>
        <RegisterUser />
      </ErrorBoundary>
    ),
  },
  /*  {
    path: '/register-team-admin/:id',
    element: <RegisterTeamAdmin />,
    errorBoundary: () => (
      <ErrorBoundary>
        <RegisterTeamAdmin />
      </ErrorBoundary>
    ),
  }, */
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Dashboard />
      </ErrorBoundary>
    ),
  },
  {
    path: '/communications/:type',
    element: (
      <ProtectedRoute>
        <Communications />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Communications />{' '}
      </ErrorBoundary>
    ),
  },
  {
    path: '/produto/:slug',
    element: (
      <ProtectedRoute>
        <Product />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Product />
      </ErrorBoundary>
    ),
  },
  {
    path: '/informations/:slug',
    element: (
      <ProtectedRoute>
        <Process />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Process />
      </ErrorBoundary>
    ),
  },
  {
    path: '/sinistros/:slug',
    element: (
      <ProtectedRoute>
        <Process />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Process />
      </ErrorBoundary>
    ),
  },
  {
    path: '/profile-broker',
    element: (
      <ProtectedRoute>
        <ProfileBroker />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <ProfileBroker />
      </ErrorBoundary>
    ),
  },
  {
    path: '/profile',
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Profile />
      </ErrorBoundary>
    ),
  },
  {
    path: '/trainings',
    element: (
      <ProtectedRoute>
        <Trainings />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Trainings />
      </ErrorBoundary>
    ),
  },
  {
    path: '/past-trainings',
    element: (
      <ProtectedRoute>
        <PastTrainings />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <PastTrainings />
      </ErrorBoundary>
    ),
  },
  {
    path: '/tutorials',
    element: (
      <ProtectedRoute>
        <Tutorials />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Tutorials />
      </ErrorBoundary>
    ),
  },
  {
    path: '/certificates',
    element: (
      <ProtectedRoute>
        <Certificates />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Certificates />
      </ErrorBoundary>
    ),
  },
  {
    path: '/quotations',
    element: (
      <ProtectedRoute>
        <Cotations />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Cotations />
      </ErrorBoundary>
    ),
  },
  {
    path: '/quotations/:id',
    element: (
      <ProtectedRoute>
        <Cotation />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Cotation />
      </ErrorBoundary>
    ),
  },
  {
    path: '/policies',
    element: (
      <ProtectedRoute>
        <Policies />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Policies />
      </ErrorBoundary>
    ),
  },
  {
    path: '/policies/:policyNo',
    element: (
      <ProtectedRoute>
        <Policy />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Policy />
      </ErrorBoundary>
    ),
  },
  {
    path: '/invoices',
    element: (
      <ProtectedRoute>
        <Invoices />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Invoices />
      </ErrorBoundary>
    ),
  },
  {
    path: '/invoices',
    element: (
      <ProtectedRoute>
        <Invoices />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Invoices />
      </ErrorBoundary>
    ),
  },
  {
    path: '/invoices/:policyNo',
    element: (
      <ProtectedRoute>
        <Invoice />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Invoice />
      </ErrorBoundary>
    ),
  },
  {
    path: '/invoices/:policyNo/:receiptNo',
    element: (
      <ProtectedRoute>
        <Invoice />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Invoice />
      </ErrorBoundary>
    ),
  },
  {
    path: '/simuladores/:slug',
    element: (
      <ProtectedRoute>
        <Simulators />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Simulators />
      </ErrorBoundary>
    ),
  },
  {
    path: '/simuladores/:slug/:id',
    element: (
      <ProtectedRoute>
        <Simulators />
      </ProtectedRoute>
    ),
    errorBoundary: () => (
      <ErrorBoundary>
        <Simulators />
      </ErrorBoundary>
    ),
  },
  { path: '*', element: <NotFound /> },
]
