import { axiosClient } from './axiosClient'

export type BrokerData = {
  email: string
  name: string
  phone: string
  vat: string
  forename: string
  files: File[]
}

export type UpdateBrokerData = Pick<
  BrokerData,
  'name' | 'vat' | 'phone' | 'files'
>

export const createBrokerAppointment = async (brokerData: BrokerData) => {
  const formData = new FormData()
  formData.append('email', brokerData.email)
  formData.append('name', brokerData.name)
  formData.append('phone', brokerData.phone)
  formData.append('vat', brokerData.vat)
  formData.append('forename', brokerData.forename)
  brokerData.files.forEach(file => {
    formData.append('broker', file)
  })

  try {
    const { data } = await axiosClient.post('/broker/appointment', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateBrokerAppointment = async ({
  id,
  brokerData,
}: {
  id: number
  brokerData: UpdateBrokerData
}) => {
  try {
    const formData = new FormData()
    formData.append('name', brokerData.name)
    formData.append('phone', brokerData.phone)
    formData.append('vat', brokerData.vat)
    brokerData.files.forEach(file => {
      formData.append('broker', file)
    })

    const { data } = await axiosClient.patch(
      `/broker/${id}/appointment`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    return data
  } catch (error) {
    throw error
  }
}

export const getBrokerByReviewToken = async (reviewToken: string) => {
  try {
    const { data } = await axiosClient.get(
      `/broker/review-token/${reviewToken}`,
    )
    return data
  } catch (err) {
    throw err
  }
}
