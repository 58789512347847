import { useFormik } from 'formik'
import * as Yup from 'yup'
import { locale } from 'locale'
import {
  FilledButton,
  FormInput,
  LoaderInline,
  NotificationContainer,
  useNotification,
} from 'components'
import { validateNif } from 'services/nif'
import { useMutation } from '@tanstack/react-query'

const validationSchemaStep1 = Yup.object().shape({
  POLICYHOLDERID: Yup.string()
    .required(locale.required)
    .matches(/^[0-9]{9}$/, locale.validation.digits9),
})

const before =
  'before:inline-block before:align-middle before:text-center before:text-lg before:w-8 before:h-8 before:leading-8 before:rounded-full before:mr-4 before:text-white before:bg-brand'

export const SimulatorsStep1 = ({
  setCurrentStep,
  brokerNo,
  setNif,
  setHolder,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  brokerNo: number
  setNif: React.Dispatch<React.SetStateAction<string>>
  setHolder: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { notifications, notify } = useNotification()

  const formikStep1 = useFormik({
    initialValues: { POLICYHOLDERID: '' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationSchemaStep1,
    onSubmit: async values => {
      setNif(values.POLICYHOLDERID)
      await CheckNif({
        brokerNo: brokerNo,
        nif: parseInt(values.POLICYHOLDERID, 10),
      })
    },
  })

  const { isPending, mutateAsync: CheckNif } = useMutation({
    mutationFn: validateNif,
    onError: () => notify('Error veryfing NIF. Try again later.'),
    onSuccess: data => {
      if (data.error) {
        setCurrentStep(1)
        notify('Consultar a Innovarisk – cliente já existente')
      } else {
        setHolder(data.Policyholder)
        setCurrentStep(2)
      }
    },
  })

  return (
    <>
      <NotificationContainer notifications={notifications} />
      <h3
        className={`font-mont font-medium text-xl leading-4 text-darkBlue py-4 before:content-['1'] px-4 bg-lightGrey ${before}`}
      >
        Validação do cliente
      </h3>
      <form
        onSubmit={formikStep1.handleSubmit}
        className="flex justify-between items-center pt-6"
      >
        <div className="w-2/3">
          <FormInput
            label="NIF"
            name="POLICYHOLDERID"
            formik={formikStep1}
            type="number"
          />
        </div>
        <div className="pt-4">
          {isPending ? (
            <LoaderInline />
          ) : (
            <FilledButton label="Continuar" type="submit" />
          )}
        </div>
      </form>
    </>
  )
}
