import {
  FilledButton,
  Loader,
  NotificationContainer,
  useNotification,
} from 'components'
import { locale } from 'locale'
import { getPolicyDocumentation } from 'services/policies'
import TransparentButton from 'components/Buttons/transparent'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AuthContext } from 'contexts/AuthContext'
import { useContext, useEffect } from 'react'
import { PolicyEmailData, sendPolicyEmail } from 'services/email'
import { SimulatorEmail } from 'emails/simulator'
import { render } from '@react-email/render'

const before =
  'before:inline-block before:align-middle before:text-center before:text-lg before:w-8 before:h-8 before:leading-8 before:rounded-full before:mr-4 before:text-white before:bg-brand'

export const SimulatorsStep3 = ({
  policyNo,
  eventNo,
}: {
  policyNo: number
  eventNo: number
}) => {
  const { notifications, notify } = useNotification()
  const { currentUser } = useContext(AuthContext)

  const { data, isLoading, error } = useQuery({
    queryKey: ['getPolicyDocs'],
    queryFn: async () =>
      await getPolicyDocumentation({ policyNo: policyNo, eventNo: eventNo }),
    retryDelay: 1000,
    retry: 2,
  })

  useEffect(() => {
    if (error) {
      notify('Erro ao ir buscar documentos')
    }
  }, [data, isLoading, error])

  const handleDownload = () => {
    const blob = new Blob([data], { type: 'application/pdf' })
    window.open(URL.createObjectURL(blob))
  }

  const { mutateAsync: createPolicyEmailMutation, isPending } = useMutation({
    mutationKey: ['sendPolicyEmail'],
    mutationFn: (values: PolicyEmailData) => sendPolicyEmail(values),
  })

  const sendByEmailHandler = async () => {
    if (currentUser && data) {
      const blob = new Blob([data], { type: 'application/pdf' })
      const file = new File([blob], `simulacao_apolice_${policyNo}.pdf`, {
        type: 'application/pdf',
        lastModified: Date.now(),
      })

      const html = render(
        <SimulatorEmail
          name={currentUser.forename!}
          surname={currentUser.surname!}
        />,
        {
          pretty: true,
        },
      )

      const values = {
        email: currentUser.email,
        attachments: file,
        htmlMessage: html,
      }

      try {
        await createPolicyEmailMutation(values)
        notify('Email enviado com sucesso', 'success')
      } catch (error) {
        error instanceof Error && notify(error.message, 'error')
      }
    } else {
      notify('No file')
    }
  }

  return (
    <div className="flex flex-col gap-y-8">
      <NotificationContainer notifications={notifications} />
      <h3
        className={`font-mont font-medium text-xl leading-4 text-darkBlue py-4 before:content-['3'] px-4 bg-lightGrey ${before}`}
      >
        Finalizar Processo
      </h3>

      <h3 className="font-mont font-medium text-lg text-darkBlue">
        {locale.simulationSaved}
      </h3>
      <p className="text-sm">
        Pode consultar a simulação na página de consultas, onde está guardada
        com o estado GRAVADO.
      </p>
      <p className="text-sm">
        Pode fazer download da cotação para ser assinada pelo Tomador (a mesma
        inclui a proposta de seguro já pré-preenchida) ou enviá-la por email.
      </p>
      <div className="flex justify-end items-center gap-x-4">
        {(isLoading || isPending) && <Loader />}
        <FilledButton
          label="Enviar por email"
          action={() => sendByEmailHandler()}
          disabled={!!error || isPending}
        />
        <TransparentButton
          label="Download"
          action={() => handleDownload()}
          disabled={!!error}
          className="!text-sm !rounded !py-2"
        />
      </div>
    </div>
  )
}
