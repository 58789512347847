/*global gtag */
import { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { AuthContext } from '../../contexts/AuthContext'
import { AppContext } from '../../contexts/AppContext'

import {
  NotificationContainer,
  useNotification,
  FilledButton,
  Loader,
  FormCheckbox,
  Wrapper
} from 'components'
import { locale } from 'locale'
import RecoverPassword from './recoverPass'
import { Link, useNavigate } from 'react-router-dom'
import { NewFormInput } from 'components/Form/newInput'

export const Login = () => {
  const navigate = useNavigate()
  const { login, isLoginLoading, isSignedIn } = useContext(AuthContext)
  const { notifications, notify } = useNotification()
  const [modalVisibility, setModayVisibility] = useState(false)
  const [loginType, setLoginType] = useState<'broker' | 'client'>('broker')
  const { settings, loading } = useContext(AppContext)

  const validationSchema = Yup.object().shape({
    username: loginType === 'broker' ? Yup.string().email().required(locale.required) : Yup.string()
    .matches(/^[0-9]{9}$/, locale.validation.digits9)
    .required(locale.required),
    password: Yup.string().required(locale.required),
  })


  const handleClose = () => {
    setModayVisibility(false)
    notify('Email de recuperação enviado com sucesso', 'success')
  }

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      try {
        await login({
          username: values.username,
          password: values.password,
        })
        navigate('/dashboard')
      } catch (err) {
        notify(locale.loginError)
      }
    },
  })

  const goToRegister = () => {
    gtag('event', 'action', {
      event_category: 'click',
      event_label: 'Abriu Form de Nomeação',
    })
  }

  const goToRegisterClient = () => {
    gtag('event', 'action', {
      event_category: 'click',
      event_label: 'Client pediu registo',
    })
  }

  if (isSignedIn) {
    navigate('/dashboard')
  }

  if (loading || !settings) {
    return <Loader />
  }

  // const image = settings.find((item: any) => item.name === 'loginImage')?.value
  const image = "/images/cloud.svg"
  const imageClient = "images/home.svg"
  // const imageClient = settings.find(
  //   (item: any) => item.name === 'clientLoginImage',
  // )?.value

  const brokerText = settings.find(
    (item: any) => item.name === 'brokerLoginText',
  )?.value

  const clientLoginText = settings.find(
    (item: any) => item.name === 'clientLoginText',
  )?.value

  const maintenanceMode =
    settings.find((item: any) => item.name === 'maintenanceMode')?.value ===
      'true'
      ? true
      : false

  const maintenanceImage = settings.find(
    (item: any) => item.name === 'maintenanceImage',
  )?.value

  const maintenanceMobileImage = settings.find(
    (item: any) => item.name === 'maintenanceMobileImage',
  )?.value

  if (maintenanceMode) {
    return (
      <div className="absolute w-full h-full">
        <div
          className="w-full h-full bg-contain bg-no-repeatbg-center bg-brandFaded hidden md:block"
          style={{ backgroundImage: `url(${maintenanceImage})` }}
        ></div>
        <div
          className="w-full h-full bg-contain bg-no-repeatbg-center bg-brandFaded md:hidden block"
          style={{ backgroundImage: `url(${maintenanceMobileImage})` }}
        ></div>
      </div>
    )
  }

  return (
    <div
      className="w-full h-full bg-cover bg-no-repeat bg-center bg-brandFaded"
      style={{
        backgroundImage: `url(${image}), url(${imageClient})`,
      }}
    >
      {modalVisibility && (
        <RecoverPassword onClose={handleClose} loginType={loginType} />
      )}
      <Wrapper extraClasses='flex space-between'>
        <NotificationContainer notifications={notifications} />
        <div className="w-1/2">
          <h1 className="text-white uppercase font-roboto font-bold mb-[50px] mt-[100px] text-3xl tracking-wider">Seguros à velocidade de um foguetão</h1>
          <div className="z-10 bg-transparent shadow-sm rounded-t w-2/3">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div
                className={`text-center h-full rounded ${loginType === 'broker'
                    ? 'bg-white mt-[10px]'
                    : 'z-10 bg-brand shadow mt-[10px] border border-black'
                  } w-full py-2`}
              >
                <button
                  className={`bg-transparent block w-full h-full uppercase font-roboto ${loginType === 'broker' ? 'text-red' : 'text-white'
                    }`}
                  onClick={() => setLoginType('broker')}
                >
                  Broker
                </button>
              </div>
              <div
                className={`text-center h-full rounded ${loginType === 'client'
                    ? 'bg-white mt-[10px]'
                    : 'z-10 bg-brand shadow mt-[10px] border border-black'
                  } w-full py-2`}
              >
                <button
                  className={`bg-transparent block w-full h-full uppercase font-roboto ${loginType === 'client' ? 'text-red' : 'text-white'
                    }`}
                  onClick={() => setLoginType('client')}
                >
                  Client
                </button>
              </div>
            </div>
          </div>
          <div className="w-2/3 bg-white pb-6 py-20 px-4">
            {loginType === 'broker' ? (
              <form className="relative" onSubmit={formik.handleSubmit}>
                <div className="relative">
                  <img className="absolute top-3 w-4 h-4" src="/images/mail_icon.svg" />
                  <NewFormInput
                    extraClasses="mb-5 px-5"
                    type="email"
                    name="username"
                    label={locale.email}
                    formik={formik}
                  />
                </div>
                <div className="relative">
                  <img className="absolute top-3" src="/images/padlock_icon.svg" />
                  <NewFormInput
                    extraClasses="mb-5 px-5"
                    name="password"
                    type="password"
                    label={locale.password}
                    formik={formik}
                  />
                </div>
                <button
                    className="absolute right-0 text-sm text-brand underline text-right mb-3"
                    onClick={e => {
                      e.preventDefault()
                      setModayVisibility(true)
                    }}
                  >
                    {locale.recoverPassword}
                  </button>
                <div className="flex flex-row mb-8 flex-wrap">
                  <FormCheckbox
                    name="remember"
                    label="Lembrar-me"
                    formik={formik}
                  />
                  <FormCheckbox
                    name="newsletter"
                    label="Aceito subscrever a newsletter para receber as últimas novidades e campanhas"
                    formik={formik}
                  />
                </div>
                <FilledButton
                  className="m-auto w-full"
                  type="submit"
                  label={locale.login}
                  disabled={isLoginLoading}
                />
              </form>
            ) : (
              <form className="relative" onSubmit={formik.handleSubmit}>
                <div className="relative">
                  <img className="absolute top-3 w-4 h-4" src="/images/nif.svg" />
                  <NewFormInput
                    extraClasses="mb-5 px-5"
                    type="text"
                    name="username"
                    label="NIF"
                    formik={formik}
                  />
                </div>
                <div className="relative">
                  <img className="absolute top-3" src="/images/padlock_icon.svg" />
                  <NewFormInput
                    extraClasses="mb-5 px-5"
                    name="password"
                    type="password"
                    label={locale.password}
                    formik={formik}
                  />
                </div>
                <button
                    className="absolute right-0 text-sm text-brand underline text-right mb-3"
                    onClick={e => {
                      e.preventDefault()
                      setModayVisibility(true)
                    }}
                  >
                    {locale.recoverPassword}
                  </button>
                <div className="flex flex-row mb-8 flex-wrap">
                  <FormCheckbox
                    name="remember"
                    label="Lembrar-me"
                    formik={formik}
                  />
                  <FormCheckbox
                    name="newsletter"
                    label="Aceito subscrever a newsletter para receber as últimas novidades e campanhas"
                    formik={formik}
                  />
                  
                </div>
                <FilledButton
                  className="m-auto w-full"
                  type="submit"
                  label={locale.login}
                  disabled={isLoginLoading}
                />
              </form>
            )}
          </div>
          <h2 className="text-white font-roboto mb-[50px] mt-[50px] text-xl">A solução que permite de forma simples e fácil aceder aos produtos especializados para nichos de mercado. </h2>
        </div>
        <div className="w-1/2">
          <div className="mt-[200px] w-full rounded flex flex-col items-center justify-center">
            <img className="mb-[50px]" src="/images/Group.svg" alt="Innovarisk" />
            <Link
              to={loginType === 'broker' ? '/register' : '/client-register'}
            >
              <FilledButton
                label={loginType === 'broker' ? 'pedir nomeação' : 'registe-se'}
                action={() =>
                  loginType === 'broker' ? goToRegister() : goToRegisterClient()
                }
              />
            </Link>
          </div>

        </div>
      </Wrapper>
    </div>
  )
}
