import { AuthContext } from 'contexts/AuthContext'
import { useContext } from 'react'
import { InvoiceBroker } from './invoiceBroker'
import { InvoiceClient } from './invoiceClient'

export const Invoice = () => {
  const { currentUser } = useContext(AuthContext)

  return <>{currentUser?.role ? <InvoiceBroker /> : <InvoiceClient />}</>
}
