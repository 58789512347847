export const MenuTrainings = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
    <ellipse fill="currentcolor" cx='17.3' cy='18' rx='4.81' ry='4.86' />
    <path
      fill='currentcolor'
      d='M34.47,20.11A18.23,18.23,0,0,0,34.61,18,17.42,17.42,0,0,0,17.3.5,17.53,17.53,0,0,0,2.15,26.44a3.28,3.28,0,0,0-.58,1.86,3.19,3.19,0,0,0,4.48,3A17.12,17.12,0,0,0,17.3,35.5a17.32,17.32,0,0,0,15.34-9.41h.16a3.24,3.24,0,0,0,1.67-6ZM17.3,33.34a15,15,0,0,1-9.69-3.56A3.34,3.34,0,0,0,8,28.3a3.23,3.23,0,0,0-3.21-3.24,3.09,3.09,0,0,0-.86.13A15.37,15.37,0,0,1,17.3,2.66,15.27,15.27,0,0,1,32.47,18a15.11,15.11,0,0,1-.09,1.66,3.23,3.23,0,0,0-1.72,5.6A15.15,15.15,0,0,1,17.3,33.34Z'
    />
    <path
      fill='currentcolor'
      d='M17.3,6.33a11.41,11.41,0,0,0-4.63,1,3.17,3.17,0,0,0-1.93-.66A3.22,3.22,0,0,0,7.54,9.9a3.18,3.18,0,0,0,.33,1.41A11.68,11.68,0,0,0,17.3,29.67a11.67,11.67,0,0,0,0-23.34Zm0,21.18A9.47,9.47,0,0,1,7.9,18a9.58,9.58,0,0,1,1.52-5.16,3.08,3.08,0,0,0,1.32.3A3.23,3.23,0,0,0,14,9.9a3.47,3.47,0,0,0-.09-.73,9.21,9.21,0,0,1,3.44-.68,9.51,9.51,0,0,1,0,19Z'
    />
  </svg>
);
