import { useState, useEffect, useMemo, useCallback } from 'react'
import { useFormik } from 'formik'
import { getAnswers, getValidations, getVisibility } from './helper'
import { locale } from 'locale'
import {
  ContentEditor,
  FilledButton,
  FormCheckbox,
  FormInput,
  LoaderInline,
  NotificationContainer,
  useNotification,
} from 'components'
import { PolicyAnswer, Questions } from 'types/simulators'
import TransparentButton from 'components/Buttons/transparent'
import { User } from 'types/user'

import { useMutation } from '@tanstack/react-query'
import { createPolicy as AxiosCreatePolicy, getRate } from 'services/policies'
import { buildSimulatorForm } from 'utils/simulators'
import { AxiosError } from 'axios'

const before =
  'before:inline-block before:align-middle before:text-center before:text-lg before:w-8 before:h-8 before:leading-8 before:rounded-full before:mr-4 before:text-white before:bg-brand'

export const SimulatorsStep2 = ({
  nif,
  holder,
  simulatorData,
  userData,
  previousValues,
  conditions,
  setCurrentStep,
  setPolicyNo,
  setEventNo,
}: {
  nif: string
  holder: string | PolicyAnswer[]
  simulatorData: Questions | undefined
  userData: User | undefined
  previousValues: Record<string, string | boolean> | undefined
  conditions: any
  setPolicyNo: React.Dispatch<React.SetStateAction<number>>
  setEventNo: React.Dispatch<React.SetStateAction<number>>
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { validationSchema, initialValues } = getValidations(
    simulatorData,
    holder as PolicyAnswer[],
    nif,
  )

  const { notifications, notify } = useNotification()
  const [prize, setPrize] = useState(false)
  const [answers, setAnswers] = useState('')
  const [visibilityRules, setVisibilityRules] = useState([])
  const policyHolderValue = initialValues.POLICYHOLDER
    ? (initialValues.POLICYHOLDER as string).trim()
    : ''

  useEffect(() => {
    if (simulatorData?.Visibility && simulatorData.Visibility.length > 0) {
      setVisibilityRules(simulatorData.Visibility)
    }
  }, [simulatorData?.Visibility])

  const { mutate: createPolicy, isPending: isPendingCreate } = useMutation({
    mutationKey: ['createPolicy'],
    mutationFn: (answers: string) => AxiosCreatePolicy(answers),
    onSuccess: data => {
      notify('Apólice criada com successo', 'success')
      setEventNo(data.EventNo)
      setPolicyNo(data.PolicyNo)
      setCurrentStep(3)
    },
    onError: () => notify(),
  })

  const { mutate: getRating, isPending: isPendingRate } = useMutation({
    mutationKey: ['getRating'],
    mutationFn: (answers: any) => getRate(answers),
    onSuccess: data => {
      setPrize(
        (
          data.Policy.Events[0].Transaction.AnnualPremium +
          data.Policy.Events[0].Transaction.AnnualTax
        ).toFixed(2),
      )
      notify('Simulação criada com sucesso', 'success')
    },
    // Axios Error
    onError: (
      error: AxiosError<{
        Status: { Ok: boolean; Errors: { ErrorText: string }[] }
      }>,
    ) => {
      const err = error.response?.data.Status.Errors[0].ErrorText
      notify(err, 'error')
    },
  })

  const formikStep2 = useFormik({
    initialValues:
      previousValues && Object.keys(previousValues).length !== 0
        ? previousValues
        : initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const answers = getAnswers(
        simulatorData,
        userData?.broker?.broker_no!,
        values,
      )
      setAnswers(answers)
      getRating(answers)
    },
  })

  const submitPolicyHandler = () => {
    createPolicy(JSON.stringify(answers))
  }

  const personalData = useMemo(() => {
    let newData = simulatorData?.Policyholder
    let tempLocal = newData && newData[5]
    simulatorData && newData
      ? (newData[5] = simulatorData.Policyholder[6])
      : undefined
    newData && tempLocal ? (newData[6] = tempLocal) : undefined

    return newData
  }, [simulatorData?.Policyholder])

  const isVisible = useCallback(
    (InputId: any, visibilityRules: any, formikStep2: any) =>
      getVisibility(InputId, visibilityRules, formikStep2),
    [],
  )

  const isPending = isPendingCreate || isPendingRate
  return (
    <>
      <NotificationContainer notifications={notifications} />
      <h3
        className={`font-mont font-medium text-xl leading-4 text-darkBlue py-4 before:content-['2'] px-4 bg-lightGrey ${before}`}
      >
        Capitais Seguros
      </h3>
      <form className="flex flex-col pt-10" onSubmit={formikStep2.handleSubmit}>
        <h4 className="font-mont font-medium text-base text-darkBlue uppercase pb-4">
          {locale.insuranceClient}
        </h4>
        {personalData?.map(item => {
          return !(
            item.InputId === 'POLICYHOLDERID' || item.InputId === 'COUNTRY'
          ) ? (
            <FormInput
              key={item.InputId}
              type="text"
              name={item.InputId}
              label={item.QuestionText ?? ''}
              formik={formikStep2}
              placeholder={item.InputId === 'POSTCODE' ? '####-###' : ''}
              disabled={Boolean(
                item.InputId === 'POLICYHOLDER' && policyHolderValue,
              )}
              extraClasses="flex"
              inline
            />
          ) : null
        })}
        {simulatorData?.Risks &&
          simulatorData.Risks.map((risk, indexr) => {
            return (
              <div key={`risk${indexr}`}>
                {risk.Covers.map((cover, index1) => {
                  return (
                    <div className="pt-10" key={`cover${index1}`}>
                      <h4 className="font-mont font-medium text-base text-darkBlue uppercase pb-4">
                        {cover.Description} NOVO
                      </h4>
                      {cover.Items.map(item => {
                        return item.Questions.map(question => {
                          return (
                            <div key={question.InputId}>
                              {buildSimulatorForm(
                                question.Attribute,
                                question,
                                isVisible(
                                  question.InputId,
                                  visibilityRules,
                                  formikStep2.values,
                                )
                                  ? 'hidden'
                                  : 'flex',
                                formikStep2,
                              )}
                            </div>
                          )
                        })
                      })}

                      {cover.Questions.map((question, index3) => {
                        return (
                          <div key={`cover${index1}_question${index3}`}>
                            {buildSimulatorForm(
                              question.Attribute,
                              question,
                              isVisible(
                                question.InputId,
                                visibilityRules,
                                formikStep2.values,
                              )
                                ? 'hidden'
                                : 'flex',
                              formikStep2,
                            )}
                          </div>
                        )
                      })}
                    </div>
                  )
                })}

                {risk && risk.Questions.length > 0 && (
                  <div>
                    <h4 className="font-mont font-medium text-base text-darkBlue uppercase pb-4">
                      Outras Questões
                    </h4>
                  </div>
                )}

                {risk &&
                  risk.Questions.length > 0 &&
                  risk.Questions.map((question, index) => {
                    return (
                      <div key={`question1${index}`}>
                        {buildSimulatorForm(
                          question.Attribute,
                          question,
                          isVisible(
                            question.InputId,
                            visibilityRules,
                            formikStep2.values,
                          )
                            ? 'hidden'
                            : 'flex',
                          formikStep2,
                        )}
                      </div>
                    )
                  })}
                {simulatorData.Questions.length > 0 &&
                  simulatorData.Questions.map((question, index) => {
                    return (
                      <div key={`question2${index}`}>
                        {buildSimulatorForm(
                          question.Attribute,
                          question,
                          isVisible(
                            question.InputId,
                            visibilityRules,
                            formikStep2.values,
                          )
                            ? 'hidden'
                            : 'flex',
                          formikStep2,
                        )}
                      </div>
                    )
                  })}

                <div>
                  <h3 className="font-mont font-medium text-sm text-brand pt-10 pb-4">
                    {locale.insuranceConditions}
                  </h3>
                  {conditions &&
                    conditions.map((item: any, index: number) => (
                      <ContentEditor
                        key={`editor_${index}`}
                        item={item}
                        index={index}
                      />
                    ))}
                  <FormCheckbox
                    name="acceptance"
                    label={locale.insuranceAcceptance}
                    formik={formikStep2}
                  />
                  <input
                    value={formikStep2.getFieldProps('COUNTRY').value}
                    className="hidden"
                  />
                </div>
              </div>
            )
          })}
        <div className="flex flex-col border-y-2 py-6 mt-10 gap-y-6">
          <div className="flex items-center justify-between font-mont font-medium text-base text-darkBlue uppercase">
            <h4>{locale.totalPrize}</h4>
            {prize && <h4>{prize}€</h4>}
          </div>

          <div className="flex items-center justify-end gap-x-6">
            {!isPending && (
              <FilledButton
                label="Simular"
                type="submit"
                onClick={e => {
                  e.preventDefault()
                }}
              />
            )}
            {prize && (
              <TransparentButton
                label="Gravar Simulação"
                className="!rounded px-2 xl:!px-4 !py-2 !text-sm !font-medium !text-brand border-brand"
                action={() => submitPolicyHandler()}
              />
            )}
            {isPending && <LoaderInline />}
          </div>
          {Object.keys(formikStep2.errors).length > 0 && (
            <p className="w-full flex justify-end font-lato text-xs text-error">
              Por favor corrija os campos assinalados
            </p>
          )}
        </div>
      </form>
    </>
  )
}
