import { BodyItem } from 'types/editor'

export const getProductBodyContent = (productBody: BodyItem[]) => {
  const bodyText = productBody.filter(
    (e: BodyItem) => e.type !== 'delimiter' && e.type !== 'fileList',
  )
  const delimiter = productBody.filter(
    (item: BodyItem) => item.type === 'delimiter',
  )
  const fileList = productBody.filter(
    (item: BodyItem) => item.type === 'fileList',
  )
  return [bodyText, delimiter, fileList]
}
