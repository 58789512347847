import { axiosClient } from './axiosClient'
import { InvoiceParams } from 'types/invoices'

export const getInvoices = async ({ params }: { params: InvoiceParams }) => {
  try {
    const { data } = await axiosClient.get('/isaac/invoices', {
      params,
    })
    return data.Invoices
  } catch (error) {
    throw error
  }
}

export const getInvoicesDocumentation = async ({
  receiptNo,
}: {
  receiptNo: InvoiceParams['receiptNo']
}) => {
  try {
    const { data } = await axiosClient.get(
      `/isaac/invoices/docs/${receiptNo}`,
      { responseType: 'arraybuffer' },
    )
    return data
  } catch (error) {
    throw error
  }
}

export const downloadInvoicesExcel = async ({
  params,
}: {
  params: InvoiceParams
}) => {
  const downloadParams: InvoiceParams = {
    ...params,
    output: 'Excel',
  }
  try {
    const { data } = await axiosClient.get('/isaac/invoices/excel', {
      params: downloadParams,
      responseType: 'arraybuffer',
    })
    return data
  } catch (error) {
    throw error
  }
}
