import { useContext } from 'react'
import DashboardBroker from './dashboardBroker'
import DashboardClient from './dashboardClient'

import { AuthContext } from '../../contexts/AuthContext'
import { Loader } from 'components'

export const Dashboard = () => {
  const { currentUser } = useContext(AuthContext)
  if (!currentUser) return <Loader />

  return <>{currentUser?.role ? <DashboardBroker /> : <DashboardClient />} </>
}
