import {
  List,
  Loader,
  NotificationContainer,
  useNotification,
} from 'components'
import { Download } from 'components/Icons'
import { useContext } from 'react'
import { AppContext } from 'contexts/AppContext'
import { Link } from 'react-router-dom'

type SettingsElement = {
  name: string
  value: string
}
export const RegisterIntro = () => {
  const { notifications } = useNotification()
  const { settings, loading } = useContext(AppContext)

  if (loading || !settings) {
    return <Loader />
  }

  const pdfUrl = settings.find(
    (item: SettingsElement) => item.name === 'nominationPdf',
  )?.value

  const before =
    'before:inline-block before:align-middle before:text-center before:w-10 before:h-10 before:leading-10 before:border-2 before:border-darkBlue before:rounded-full before:mr-4'
  return (
    <div className="w-full md:pr-8">
      <NotificationContainer notifications={notifications} />
      <h1 className="font-mont font-medium text-xxxl leading-4 text-brand pb-8">
        Junte-se à nossa equipa!
      </h1>
      <div className="flex flex-col gap-y-4 font-lato font-normal text-base text-darkGrey">
        <p>
          O projeto Innova|GO é a solução da Innovarisk que lhe permite de forma
          simples e fácil aceder aos nossos produtos especializados para nichos
          de mercado.
        </p>
        <p>
          Para trabalhar connosco e ter acesso à plataforma, precisa apenas de:
        </p>
        <List
          id="register_intro_requirments"
          items={[
            'Ser mediador ou corretor de seguros certificado pela ASF',
            'Fazer download e preencher a Ficha de nomeação (passo 2)',
            'Preencher o formulário ao lado com os seus dados e da sua empresa.',
            'Anexar todos os documentos.',
          ]}
        />
      </div>
      <h2
        className={`font-mont font-medium text-xl leading-4 text-darkBlue py-4 before:content-['1'] ${before}`}
      >
        Documentos necessários
      </h2>
      <div className="flex flex-col gap-y-4 font-lato font-normal text-base text-darkGrey">
        <p>
          Para que possamos proceder à sua nomeação precisamos que anexe no
          formulário ao lado os seguintes documentos:
        </p>
        <List
          id="register_intro_documents"
          items={[
            'Ficheiro de nomeação',
            'Apólice Responsabilidade Civil',
            'Relatório e contas',
            'Certificado ASF de Mediador',
            'Comprovativo de IBAN/NIB',
          ]}
        />
      </div>
      <h2
        className={`font-mont font-medium text-xl leading-4 text-darkBlue py-4 before:content-['2'] ${before}`}
      >
        Preencha a Ficha de Nomeação
      </h2>
      <div className="flex flex-col gap-y-4 font-lato font-normal text-base text-darkGrey">
        <p>
          Faça o download da ficha de nomeação, preencha e anexe juntamente com
          os documentos do passo anterior no formulário ao lado.
        </p>
        <Link
          to={pdfUrl!}
          target="_blank"
          rel="noreferrer"
          className="inline-flex items-center justify-center gap-x-4 font-mont font-medium text-sm leading-9 text-brand text-center uppercase"
        >
          <Download extraClasses="w-[14px]" />
          <p>Download Ficha de nomeação</p>
        </Link>
      </div>
      <div className="flex flex-col gap-y-2 font-lato font-normal text-base text-darkGrey bg-lightGrey p-8 mt-10">
        <p className="font-semibold">Tem alguma dúvida?</p>
        <p>Caso tenha alguma dúvida ou questão, por favor contacte:</p>
        <p>+351 211 351 290</p>
      </div>
    </div>
  )
}
