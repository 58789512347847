import { useContext } from 'react'
import { AppContext } from 'contexts/AppContext'
import { useQuery } from '@tanstack/react-query'
import { getProduct } from 'services/products'
import { LocaleType, locale } from 'locale'
import {
  Loader,
  ContentEditor,
  Wrapper,
  NotificationContainer,
  useNotification,
} from 'components'
import { BodyItem } from 'types/editor'
import { getProductBodyContent } from 'utils/product'

const ProductClient = ({ slug }: { slug: string }) => {
  const { notifications } = useNotification()
  const { products } = useContext(AppContext)
  const id = products.find(product => product.slug === slug)?.id || -1

  const { data, isLoading } = useQuery({
    queryKey: ['getProduct', { id }],
    queryFn: () => getProduct({ id }),
  })

  if (isLoading) {
    return <Loader />
  }

  const product = data.product

  const productBody = product.body.length > 0 ? JSON.parse(product.body) : []
  const attachments = product.attachments
  const columns: Array<Array<BodyItem>> = getProductBodyContent(productBody)

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase font-mont text-sm font-medium text-darkBlue">
          {locale[product.type as LocaleType] as string}
        </h2>
        <h1 className="pb-10 font-mont text-xxxl font-medium text-brand">
          {product.title}
        </h1>
        <div className="grid grid-cols-[1fr_1px_1fr] grid-rows-[auto_1fr] gap-x-12 items-start justify-between">
          {columns.map((column, index) => {
            return (
              <div key={`editor_col_${index}`} className="h-full">
                {column.map((item, index) => {
                  return (
                    <ContentEditor
                      key={`editor_${index}`}
                      attachments={attachments}
                      index={index}
                      item={item}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
      </Wrapper>
    </div>
  )
}

export default ProductClient
