export const ReviewIntro = ({ observations }: { observations: string }) => {
  return (
    <div className="w-full md:pr-8">
      <h1 className="font-mont font-medium text-xxxl leading-4 text-brand pb-8">
        Corrigir nomeação
      </h1>
      <h2 className="font-mont font-medium text-xl leading-4 text-darkBlue py-4">
        A Innovarisk informa que:
      </h2>
      <div className="flex flex-col gap-y-4 font-lato font-normal text-base text-darkGrey">
        <p>{observations}</p>
      </div>
      <div className="flex flex-col gap-y-2 font-lato font-normal text-base text-darkGrey bg-lightGrey p-8 mt-10">
        <p className="font-semibold">Tem alguma dúvida?</p>
        <p>Caso tenha alguma dúvida ou questão, por favor contacte:</p>
        <p>+351 211 351 290</p>
      </div>
    </div>
  )
}
