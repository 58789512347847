import { useMemo, useState } from 'react'
import { FilledButton } from 'components/Buttons'
import { SearchFilter } from './searchFilter'
import { DateFilter } from './dateFilter'
import TransparentButton from 'components/Buttons/transparent'
import { Close } from 'components/Icons'
import { Excel } from 'components/Icons/excel'
import { SelectFilter } from './selectFilter'
import { useNavigate } from 'react-router-dom'
import { LoaderInline } from 'components/Loader'

export type FilterType = {
  id: string
  name: string
  type: string
  options: Option[] | []
}
export type Option = {
  id: number | string
  name: string
  checked?: boolean
  link?: string
}

export const Filter = ({
  isLoading,
  filters,
  navigation,
  formik,
  actionLabel,
  action,
}: {
  isLoading?: boolean
  filters: FilterType[]
  navigation?: boolean
  formik?: any
  actionLabel?: string
  action?: () => void
}) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const newFilters = useMemo(() => {
    return !navigation
      ? filters.map(filter => {
          const allOption: Option = {
            id: 0,
            name: 'All',
            checked: true,
          }
          return {
            id: filter.id,
            name: filter.name,
            type: filter.type,
            options: [
              allOption,
              ...filter.options.map(option => ({
                id: option.id,
                name: option.name,
                checked: false,
              })),
            ],
          }
        })
      : filters
  }, [filters, navigation])

  const handleCheckboxChange = (
    filterId: string,
    optionIndex: number | string,
  ) => {
    if (!navigation) {
      formik && formik.setFieldValue(filterId, optionIndex)
    } else {
      const filter = filters.find(filter => filter.id === filterId)

      const link = filter?.options.find(
        option => option.id == optionIndex,
      )?.link
      if (link) {
        navigate(`${link}`)
      }
    }
  }

  return (
    <div className="bg-white">
      {/* Mobile filter dialog */}
      <div className="block lg:hidden">
        <span className="flex justify-end">
          <TransparentButton
            label="Filtros"
            className="text-sm"
            action={() => setOpen(true)}
          />
        </span>
        <div className={`${open ? 'visible' : 'invisible'}`}>
          <div
            onClick={() => setOpen(false)}
            className={`fixed inset-0 bg-black/60 z-[9999] duration-500 ease-out transition-all ${
              open ? 'opacity-100' : 'opacity-0'
            }`}
          />
          <dialog
            className={`fixed top-0 h-full ml-autom mr-0 flex w-3/4 flex-col overflow-y-auto bg-lightGrey py-4 px-4 z-[9999] duration-500 ease-out transition-all ${
              open ? '' : 'translate-x-full'
            }`}
          >
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-medium text-gray-900">Filters</h2>
              <button
                type="button"
                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-lightGrey p-2 text-gray-400"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <span className="text-gray-600 h-3 w-3 flex items-center">
                  <Close aria-hidden="true" />
                </span>
              </button>
            </div>
            <form
              onSubmit={formik ? formik.handleSubmit : null}
              className="mt-4"
            >
              <div className="flex flex-col gap-y-6">
                {newFilters.map(filter =>
                  filter.type === 'search' ? (
                    <div key={`searchFilter_${filter.id}`}>
                      <SearchFilter
                        key={`searchFilter_${filter.id}`}
                        filter={filter}
                        formik={formik}
                      />
                    </div>
                  ) : filter.type === 'date' ? (
                    <div key={`searchFilter_${filter.id}`}>
                      <DateFilter
                        key={`dateFilter_${filter.id}`}
                        filter={filter}
                        formik={formik}
                      />
                    </div>
                  ) : (
                    <div key={`searchFilter_${filter.id}`}>
                      <SelectFilter
                        key={`checkboxFilter_${filter.id}`}
                        filter={filter}
                        formik={formik}
                        onChange={optionIdx =>
                          handleCheckboxChange(filter.id, optionIdx)
                        }
                      />
                    </div>
                  ),
                )}
                <div className="flex w-auto">
                  <FilledButton label="Pesquisar" type="submit" />
                </div>
                {action && (
                  <div className="flex w-auto">
                    <FilledButton
                      label="Download excel"
                      action={() => action()}
                    >
                      <span className="hidden xl:flex w-5 ml-2">
                        <Excel />
                      </span>
                    </FilledButton>
                  </div>
                )}
              </div>
            </form>
          </dialog>
        </div>
      </div>

      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <div className="bg-white pb-4">
          <div className="mx-auto max-w-full">
            <div className="hidden lg:block">
              <div className="flow-root">
                <form
                  onSubmit={formik ? formik.handleSubmit : null}
                  className="bg-lightGrey p-4 pt-8 rounded-xl "
                >
                  <div className="grid grid-cols-4 gap-y-6 gap-x-6">
                    {newFilters.map(filter =>
                      filter.type === 'search' ? (
                        <SearchFilter
                          key={`searchFilter_${filter.id}`}
                          filter={filter}
                          formik={formik}
                        />
                      ) : filter.type === 'date' ? (
                        <DateFilter
                          key={`dateFilter_${filter.id}`}
                          filter={filter}
                          formik={formik}
                        />
                      ) : (
                        <SelectFilter
                          key={`checkboxFilter_${filter.id}`}
                          filter={filter}
                          formik={formik}
                          onChange={optionIdx =>
                            handleCheckboxChange(filter.id, optionIdx)
                          }
                        />
                      ),
                    )}
                    {isLoading ? (
                      <LoaderInline />
                    ) : (
                      <>
                        {formik && (
                          <div className="flex w-auto">
                            <FilledButton
                              label="Pesquisar"
                              action={() => formik.handleSubmit()}
                              type="button"
                            />
                          </div>
                        )}
                        {action && actionLabel && (
                          <div className="flex w-auto">
                            <FilledButton label={actionLabel} action={action}>
                              <span className="hidden xl:flex w-5 ml-2">
                                <Excel />
                              </span>
                            </FilledButton>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
