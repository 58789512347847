import { useContext, useEffect, useState } from 'react'

import { getDashboard } from 'services/dashboard'
import { AuthContext } from '../../contexts/AuthContext'
import { AppContext } from 'contexts/AppContext'

import { Campaign } from './campaign'
// import { Information } from './information';

import {
  NotificationContainer,
  useNotification,
  FilledButton,
  Modal,
  Loader,
} from 'components'
import { Newsletter } from './newsletter'
import { Trainings } from './trainings'
import { Information } from './information'
import { useQuery } from '@tanstack/react-query'

const DashboardBroker = () => {
  const { currentUser } = useContext(AuthContext)
  const { settings } = useContext(AppContext)
  const { notifications, notify } = useNotification()
  const [modalVisibility, setModayVisibility] = useState(false)
  /* const today = moment().format('YYYY-MM-DD') */

  const { data, isLoading } = useQuery({
    queryKey: ['getDashboard'],
    queryFn: () => getDashboard(),
  })

  useEffect(() => {
    if (currentUser && (!currentUser?.birthdate || !currentUser?.id_card)) {
      setModayVisibility(true)
    }
  }, [currentUser])

  const closeModal = () => {
    setModayVisibility(false)
  }

  if (isLoading || !settings) {
    return <Loader />
  }

  /*TODO Revisit incomplete code*/

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const campaignImage = settings.find(
    (item: any) => item.name === 'campaignImage',
  )?.value
  const newsletterImage = settings.find(
    (item: any) => item.name === 'newsletterImage',
  )?.value
  const infoImage = settings.find(
    (item: any) => item.name === 'infoImage',
  )?.value
  const trainingsImage = settings.find(
    (item: any) => item.name === 'trainingsImage',
  )?.value

  return (
    <div className="w-full">
      <NotificationContainer notifications={notifications} />

      {data.campaign && (
        <Campaign content={data.campaign} image={campaignImage} />
      )}

      {/* <Charts /> */}

      {data.trainings && (
        <Trainings
          content={data.trainings}
          image={trainingsImage}
          userID={currentUser?.id!}
        />
      )}

      {data.newsletter && (
        <Newsletter content={data.newsletter} image={newsletterImage} />
      )}

      {data.information && (
        <Information content={data.information} image={infoImage} />
      )}

      {modalVisibility && (
        <Modal onClose={closeModal}>
          <h2 className="text-black font-mont font-medium text-xxxl pb-8">
            Complete o seu perfil
          </h2>
          <p className="text-black font-lato">
            Estes dados são necessarios para que possa receber os certificados
            de participação nos webinares Innovarisk.
          </p>
          <div className="flex justify-end">
            <FilledButton url="/profile" label="Editar perfil" />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default DashboardBroker
