import { Wrapper } from 'components'
import { locale } from 'locale'

export const NotFound = () => (
  <Wrapper>
    <>
      <h1 className="font-mont text-xxxl py-8 text-brand">{locale.notFound}</h1>
      {locale.notFoundInfo}
    </>
  </Wrapper>
)
