import {
  Body,
  Column,
  Container,
  Head,
  Heading,
  Hr,
  Html,
  Img,
  Link,
  Row,
  Section,
  Tailwind,
} from '@react-email/components'

export const Header = () => {
  return (
    <Section>
      <Row cellPadding={24}>
        <Img
          src="https://innovagouploadsdev.blob.core.windows.net/public-images/innovago-logo.png"
          alt="Innorisk"
          className="mb-4"
        />
      </Row>
      <Hr className="border border-solid border-brand" />
    </Section>
  )
}

export const Footer = () => {
  return (
    <Section className="w-full bg-darkGrey px-3 py-4">
      <Row>
        <Column>
          <Row width="312px" align="left">
            <Heading className="text-base uppercase text-white tracking-widest font-bold m-0">
              contactos
            </Heading>
            <Hr className="border border-solid border-white" />
          </Row>
          <Row width="312px" align="left">
            <Column width="150px">
              <Link
                className="text-sm underline text-white inline-block tracking-wider font-mont leading-6"
                href="tel:00351211351290"
              >
                +351 211 351 290
              </Link>
            </Column>

            <Column>
              <Link
                href="mailto:innovago@innovarisk.pt"
                className="text-sm underline text-white inline-block tracking-wider font-mont leading-6"
              >
                innovago@innovarisk.pt
              </Link>
            </Column>
          </Row>
        </Column>
        <Column width="60%">
          <Row align="left" className="mt-6">
            <Column>
              <Link href="https://innovarisk.pt">
                <Img
                  src="https://innovagouploadsdev.blob.core.windows.net/public-images/innovarisk-logo-footer.png"
                  alt="Innorisk"
                />
              </Link>
            </Column>

            <Column>
              <Img
                className="ml-4"
                src="https://innovagouploadsdev.blob.core.windows.net/public-images/hiscox.png"
                alt="Hiscox"
              />
            </Column>
            <Column>
              <Img
                className="ml-4"
                src="https://innovagouploadsdev.blob.core.windows.net/public-images/lloyds.png"
                alt="Lloyds"
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </Section>
  )
}

export const EmailTemplate = ({ children }: { children: JSX.Element }) => {
  return (
    <Html>
      <Head />
      <Tailwind
        config={{
          theme: {
            extend: {
              colors: {
                brand: '#e86137',
                darkGrey: '#313131',
              },
            },
          },
        }}
      >
        <Body>
          <Container
            style={{ maxWidth: 'none', padding: '20px' }}
            className="p-5 max-w-none"
          >
            <Header />
            {children}
            <Footer />
          </Container>
        </Body>
      </Tailwind>
    </Html>
  )
}
