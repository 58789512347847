import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'
import {
  PoliciesParams,
  PolicyAddress,
  PolicyAddressResponse,
} from 'types/policies'

export const getPolicies = async ({
  params,
}: {
  params: Partial<PoliciesParams>
}) => {
  try {
    const { data } = await axiosClient.get('/isaac/policylist', {
      params,
    })
    return data.Policies
  } catch (error) {
    return handleServerError(error)
  }
}

export const getPolicy = async ({ policyNo }: { policyNo: number }) => {
  try {
    const response = await axiosClient.get(`/isaac/policies/${policyNo}`)
    return response.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getPolicyAddress = async ({
  policyNo,
}: {
  policyNo: number
}): Promise<PolicyAddress[]> => {
  try {
    const response: PolicyAddressResponse = await axiosClient.get(
      `/isaac/policyaddress/${policyNo}`,
    )
    return response.data.PolicyAddress
  } catch (error) {
    return handleServerError(error) as any
  }
}

export const updatePolicyAddress = async ({
  policyNo,
  brokerNo,
  nif,
  address,
}: {
  policyNo: number
  brokerNo?: string
  nif?: string
  address: PolicyAddress[]
}) => {
  try {
    const response = await axiosClient.put(`/isaac/policyaddress/${policyNo}`, {
      address,
      brokerNo,
      nif,
    })
    return response.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const createPolicy = async (answers: string) => {
  try {
    const { data } = await axiosClient.post(`/isaac/policies`, {
      answers,
    })
    return data
  } catch (error) {
    handleServerError(error)
  }
}

export const getRate = async (answers: any) => {
  try {
    const { data } = await axiosClient.post(`/isaac/rate`, { ...answers })
    return data
  } catch (error) {
    throw error
  }
}

export const getPolicyDocumentation = async ({
  policyNo,
  eventNo,
}: {
  policyNo: number
  eventNo: number
}) => {
  const { data } = await axiosClient.get(
    `/isaac/policies/${policyNo}/events/${eventNo}/documents`,
    { responseType: 'arraybuffer' },
  )
  return data
}

export const downloadPoliciesExcel = async ({
  params,
}: {
  params: PoliciesParams
}) => {
  const downloadParams: PoliciesParams = {
    ...params,
    output: 'Excel',
  }
  try {
    const { data } = await axiosClient.get('/isaac/policies/excel', {
      params: downloadParams,
      responseType: 'arraybuffer',
    })
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getQuotations = async ({ params }: { params: PoliciesParams }) => {
  try {
    const { data } = await axiosClient.get('/isaac/quotations', { params })
    return data.Policies
  } catch (error) {
    throw error
  }
}

export const downloadQuotationsExcel = async ({
  params,
}: {
  params: PoliciesParams
}) => {
  const downloadParams: PoliciesParams = {
    ...params,
    output: 'Excel',
  }
  try {
    const { data } = await axiosClient.get('/isaac/quotations/excel', {
      params: downloadParams,
      responseType: 'arraybuffer',
    })
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const sendPolicyDocuments = async ({
  policyNo,
  files,
}: {
  policyNo: number
  files: File[]
}) => {
  try {
    const formData = new FormData()
    files.forEach(file => {
      formData.append('policyDocs', file)
    })

    const { data } = await axiosClient.post(
      `/isaac/policies/${policyNo}/documents`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    return data
  } catch (error) {
    throw error
  }
}
