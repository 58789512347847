export const FormInput = ({
  formik,
  label,
  required,
  placeholder,
  name,
  type,
  extraClasses,
  inline,
  disabled,
  onChange,
}: {
  formik: any
  label: string
  required?: boolean
  placeholder?: string
  name: string
  type: string
  extraClasses?: string
  inline?: boolean
  disabled?: boolean
  onChange?: () => void
}) => {
  const { error, touched } = formik.getFieldMeta(name)
  return (
    <div className="flex flex-col">
      <div
        className={`w-full mb-3 ${inline ? 'items-center' : ''} ${
          extraClasses ?? ''
        }`}
      >
        {label && (
          <label
            className={`font-lato text-md leading-5 text-darkBlue block ${
              inline ? 'w-[40%]' : 'pb-2'
            }`}
          >
            {label}
            {required && <span className="text-brand">*</span>}
          </label>
        )}
        <input
          className={`font-lato text-sm font-semibold text-black px-4 leading-[48px] w-full rounded border h-[38px]  ${
            error ? 'border-error' : 'border-black'
          } bg-transparent outline-none placeholder:text-grey focus:outline-none disabled:bg-lightGrey disabled:cursor-not-allowed`}
          value={formik.getFieldProps(name).value}
          type={type}
          placeholder={placeholder}
          disabled={disabled ? disabled : false}
          onChange={
            onChange
              ? onChange
              : value => formik.setFieldValue(name, value.target.value)
          }
        />
      </div>
      {error && touched && (
        <span className="w-full font-lato text-xs mb-4">{error}</span>
      )}
    </div>
  )
}
