import { useContext } from 'react'

import { AuthContext } from '../../contexts/AuthContext'
/* 
import { EditPassword } from './editPassword' */
import { EditProfileClient } from './editProfileClient'
import { NotificationContainer, Wrapper, useNotification } from 'components'
import { locale } from 'locale'
import { EditPassword } from './editPassword'
import { Client } from 'types/clients'

const ProfileClient = () => {
  const { logout, currentUser } = useContext(AuthContext)
  const { notifications, notify } = useNotification()

  const user = currentUser as unknown as Client
  if (!user) {
    logout()
    return null
  }

  return (
    <div className="pt-12 pb-[136px]">
      <Wrapper>
        <NotificationContainer notifications={notifications} />

        <h2 className="text-sm font-mont text-darkBlue pb-2 uppercase">
          perfil pessoal
        </h2>

        <h1 className="text-xxxl font-medium font-mont text-brand tracking-widest pb-10">
          {locale.profile}
        </h1>

        <div className="pb-12 items-start grid md:grid-cols-2 gap-4">
          <EditProfileClient
            initialValues={{
              email: user.email!,
              name: user.name!,
              nif: user.nif,
              phone: user.phone!,
              id: user.id,
            }}
            notify={notify}
          />
          <EditPassword notify={notify} />
        </div>
      </Wrapper>
    </div>
  )
}

export default ProfileClient
