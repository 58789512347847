export const MenuProducts = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
    <path
      fill='currentcolor'
      d='M25.53,25.29,22.15,15.11l.61.32c.11.05.22.08.42-.1l1.73-3.25a.35.35,0,0,0-.17-.41L16.38,7.3l-1.93,3.78,4.63,2.42-.2.4L15.07,25.29l-2.74,8.28a.58.58,0,0,0,.1.58.7.7,0,0,0,.58.2A.64.64,0,0,0,13.5,34L16,26.26l3.62,1.17v8a.59.59,0,0,0,1.18,0v-8l3.61-1.17L27,34a.68.68,0,0,0,.79.39.6.6,0,0,0,.48-.78Zm-6,.88L16.34,25.1l3.22-9.64Zm1.28,0V15.36L24.06,25Z'
    />
    <path
      fill='currentcolor'
      d='M34.32,15.67l-1.63-.86h-.1a.37.37,0,0,0-.31.18l-2.42,4.73a.35.35,0,0,0,.17.41l1.66.88a.22.22,0,0,0,.15,0,.69.69,0,0,0,.23-.19l2.42-4.74A.33.33,0,0,0,34.32,15.67Z'
    />
    <polygon
      fill='currentcolor'
      points='25.45 12.6 23.89 15.53 29.65 18.54 31.16 15.59 25.45 12.6'
    />
    <path
      fill='currentcolor'
      d='M13.36,11.36l0,0a.5.5,0,0,0,.21-.17l.05-.1.17-.33L15.74,7l.16-.33L16,6.43a.71.71,0,0,0,0-.14A.34.34,0,0,0,15.84,6L4.34,0h-.1a.36.36,0,0,0-.31.18L1.51,4.92a.33.33,0,0,0,.17.4l11.54,6S13.27,11.39,13.36,11.36Z'
    />
  </svg>
);
