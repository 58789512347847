import { Invoice } from 'types/invoices'
import { formatCurrency } from './currency'
import { formatDate } from './dates'

type InvoiceKeys = keyof Invoice

export const formatInvoice = (invoice: Invoice) => {
  const invoiceKeys = Object.keys(invoice) as InvoiceKeys[]
  const invoiceDetails = invoiceKeys.map(key => {
    if (key === 'Pago por') return
    const premiumKeys = ['Prémio Comercial', 'Prémio Total', 'Comissão']
    const dateKeys = ['Data de Emissão', 'Data de Cobrança']
    const value = premiumKeys.includes(key)
      ? formatCurrency(invoice[key] as number)
      : dateKeys.includes(key)
      ? formatDate(invoice[key] as string).dashed
      : invoice[key]

    return {
      label: key,
      value,
    }
  })
  return invoiceDetails
}
