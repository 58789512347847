import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'

import {
  FilledButton,
  Loader,
  NotificationContainer,
  Wrapper,
  useNotification,
} from 'components'
import { ClientBanner } from './clientBanner'
import { ClientPolicies } from './clientPolicies'
import { getContent } from 'services/content'
import { getPolicies } from 'services/policies'
import { AuthContext } from 'contexts/AuthContext'
import { Client } from 'types/clients'
import { Link } from 'react-router-dom'

const DashboardClient = () => {
  const { notifications, notify } = useNotification()
  const { currentUser } = useContext(AuthContext)
  const client = currentUser as unknown as Client

  const { data: banner, isLoading: loading1 } = useQuery({
    queryKey: ['getBannerContent'],
    queryFn: () =>
      getContent({
        params: {
          type: 'process',
          status: 'published',
          slug: 'client-dashboard',
        },
      }),
  })

  const { data: policy, isLoading: loading2 } = useQuery({
    queryKey: ['getPoliciesContent'],
    queryFn: () =>
      getContent({
        params: {
          type: 'process',
          status: 'published',
          slug: 'client_dashboard',
        },
      }),
  })

  const { data: clientPolicies, isLoading: isLoadingPolicies } = useQuery({
    queryKey: ['getClientPolicies'],
    queryFn: async () => await getPolicies({ params: { nif: client?.nif } }),
  })

  if (loading1 || loading2 || isLoadingPolicies) {
    return <Loader />
  }

  const bannerBody = JSON.parse(banner?.content?.[0]?.body)
  const policiesBody = JSON.parse(policy?.content?.[0]?.body)
  const headerItems = policiesBody.filter((c: any) => c.type === 'header')
  const formItem = policiesBody.filter(
    (c: any) => c.type === 'formPlaceholder',
  )[0]

  return (
    <div>
      <NotificationContainer notifications={notifications} />
      <ClientBanner data={bannerBody} title={banner.title} />
      <div className="pt-12 pb-[136px]">
        <NotificationContainer notifications={notifications} />

        <Wrapper>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="h-full border-grey pr-8 border-b pb-8 md:border-r md:border-b-0 md:border-grey md:pr-8 md:pb-8">
              {headerItems[0]?.data && (
                <h2 className="text-darkBlue text-xl font-mont font-medium pb-4">
                  {headerItems[0]?.data?.text}
                </h2>
              )}
              <ClientPolicies policies={clientPolicies} />
            </div>

            <div className="h-full">
              {headerItems[0]?.data && (
                <h2 className="text-darkBlue text-xl font-mont font-medium pb-4">
                  {headerItems[1]?.data?.text}
                </h2>
              )}
              <div className="my-12 p-6 bg-lightGrey">
                <h3 className="font-lato text-sm text-darkBlue tracking-widest leading-5 font-medium uppercase pb-4">
                  {formItem?.data?.title}
                </h3>
                <p className="font-latotext-black leading-7 pb-4">
                  {formItem?.data?.text}
                </p>
                <div className="flex justify-end">
                  <Link to="/sinistros/sinistros">
                    <FilledButton label="FORMULÁRIO" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  )
}

export default DashboardClient
