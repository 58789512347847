import { AuthContext } from 'contexts/AuthContext'
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isSignedIn } = useContext(AuthContext)

  if (!isSignedIn) {
    return <Navigate to="/login" replace />
  }

  return <>{children}</>
}
