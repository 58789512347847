import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import pt from 'date-fns/locale/pt'
import { formatDate } from 'utils/dates'
registerLocale('pt', pt)
export const FormDate = ({
  formik,
  label,
  name,
  required,
  inline,
  extraClasses,
  action,
}: {
  formik: any
  label: string
  name: string
  required?: boolean
  inline?: boolean
  extraClasses?: string
  disabled?: boolean
  action?: (date: string) => void
}) => {
  const field = formik.getFieldProps(name)
  const { error, touched } = formik.getFieldMeta(name)
  return (
    <div className="flex flex-col">
      <div
        className={`relative w-full ${error ? 'mb-1' : 'mb-6'} ${
          inline ? 'items-center' : ''
        } ${extraClasses ?? ''}`}
      >
        {label && (
          <label
            className={`font-lato text-md leading-5 text-darkBlue block ${
              inline ? 'w-[40%]' : 'pb-2'
            }`}
          >
            {required && <span className="text-brand">*</span>}
            {label}
          </label>
        )}
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={field.value}
          onChange={(date: any) =>
            formik
              ? formik.setFieldValue(name, formatDate(date).slashed)
              : action && action(date)
          }
          className={`font-lato text-sm font-semibold text-black !px-10 leading-[38px] w-full rounded border h-[38px] ${
            error ? 'border-error' : 'border-black'
          } bg-transparent outline-none placeholder:text-grey focus:outline-none disabled:bg-lightGrey disabled:cursor-not-allowed`}
          showIcon
          calendarIconClassname="flex items-center justify-center"
          todayButton="Hoje"
          isClearable={true}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={15}
          useShortMonthInDropdown
        />
      </div>
      {error && touched && (
        <span className="w-full font-lato text-xs text-error mb-4">
          {error}
        </span>
      )}
    </div>
  )
}
