import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'

export type productsParams = {
  orderBy?: 'asc' | 'desc'
  status?: 'published' | 'draft'
}

export const getProducts = async ({ params }: { params: productsParams }) => {
  try {
    const { data } = await axiosClient.get('/products', { params })
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getProduct = async ({
  id,
  params,
}: {
  id: number
  params?: { simulator: boolean }
}) => {
  try {
    const { data } = await axiosClient.get(`/products/${id}`, { params })
    return data
  } catch (error) {
    return handleServerError(error)
  }
}
