import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'
import { BrokerById } from 'types/broker'

export const getMe = async () => {
  try {
    const { data } = await axiosClient.get(`/user/me`)
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateUserDetails = async (id: number, userInfo: any) => {
  try {
    const { data } = await axiosClient.patch(`/user/${id}`, userInfo)
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getUserDetail = async (id: number) => {
  try {
    const { data } = await axiosClient.get(`/user/${id}`)
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

export const getBrokerDetails = async (id: number): Promise<BrokerById> => {
  try {
    const { data } = await axiosClient.get(`/broker/${id}`)
    return data
  } catch (error) {
    return handleServerError(error) as any
  }
}

export const registerUser = async ({
  invite_id,
  password,
}: {
  invite_id: string
  password: string
}) => {
  try {
    const { data } = await axiosClient.post('/user/signup', {
      invite_id,
      password,
    })
    return data
  } catch (error) {
    throw error
  }
}
