export const MenuProposals = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
    <path
      fill='currentcolor'
      d='M15.3,15.61,8,22.9l-.85-.84L3.77,23.75l8.48,8.48,1.69-3.39L13.1,28,26.66,14.42a3.59,3.59,0,0,0-5.08-5.08l-5.43,5.43-.85-.85h0v-3.4l.4-.39L8.91,3.34,7.22,5,14,11.82l.1-.1v1.85H12.26l.09-.1L5.57,6.69,3.87,8.38l6.79,6.79.4-.41h3.39Z'
    />
    <path
      fill='currentcolor'
      d='M33.45,9.34,30.53,6.42l.91-.91a2.39,2.39,0,1,0-.85-.84l-.91.9-3-3a4.8,4.8,0,1,0,6.79,6.79ZM31.8,2.6a1.22,1.22,0,0,1,1.7,0,1.2,1.2,0,1,1-1.7,0Z'
    />
    <rect
      fill='currentcolor'
      x='13.6'
      y='-0.56'
      width='2.4'
      height='9.59'
      transform='translate(1.34 11.71) rotate(-45)'
    />
    <rect
      fill='currentcolor'
      x='3.57'
      y='9.47'
      width='2.4'
      height='9.59'
      transform='translate(-8.69 7.55) rotate(-45)'
    />
    <rect
      fill='currentcolor'
      x='30.56'
      y='16.4'
      width='2.4'
      height='9.59'
      transform='translate(-5.69 28.67) rotate(-45)'
    />
    <rect
      fill='currentcolor'
      x='20.53'
      y='26.43'
      width='2.4'
      height='9.59'
      transform='translate(-15.72 24.51) rotate(-45)'
    />
    <polygon
      fill='currentcolor'
      points='25.48 20.7 22.08 20.7 22.08 20.7 21.23 19.86 20.39 20.7 21.23 21.55 21.24 24.94 20.83 25.34 27.62 32.13 29.31 30.43 22.53 23.65 22.43 23.74 22.43 21.9 24.28 21.9 24.18 22 30.96 28.78 32.66 27.09 25.87 20.3 25.48 20.7'
    />
    <path
      fill='currentcolor'
      d='M7.16,28.84a.58.58,0,0,0-.84,0L2.08,33.08a.58.58,0,0,0,0,.84.59.59,0,0,0,.85,0l4.23-4.24A.58.58,0,0,0,7.16,28.84Z'
    />
    <path
      fill='currentcolor'
      d='M9.88,31.8a.6.6,0,0,0-.6-.6.6.6,0,0,0-.6.6v3.6a.56.56,0,0,0,.18.42.59.59,0,0,0,1-.42Z'
    />
    <path
      fill='currentcolor'
      d='M4.2,26.12H.6a.59.59,0,0,0-.42,1,.56.56,0,0,0,.42.17H4.2a.6.6,0,0,0,.6-.6A.6.6,0,0,0,4.2,26.12Z'
    />
  </svg>
);
