import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'
import { UserInvite } from 'types/invite'

export const getInvite = async (id: string) => {
  try {
    const { data } = await axiosClient.get(`invite/${id}`)
    return data
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}

export const createUserInvite = async (iniviteData: UserInvite) => {
  try {
    const { data } = await axiosClient.post(`invite`, { ...iniviteData })
    return data
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}

export const deleteInvite = async (id: string) => {
  try {
    const { data } = await axiosClient.delete(`invite/${id}`)
    return data
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}

export const updateInvite = async (
  id: string,
  iniviteData: Partial<UserInvite>,
) => {
  try {
    const { data } = await axiosClient.patch(`invite/${id}`, { ...iniviteData })
    return data
  } catch (err) {
    const { error, status } = handleServerError(err)
    throw new Error(`status: ${status}, error: ${error}`)
  }
}
