export const Video = ({
  classes,
  videoId,
}: {
  classes: string;
  videoId: string;
}) => {
  return (
    <iframe
      title={`video_${videoId}`}
      className={classes}
      src={`https://www.youtube.com/embed/${videoId}?rel=0&controls=0&modestbranding&autoplay=0`}
    />
  );
};
