import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { getLocalizedText, locale } from 'locale'
import {
  Filter,
  FilterType,
  Loader,
  Wrapper,
  useNotification,
} from 'components'
import { Campaigns } from './Campaigns'
import { Others } from './Others'
import { getContent } from 'services/content'
import { useParams } from 'react-router-dom'

export const Communications = () => {
  const { type } = useParams() as {
    type: 'campaign' | 'newsletter' | 'information'
  }

  const today = moment().add(1, 'days').format('YYYY-MM-DD')
  const { notify } = useNotification()

  const { data, isLoading, isError } = useQuery({
    queryKey: ['getCommunications', { type }],
    queryFn: () =>
      getContent({
        params: {
          type,
          status: 'published',
          limit: 10,
          offset: 0,
          publish_at: today,
        },
      }),
  })

  const filters: FilterType[] = [
    {
      id: 'tipo',
      name: 'Tipo',
      type: 'option',
      options: [
        {
          name: 'Campanha',
          id: 0,
          link: '/communications/campaign',
          checked: type === 'campaign',
        },
        {
          name: 'Newsletter',
          id: 1,
          link: '/communications/newsletter',
          checked: type === 'newsletter',
        },
        {
          name: 'Flash',
          id: 2,
          link: '/communications/information',
          checked: type === 'information',
        },
      ],
    },
  ]

  if (isError) {
    notify()
  }
  if (isLoading) {
    return <Loader />
  }
  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <h2 className="uppercase text-sm font-medium text-darkBlue">
          {locale.ourCommunications}
        </h2>
        <h1 className="pb-10 text-xxxl font-medium text-brand">
          {getLocalizedText(type, 'plural') as string}
        </h1>
        <Filter navigation={true} filters={filters} />

        {type === 'campaign' ? (
          <Campaigns data={data.content} />
        ) : (
          <Others loading={!data} data={data.content} />
        )}
      </Wrapper>
    </div>
  )
}
