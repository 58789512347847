import { Eye } from 'components/Icons/eye'
import { EyeSlash } from 'components/Icons/eyeslash'
import { useState } from 'react'

export const FormPassword = ({
  label,
  name,
  formik,
  extraClasses,
}: {
  label: string
  name: string
  formik: any
  extraClasses?: string
}) => {
  const [visible, setVisible] = useState(false)
  const { error, touched } = formik.getFieldMeta(name)
  return (
    <div className={`relative w-full mb-3 ${extraClasses}`}>
      {label && (
        <label className="font-lato text-sm leading-5 text-darkBlue pb-2 block">
          {label}
          <span className="text-brand">*</span>
        </label>
      )}
      <div
        className={`flex px-4 justify-between items-center rounded border ${
          error ? 'border-error' : 'border-black'
        }`}
      >
        <input
          className={`font-lato text-sm font-semibold text-black px-4 leading-[48px] height-[48px] w-[calc(100% - 30px)] bg-transparent outline-none placeholder:text-grey focus:outline-none hover:text-brand `}
          type={visible ? 'text' : 'password'}
          onChange={value => formik.setFieldValue(name, value.target.value)}
        />
        <button
          className="bg-transparent border-none outrline-none cursor-pointer [&_svg]:size-4"
          onClick={e => {
            e.preventDefault()
            setVisible(!visible)
          }}
        >
          {visible ? <EyeSlash /> : <Eye />}
        </button>
      </div>
      {error && touched && (
        <span className="py-2 block w-full font-lato text-xs ">{error}</span>
      )}
    </div>
  )
}
