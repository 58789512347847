import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  NotificationContainer,
  useNotification,
  FileType,
  FormInput,
  Dropzone,
  FileGroup,
  FilledButton,
  LoaderInline,
} from 'components'
import { useEffect, useState } from 'react'
import { sendBrokerAppointmentEmail } from 'services/email'
import { BrokerAppointmentEmail } from 'emails/invites'
import { render } from '@react-email/components'
import { useMutation } from '@tanstack/react-query'
import {
  BrokerData,
  createBrokerAppointment,
  updateBrokerAppointment,
  UpdateBrokerData,
} from 'services/broker'

const registerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  vat: Yup.number().required('Obrigatório'),
  phone: Yup.string().required('Obrigatório'),
  forename: Yup.string().required('Obrigatório'),
  email: Yup.string().email().required('Obrigatório'),
})

const updateValidationSchema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  vat: Yup.number().required('Obrigatório'),
  phone: Yup.string().required('Obrigatório'),
})

const before =
  'before:inline-block before:align-middle before:text-center before:w-10 before:h-10 before:leading-10 before:border-2 before:border-darkBlue before:rounded-full before:mr-4'

const initialFiles: FileType[] = []
const MAX_FILES = 5

export const BrokerForm = ({
  type,
  broker,
  reviewToken,
  onSuccess,
}: {
  type: string
  broker?: any
  reviewToken?: string
  onSuccess: (email?: string) => void
}) => {
  const { notifications, notify } = useNotification()
  const [files, setFiles] = useState(initialFiles)

  useEffect(() => {
    if (broker && broker?.attachments) {
      const attachments = broker.attachments.map((file: any) => ({
        ...file,
        name: file.file_name,
        size: file.file_size,
      }))
      setFiles(attachments)
    }
  }, [broker, reviewToken])

  const onAddFile = async (acceptedFiles: FileType[]) => {
    if (files.length < MAX_FILES) {
      setFiles(prevFiles => [...prevFiles, ...acceptedFiles])
    }
  }
  const onRemoveFile = (item: FileType) => {
    const newFiles = files.filter((file: FileType) => file.name !== item.name)
    setFiles(newFiles)
  }

  const { isPending: isPendingEmail, mutateAsync: sendAppointmentEmail } =
    useMutation({
      mutationKey: ['sendBrokerAppointmentEmail'],
      mutationFn: async (data: any) => {
        const html = render(
          <BrokerAppointmentEmail data={data} />,

          {
            pretty: true,
          },
        )
        await sendBrokerAppointmentEmail({
          email: data.email,
          attachments: data.attachments,
          htmlMessage: html,
        })
      },
      onSuccess: () => {
        onSuccess(formik.values.email.toLocaleLowerCase())
      },
      onError: () => {
        notify('OOcorreu um erro ao submeter email', 'error')
      },
    })

  const { mutateAsync: createBrokerMutation, isPending: isPendingCreate } =
    useMutation({
      mutationKey: ['createBroker'],
      mutationFn: createBrokerAppointment,
      onSuccess: async data =>
        await sendAppointmentEmail({ ...data, attachments: files as File[] }),
      onError: () => {
        notify('Ocorreu um erro ao submeter a inscrição', 'error')
      },
    })

  const { mutateAsync: updateBrokerMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationKey: ['updateBroker'],
      mutationFn: ({ id, data }: { id: number; data: UpdateBrokerData }) =>
        updateBrokerAppointment({ id, brokerData: data }),
      onSuccess: async data =>
        await sendAppointmentEmail({
          ...data,
          attachments: files.filter(e => e instanceof File),
        }),
      onError: () => notify('Ocorreu um erro ao submeter a inscrição', 'error'),
    })

  const validationSchema =
    type === 'register' ? registerValidationSchema : updateValidationSchema

  const isPending = isPendingCreate || isPendingUpdate || isPendingEmail
  const formik = useFormik({
    initialValues: {
      name: broker?.name ?? '',
      vat: broker?.vat ?? '',
      phone: broker?.phone ?? '',
      forename: broker?.forename ?? '',
      email: broker?.email ?? '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      if (type === 'register') {
        const submitData: BrokerData = {
          ...values,
          files: files as any,
        }
        await createBrokerMutation(submitData)
      } else {
        const newFiles = files.filter(file => file instanceof File)

        const brokerData = {
          name: values.name,
          vat: values.vat,
          phone: values.phone,
          files: newFiles as File[],
        }

        await updateBrokerMutation({
          id: broker.id,
          data: brokerData,
        })
      }
    },
  })

  return (
    <div className="md:pl-8">
      <NotificationContainer notifications={notifications} />
      <h2
        className={`font-mont font-medium text-xl leading-4 text-darkBlue py-4 before:content-['3'] ${before}`}
      >
        Identificação da empresa
      </h2>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-y-4 pt-4"
      >
        <FormInput
          label="Empresa"
          type="text"
          formik={formik}
          name="name"
          required
          placeholder="Nome da empresa"
        />
        <FormInput
          label="NIF Empresa"
          type="text"
          formik={formik}
          name="vat"
          required
          placeholder="NIF"
        />
        <FormInput
          label="Telefone / Telémovel"
          type="text"
          formik={formik}
          name="phone"
          required
          placeholder="+351 20 000 00 00"
        />
        {type === 'register' && (
          <>
            <FormInput
              label="Nome do Administrador"
              type="text"
              formik={formik}
              name="forename"
              required
              placeholder="Nome do adminitrsador da conta empresarial"
            />
            <FormInput
              label="Email"
              type="text"
              formik={formik}
              name="email"
              required
              placeholder="Email do administrador"
            />
          </>
        )}
        <p className="font-lato font-normal text-base text-darkGrey">
          Anexe os 5 documentos para nomeação (tamanho máximo 10MB):
        </p>
        <Dropzone
          maxFiles={1}
          maxSize={10485760}
          fileTypes={{ 'application/pdf': ['.pdf'] }}
          compact
          label="Ficha de nomeação"
          onAddFile={files => onAddFile(files)}
        />
        <Dropzone
          maxFiles={1}
          maxSize={10485760}
          fileTypes={{ 'application/pdf': ['.pdf'] }}
          compact
          label="Apólice de Responsabilidade Civil"
          onAddFile={files => onAddFile(files)}
        />
        <Dropzone
          maxFiles={1}
          maxSize={10485760}
          fileTypes={{ 'application/pdf': ['.pdf'] }}
          compact
          label="Relatório e Contas"
          onAddFile={files => onAddFile(files)}
        />
        <Dropzone
          maxFiles={1}
          maxSize={10485760}
          fileTypes={{ 'application/pdf': ['.pdf'] }}
          compact
          label="Certificado de ASF de Mediador"
          onAddFile={files => onAddFile(files)}
        />
        <Dropzone
          maxFiles={1}
          maxSize={10485760}
          fileTypes={{ 'application/pdf': ['.pdf'] }}
          compact
          label="IBAN"
          onAddFile={files => onAddFile(files)}
        />
        <FileGroup files={files} onRemove={file => onRemoveFile(file)} />
        <div className="flex justify-end">
          {isPending ? (
            <LoaderInline />
          ) : (
            <FilledButton label="Submeter" type="submit" />
          )}
        </div>
      </form>
    </div>
  )
}
