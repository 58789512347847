export const Wrapper = ({
  children, extraClasses
}: {
  children: string | JSX.Element | JSX.Element[], extraClasses?: string
}) => (
  <div className={`${extraClasses} w-full max-w-[1192px] m-auto px-3 md:px-8 2xl:max-w-[1400px] font-mont`}>
    {children}
  </div>
)

export const WrapperSmall = ({
  children,
}: {
  children: string | JSX.Element | JSX.Element[]
}) => <div className="w-full max-w-[580px] m-auto px-8">{children}</div>
