export const Download = ({ extraClasses }: { extraClasses?: string }) => (
  <svg
    className={extraClasses}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 17"
  >
    <path
      d="M14 6h-4V0H4v6H0l7 7zM0 15v2h14v-2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
