import { Text } from '@react-email/components'
import { EmailTemplate } from 'emails/template'

export const UserRegistrationEmail = ({
  name,
  url,
}: {
  name: string
  url: string
}) => (
  <EmailTemplate>
    <>
      <Text className="text-base font-bold text-darkGrey py-6">
        Assunto: Foi convidado para aceder ao portal Innova|GO
      </Text>
      <Text className="text-base text-darkGrey m-0">Caro/a {name}</Text>
      <Text className="text-base text-darkGrey m-0 pb-6">
        Termine registo no portal InnovaGo neste link {url}
      </Text>
    </>
  </EmailTemplate>
)

export const BrokerAppointmentEmail = ({ data }: { data: any }) => {
  return (
    <EmailTemplate>
      <>
        <Text className="text-base font-bold text-darkGrey py-6">
          Assunto: Pedido de nomeação de corretor Innova | GO
        </Text>
        <Text className="text-base text-darkGrey m-0">
          Empresa: {data.name}
        </Text>
        <Text className="text-base text-darkGrey m-0">Email:{data.email}</Text>

        <Text className="text-base text-darkGrey m-0">
          Telefone:{data.phone}
        </Text>
        <Text className="text-base text-darkGrey m-0">
          NIF Empresa: {data.vat}
        </Text>
        <Text className="text-base text-darkGrey mb-8">
          Nome do Administrador: {data.forename}
        </Text>
      </>
    </EmailTemplate>
  )
}

export const RecoverPasswordEmail = ({
  url,
  token,
}: {
  url: string
  token: string
}) => (
  <EmailTemplate>
    <>
      <Text className="text-base font-bold text-darkGrey py-6">
        Assunto: Foi solicitada a recuperação da palavra-passe
      </Text>
      <Text className="text-base text-darkGrey m-0 pb-6">
        Termine a recuperação através do link: {url}/reset-password/{token}
      </Text>
    </>
  </EmailTemplate>
)
