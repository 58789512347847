import { useContext } from 'react'

import { AuthContext } from '../../contexts/AuthContext'
import { NotificationContainer, Wrapper, useNotification } from 'components'
import { locale } from 'locale'
import { EditProfile } from './editProfile'
import { formatDate } from 'utils/dates'
import { EditPassword } from './editPassword'

const ProfileBroker = () => {
  const { logout, currentUser } = useContext(AuthContext)
  const { notifications, notify } = useNotification()

  if (!currentUser) {
    logout()
    return null
  }

  const initialValues = {
    birthdate: currentUser.birthdate
      ? formatDate(new Date(currentUser.birthdate).toISOString()).slashed
      : '',
    id_card_expiricy: currentUser.id_card_expiricy
      ? formatDate(new Date(currentUser.id_card_expiricy).toISOString()).slashed
      : null,
    forename: currentUser.forename!,
    id_card: currentUser.id_card!,
  }

  return (
    <div className="pt-12 pb-÷136px]">
      <Wrapper>
        <NotificationContainer notifications={notifications} />

        <h2 className="text-sm font-mont text-darkBlue pb-2 uppercase">
          perfil pessoal
        </h2>

        <h1 className="text-xxxl font-medium font-mont text-brand tracking-widest pb-10">
          {locale.profile}
        </h1>

        <div className="pb-12 items-start grid md:grid-cols-2 gap-4">
          <EditProfile initialValues={initialValues} notify={notify} />
          <EditPassword notify={notify} />
        </div>
      </Wrapper>
    </div>
  )
}

export default ProfileBroker
