export const FormCheckbox = ({
  formik,
  name,
  label,
}: {
  name: string
  label: string
  formik: any
}) => {
  const { error, touched, value } = formik.getFieldMeta(name)

  return (
    <div className="flex flex-col">
      <div className="flex items-center form_checkbox">
        <input
          id={name}
          name={name}
          type="checkbox"
          checked={value ? true : false}
          onChange={() => formik.setFieldValue(name, !value)}
        />
        <label
          className="relative font-mont text-sm leading-5 text-darkBlue pb-2 items-center flex"
          htmlFor={name}
        >
          {label}
        </label>
      </div>
      {error && touched && (
        <span className="w-full font-lato text-xs text-error">{error}</span>
      )}
    </div>
  )
}
