import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { PoliciesClient } from './policiesClient'
import { PoliciesBroker } from './policiesBroker'
import { Loader } from 'components'

export const Policies = () => {
  const { currentUser } = useContext(AuthContext)

  if (!currentUser) return <Loader />

  return <>{currentUser?.role ? <PoliciesBroker /> : <PoliciesClient />}</>
}
