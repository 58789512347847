export const FormSelect = ({
  formik,
  label,
  name,
  options = [],
  allowEmpty,
  placeholder,
  required,
  inline,
  extraClasses,
  onChange,
}: {
  formik: any
  label: string
  name: string
  options: Array<{ label: string; value: string | number }> | undefined
  allowEmpty: boolean
  required?: boolean
  extraClasses?: string
  inline?: boolean
  placeholder?: string
  onChange?: (val?: any) => void
}) => {
  const { error, touched } = formik.getFieldMeta(name)

  return (
    <div className="flex flex-col">
      <div
        className={`relative w-full ${error ? 'mb-1' : 'mb-6'} ${
          inline ? 'items-center' : ''
        } ${extraClasses ?? ''}`}
      >
        {label && (
          <label
            className={`font-lato text-md leading-5 text-darkBlue block ${
              inline ? 'w-[40%]' : 'pb-2'
            }`}
          >
            {required && <span className="text-brand">*</span>}
            {label}
          </label>
        )}
        <select
          className={`font-lato text-sm text-black px-4 leading-[38px] h-[38px] w-full rounded border ${
            error ? 'border-brand' : 'border-black'
          } bg-transparent outline-none overflow-hidden text-ellipsis disabled:bg-lightGrey disabled:cursor-not-allowed`}
          onChange={
            onChange
              ? onChange
              : (val: React.ChangeEvent<HTMLSelectElement>) =>
                  formik.setFieldValue(name, val.target.value)
          }
        >
          {allowEmpty && <option>{placeholder}</option>}
          {options.map(({ label, value }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {error && touched && (
        <span className="w-full font-lato text-xs text-error mb-4">
          {error}
        </span>
      )}
    </div>
  )
}
