import { useQuery } from '@tanstack/react-query'
import {
  ContentEditor,
  Loader,
  NotificationContainer,
  Wrapper,
  useNotification,
} from 'components'
import { AuthContext } from 'contexts/AuthContext'
import { useContext, useState } from 'react'
import { InitialValues, getPreviousValues } from './helper'
import { locale } from 'locale'
import { SimulatorsStep1 } from './step1'
import { SimulatorsStep2 } from './step2'
import { BodyItem } from 'types/editor'
import { SimulatorsStep3 } from './step3'
import { AppContext } from 'contexts/AppContext'
import { getProduct } from 'services/products'
import { getPolicy } from 'services/policies'
import { Link, useNavigate, useParams } from 'react-router-dom'

export const Simulators = () => {
  const navigate = useNavigate()

  const { slug, id } = useParams() as { slug: string; id: string }

  const { currentUser } = useContext(AuthContext)
  const { products } = useContext(AppContext)
  const [currentStep, setCurrentStep] = useState(1)
  const [nif, setNif] = useState('')
  const [holder, setHolder] = useState('')
  const [eventNo, setEventNo] = useState<number>(-1)
  const [policyNo, setPolicyNo] = useState<number>(-1)
  const [previousValues, setPreviousValues] = useState<InitialValues>({})
  const { notifications, notify } = useNotification()

  const product_id = products.find(product => product.slug === slug)?.id

  const { data, isLoading } = useQuery({
    queryKey: ['getProduct', { product_id }],
    queryFn: () => getProduct({ id: product_id!, params: { simulator: true } }),
    enabled: !!product_id,
  })

  const { data: policy, isLoading: isLoading2 } = useQuery({
    queryKey: ['getPolicy', { id }],
    queryFn: () => getPolicy({ policyNo: +id! }),
    enabled: !!id,
  })

  if (isLoading || isLoading2 || !data) {
    return <Loader />
  }

  if (id && currentStep < 2) {
    setCurrentStep(2)
  }

  if (policy?.Policy?.Policyholder) {
    if (!nif) {
      const nifFilter = policy.Policy.Policyholder.find(
        (items: any) => items.Question === 'POLICYHOLDERID',
      )?.Answer
      setNif(nifFilter)

      setPreviousValues(getPreviousValues(policy.Policy))
    }
  }
  const brokerNo = currentUser?.broker?.broker_no
  const product = data?.product
  const simulatorData = product.questions
  const attachments = product.attachments
  const conditions =
    product?.conditions === '' ? [] : product && JSON.parse(product.conditions)
  const helper =
    product?.helper === '' ? [] : product && JSON.parse(product.helper)

  if (!currentUser) {
    navigate('/')
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue">
          {locale.simulator.singular}
        </h2>
        <h1 className="pb-10 text-xl lg:text-xxxl font-medium text-brand">
          {product?.title}
        </h1>
        <div className="flex flex-col gap-y-8 justify-center items-start divide-y-2 divide-cloudyBlue md:grid md:grid-cols-2 md:grid-rows-none md:divide-y-0 md:divide-x-2">
          <div className="md:pr-8">
            {currentStep === 1 && (
              <SimulatorsStep1
                setCurrentStep={setCurrentStep}
                setNif={setNif}
                brokerNo={brokerNo!}
                setHolder={setHolder}
              />
            )}
            {currentStep === 2 && (
              <SimulatorsStep2
                nif={nif}
                holder={holder}
                simulatorData={simulatorData}
                userData={currentUser!}
                previousValues={previousValues ? previousValues : undefined}
                conditions={conditions}
                setPolicyNo={setPolicyNo}
                setEventNo={setEventNo}
                setCurrentStep={setCurrentStep}
              />
            )}
            {currentStep === 3 && (
              <SimulatorsStep3 policyNo={policyNo} eventNo={eventNo} />
            )}
          </div>
          <div className="md:pl-8">
            <h3 className="font-lato font-medium text-base text-darkBlue uppercase pb-6">
              assistente de preenchimento
            </h3>
            {currentStep === 1 && (
              <p className="font-mont font-normal text-sm">
                Por favor insira o número de contribuinte do cliente para
                verificação prévia
              </p>
            )}
            {currentStep === 2 &&
              helper &&
              helper.map((item: BodyItem, index: number) => (
                <ContentEditor
                  key={`helper_editor_${index}`}
                  item={item}
                  index={index}
                  attachments={attachments}
                  simulator
                />
              ))}
            {currentStep === 3 && (
              <div className="flex flex-col gap-y-6 text-sm">
                <p>
                  Se desejar fazer o pedido de contratação de seguro desta
                  simulação, deve enviar a proposta contida no documento
                  cotação, assinada pelo Tomador.
                </p>
                <p>
                  Para tal deve ir ao menu
                  <Link className="text-brand px-2" to="/quotations">
                    Consulta
                  </Link>
                  e anexar a cotação assinada (assinatura é pedida na pagina 4
                  da cotação) pelo tomador e enviar para a Innovarisk.
                </p>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
