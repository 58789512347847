import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'
import { TrainingParams } from 'types/training'

export const getTrainings = async ({ params }: { params: TrainingParams }) => {
  try {
    const { data } = await axiosClient.get('/training', { params })
    return data
  } catch (error) {
    return handleServerError(error)
  }
}

type CreatePresenceDTO = {
  certificate_url?: string
  training_id: number
  user_id: number
}

export const insertPresence = async (insertDto: CreatePresenceDTO) => {
  try {
    const { data } = await axiosClient.post(`/training/presence`, insertDto)
    return data
  } catch (error) {
    throw error
  }
}

export const getCategories = async () => {
  try {
    const { data } = await axiosClient.get('/categories')
    return data
  } catch (error) {
    return handleServerError(error)
  }
}
