import { Link } from 'react-router-dom'

type TransparentButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string
  to?: string
  action?: () => void
}

const TransparentButton = ({
  label,
  to,
  type = 'button',
  disabled,
  action,
  className = '',
}: TransparentButtonProps) => {
  const btnClasses = `${className} inline-block appearance-none bg-transparent border border-solid border-gray text-black text-inherit uppercase cursor-pointer py-[0.1rem] px-2 font-roboto`

  if (to) {
    return (
      <Link to={to} className={btnClasses}>
        {label}
      </Link>
    )
  }
  return (
    <button
      type={type}
      className={`${btnClasses} disabled:opacity-40`}
      onClick={action ? action : () => {}}
      disabled={disabled}
    >
      {label}
    </button>
  )
}

export default TransparentButton
