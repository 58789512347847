export const Upload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <path id="a" d="M4 13h6V7h4L7 0 0 7h4v6zm-4 2h14v2H0v-2z" />
    </defs>
    <g fill="none" transform="translate(5 3)">
      <mask id="b" fill="#fff">
        <use to="#a" />
      </mask>
      <g fill="#E86137" mask="url(#b)">
        <path d="M-18-16h50v50h-50v-50z" />
      </g>
    </g>
  </svg>
)
