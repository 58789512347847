export const MenuProcesses = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
    <path
      fill='currentcolor'
      d='M16.53,29.9h-2.1l-.71-1.64a2.24,2.24,0,0,0-1.38-1.33V20a2.7,2.7,0,1,0-1.06,0v7a2.27,2.27,0,0,0-1.4,1.33l-.7,1.64H7.08V34H5.5v2H18.11V34H16.53Z'
    />
    <path
      fill='currentcolor'
      d='M25.46,17V9l1.68-.41.94-3.66L25.25,2.14l-3.78.91-.29,1.12h-9L7.92,0,7.39.51C5.54,2.3,5.05,8.4,8.19,14.06a4.85,4.85,0,1,1,6.7,7,19.9,19.9,0,0,0,9.94,2.76c2.31,0,4.2-.51,5.14-1.43l.53-.51ZM13.59,5.49h7.25l-.32,1.22,2.84,2.75.73-.18v6.38Z'
    />
  </svg>
);
