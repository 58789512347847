import * as Yup from 'yup'
import {
  PolicyAnswer,
  PolicyAnswers,
  Question,
  Questions,
} from 'types/simulators'
import { locale } from 'locale'
import { FormikErrors } from 'formik'

export type InitialValues<T = Record<string, string | boolean>> = T

export const getPreviousValues = (answers: PolicyAnswers) => {
  const initialValues: InitialValues = {}

  answers.Policyholder.map(prop => {
    initialValues[prop.InputId] = prop.Answer
    return false
  })

  if (answers.Events[0].Transaction.PolicyAnswers) {
    answers.Events[0].Transaction.PolicyAnswers.map(prop => {
      initialValues[prop.InputId] = prop.Answer
      return false
    })
  }

  if (answers.Events[0].Transaction.PolicyRisks[0].PolicyAnswers) {
    answers.Events[0].Transaction.PolicyRisks[0].PolicyAnswers.map(prop => {
      initialValues[prop.InputId] = prop.Answer
      return false
    })
  }

  if (answers.Events[0].Transaction.PolicyRisks[0].PolicyCovers) {
    if (answers.Events[0].Transaction.PolicyRisks[0].PolicyCovers) {
      answers.Events[0].Transaction.PolicyRisks[0].PolicyCovers.map(cover => {
        if (cover.PolicyAnswers) {
          cover.PolicyAnswers.map((prop: any) => {
            initialValues[prop.InputId] = prop.Answer
            return false
          })
        }

        if (cover.PolicyItems) {
          cover.PolicyItems.map(item => {
            item.PolicyAnswers.map(prop => {
              initialValues[prop.InputId] = prop.Answer
              return false
            })
            return false
          })
        }
        return false
      })
    }
  }

  initialValues['acceptance'] = false

  return initialValues
}

const createYupSchema = (
  schema: Record<string, Yup.Schema<any>>,
  config: Question,
  visibility: [],
) => {
  const { InputId, Attribute, Validation = [] } = config
  let validator: any
  switch (Attribute) {
    case 'Date':
      validator = Yup['mixed']()
      break
    case 'Number':
      validator = Yup['number']()
      break
    case 'Checkbox':
      validator = Yup['boolean']()
      break
    case 'List':
    default:
    case 'Alpha':
      validator = Yup['string']()
  }

  Validation.forEach((validation: any) => {
    let type, params
    switch (validation) {
      case 'IsPostcode':
        type = 'matches'
        params = [/^[0-9]{4}(?:-[0-9]{3})?$/, locale.validation.zip]
        validator = validator[type](...params)
        break
      case 'True':
        type = 'oneOf'
        params = [[true], locale.validation.required]
        validator = validator[type](...params)
        break
      case '':
      case 'IsMandatory':
        type = 'required'
        params = [locale.validation.required]
        validator = validator[type](...params)
        break
      case 'IsInteger':
        type = 'integer'
        params = [locale.validation.integer]
        validator = validator.nullable()[type](...params)
        break
      case 'IsPositive':
        type = 'positive'
        params = [locale.validation.positive]
        validator = validator.nullable()[type](...params)
        break
      case 'MaxLength(4)':
        type = 'max'
        params = [9999, locale.validation.maxlength4]
        validator = validator.nullable()[type](...params)
        break
      case 'MaxLength(12)':
        type = 'max'
        params = [999999999999, locale.validation.maxlength12]
        validator = validator.nullable()[type](...params)
        break
      case 'MaxLength(60)':
        type = 'max'
        params = [60, locale.validation.max60]
        validator = validator.nullable()[type](...params)
        break
      case 'MaxLength(100)':
        type = 'max'
        params = [100, locale.validation.max100]
        validator = validator.nullable()[type](...params)
        break
      default:
        break
    }
  })

  if (visibility && visibility.length > 0) {
    validator = Yup.mixed()
    visibility.forEach((vis: any) => {
      if (vis.InputId === InputId) {
        vis.Rules[0].Expressions.forEach((expression: any) => {
          let params = [locale.validation.required]

          validator = validator.when([expression.InputId], {
            is: expression.Value,
            then: Yup.string().required(...params),
          })
        })
      }
    })
  }

  schema[InputId] = validator
  return schema
}

export const getValidations = (
  simulatorData: Questions | undefined,
  holder: PolicyAnswer[] | null,
  nif: string,
) => {
  const rules = {}
  const initialValues: InitialValues = {}
  const visibility = simulatorData?.Visibility || []

  simulatorData?.Policyholder.map(prop => {
    if (prop.Validation) {
      createYupSchema(rules, prop, visibility)
    }

    return false
  })

  if (holder) {
    holder.map(prop => {
      initialValues[prop.InputId] = prop.Answer

      if (prop.InputId === 'POLICYHOLDERID') {
        initialValues[prop.InputId] = nif
      }

      if (prop.InputId === 'COUNTRY') {
        initialValues[prop.InputId] = 'PRT'
      }

      return false
    })
  } else {
    initialValues['POLICYHOLDERID'] = nif
    initialValues['COUNTRY'] = 'PRT'
  }

  simulatorData?.Questions.map(question => {
    if (question.Attribute === 'List' && !question.Validation) {
      question.Validation = ['IsMandatory']
    }
    if (question.Validation) {
      createYupSchema(rules, question, visibility)
    }
    initialValues[question.InputId] = ''

    return false
  })

  if (simulatorData?.Risks) {
    simulatorData.Risks.map(risk => {
      risk.Questions.map(question => {
        if (question.Attribute === 'List' && !question.Validation) {
          question.Validation = ['IsMandatory']
        }
        if (question.Validation) {
          createYupSchema(rules, question, visibility)
        }
        initialValues[question.InputId] = ''
        return false
      })

      risk.Covers.map(cover => {
        cover.Items.map(item => {
          item.Questions.map(question => {
            if (question.Attribute === 'List' && !question.Validation) {
              question.Validation = ['IsMandatory']
            }
            if (question.Validation) {
              createYupSchema(rules, question, visibility)
            }
            initialValues[question.InputId] = ''
            return false
          })
          return false
        })
        cover.Questions.map(question => {
          if (question.Attribute === 'List' && !question.Validation) {
            question.Validation = ['IsMandatory']
          }
          if (question.Validation) {
            createYupSchema(rules, question, visibility)
          }
          initialValues[question.InputId] = ''
          return false
        })
        return false
      })
      return false
    })
  }

  createYupSchema(
    rules,
    {
      InputId: 'acceptance',
      Attribute: 'Checkbox',
      Validation: ['True'],
    },
    visibility,
  )
  initialValues['acceptance'] = false

  const validationSchema = Yup.object().shape(rules)
  return { validationSchema, initialValues }
}

export const getAnswers = (
  simulatorData: Questions | undefined,
  broker: number | undefined,
  answers: Record<string, string | boolean>,
) => {
  const obj: any = {}
  obj.Policy = {}
  obj.Policy.BrokerNo = broker
  obj.Policy.Currency = 'EUR'
  obj.Policy.Product = simulatorData?.ProductCode
  obj.Policy.BrokerContact = {}
  obj.Policy.BrokerContact.ContactNo = broker

  const Policyholder: any = []

  simulatorData?.Policyholder.map(prop => {
    Policyholder.push({
      Question: prop.QuestionCode,
      Answer: answers[prop.InputId],
      InputId: prop.InputId,
    })
    return false
  })
  obj.Policy.Policyholder = Policyholder

  const PolicyRisks: any = []

  simulatorData?.Risks.map(risk => {
    const PolicyAnswers: any = []
    risk.Questions.forEach(question => {
      PolicyAnswers.push({
        Question: question.QuestionCode,
        Answer: answers[question.InputId],
        InputId: question.InputId,
      })
    })

    const PolicyCovers: any = []
    risk.Covers.map(cover => {
      const PolicyAnswers: any = []
      cover.Questions.forEach(question => {
        PolicyAnswers.push({
          Question: question.QuestionCode,
          Answer: answers[question.InputId],
          InputId: question.InputId,
        })
      })
      const PolicyItems: any = []
      cover.Items.forEach(item => {
        const PolicyAnswers: any = []
        item.Questions.forEach(question => {
          PolicyAnswers.push({
            Question: question.QuestionCode,
            Answer: answers[question.InputId],
            InputId: question.InputId,
          })
        })
        PolicyItems.push({
          ItemCode: item.ItemCode,
          PolicyAnswers: PolicyAnswers,
        })
      })

      PolicyCovers.push({
        CoverCode: cover.CoverCode,
        PolicyAnswers: PolicyAnswers,
        PolicyItems: PolicyItems,
      })

      return true
    })

    PolicyRisks.push({
      RiskCode: risk.RiskCode,
      PolicyAnswers: PolicyAnswers,
      PolicyCovers: PolicyCovers,
    })

    return false
  })

  const generalQuestions: any = []
  simulatorData?.Questions.map(question => {
    generalQuestions.push({
      Question: question.QuestionCode,
      Answer: answers[question.InputId],
      InputId: question.InputId,
    })
    return false
  })

  const Events = [
    {
      Event: 'QT ',
      Transaction: {
        PolicyAnswers: generalQuestions,
        PolicyRisks: PolicyRisks,
      },
    },
  ]
  obj.Policy.Events = Events

  return obj
}

export const isEmpty = (
  obj: FormikErrors<Record<string, string | boolean>>,
) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export const getVisibility = (
  fieldInput: string,
  visibilityRules: any,
  values: any,
) => {
  const fieldRules = visibilityRules.filter(
    (rule: any) => rule.InputId === fieldInput,
  )

  let shoudBeVisible
  if (fieldRules && fieldRules[0]) {
    shoudBeVisible = fieldRules[0].Rules[0].Expressions.every(
      (expression: any) => {
        switch (expression.Operator) {
          case '<>':
            return (
              values[expression.InputId] !== expression.Value &&
              values[expression.InputId] !== ''
            )
          case '>':
            return (
              values[expression.InputId] * 1 > expression.Value * 1 &&
              values[expression.InputId] !== ''
            )
          case '>=':
            return (
              values[expression.InputId] * 1 >= expression.Value * 1 &&
              values[expression.InputId] !== ''
            )
          case '<':
            return (
              values[expression.InputId] * 1 < expression.Value * 1 &&
              values[expression.InputId] !== ''
            )
          case '<=':
            return (
              values[expression.InputId] * 1 <= expression.Value * 1 &&
              values[expression.InputId] !== ''
            )
          default:
          case '=':
            return (
              values[expression.InputId] === expression.Value &&
              values[expression.InputId] !== ''
            )
        }
      },
    )
  } else {
    shoudBeVisible = true
  }

  return !shoudBeVisible
}
