import { useCallback, useEffect, useState, memo } from 'react'
import { locale } from '../../locale'
import { NotificationType } from '../../types'

export const useNotification = () => {
  const [notifications, setNotifications] = useState<NotificationType[]>([])

  const notify = (
    message: string = locale.genericError || 'Pedido enviado com sucesso',
    type = 'error',
    autoClose = true,
  ) => {
    setNotifications((prev: NotificationType[]) => [
      ...prev,
      {
        message,
        type,
        autoClose,
      },
    ])

    setTimeout(() => {
      setNotifications(prev => {
        prev.shift()

        return prev
      })
    }, 5000)
  }

  return { notifications, notify }
}

export const Notification = ({
  message,
  type,
  autoClose,
}: NotificationType) => {
  const [show, setShow] = useState(true)

  const closeNotification = useCallback(() => {
    setShow(false)
  }, [])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (autoClose) {
      timer = setTimeout(closeNotification, 5000)
    }

    return () => clearTimeout(timer)
  }, [autoClose, closeNotification])

  const bgColor = `${
    type === 'error'
      ? 'bg-error'
      : type === 'warning'
        ? 'bg-yellow'
        : 'bg-success'
  }`
  const buttonClasses = `${
    type === 'warning' ? 'border-yellow text-black' : 'border-black text-white'
  }`
  const textClasses = `${type === 'warning' ? 'text-black' : 'text-white'}`

  return show ? (
    <div className="mb-4">
      <div
        className={`rounded flex md:flex-nowrap items-center shadow-md p-2 w-full ${bgColor}`}
      >
        <p
          className={`text-sm leading-5 w-[80%] pr-4 bg-transparent ${textClasses}`}
        >
          {message}
        </p>
        <button
          className={`border leading-9 inline-block font-mont text-sm uppercase text-center rounded-md outline-none cursor-pointer font-medium px-2 ${buttonClasses}`}
          onClick={closeNotification}
        >
          {locale.close}
        </button>
      </div>
    </div>
  ) : null
}

export const NotificationContainer = memo(function NotificationContainer({
  notifications,
}: {
  notifications: NotificationType[]
}) {
  return (
    <div className="fixed top-[170px] left-1/2 transform -translate-x-1/2 z-[400]">
      {notifications.map((notification: any, i: number) => (
        <Notification key={i} {...notification} />
      ))}
    </div>
  )
})
