import { LogoSideBar } from 'components/Icons'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { SideBarType } from 'types/navbar'
import { SideSubMenu } from './subMenu'
import { AuthContext } from 'contexts/AuthContext'

type NavSideBarType = {
  barColor?: string
  sideBar: SideBarType | null
}

const NavSideBar = ({ barColor, sideBar }: NavSideBarType) => {
  const [subMenuVisible, setSubMenuVisible] = useState([0, 0, 0, 0, 0, 0, 0])
  const { currentUser } = useContext(AuthContext);
  const brokerName = currentUser && currentUser.broker ? currentUser.broker.name : "Empresa";

  const handleMenuClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    i: number,
    hasSubMenu: boolean | undefined,
  ) => {
    if (!hasSubMenu) return false
    e.preventDefault()
    if (subMenuVisible[i] === 1) {
      const arr = [0, 0, 0, 0, 0, 0, 0]
      setSubMenuVisible(arr)
    } else {
      const arr = [0, 0, 0, 0, 0, 0, 0]
      arr[i] = 1
      setSubMenuVisible(arr)
    }
  }

  return (
    <div className="w-32 h-[calc(100vh-112px)] absolute bg-gradient-to-t from-menuRedZero from-0% via-menuRedOne via-48% via-menuRedTwo via-56% via-menuRedThree via-69% via-menuRedFour via-85% to-menuRedFive">
      <div className="h-fit min-h-[102px]">
        <div className="h-[42px] pt-[10px] flex justify-center">
          <LogoSideBar />
        </div>
        <div className="h-fit flex flex-col justify-center bg-white pt-4 pb-2">
          <h2 className="font-roboto font-bold text-md text-center">{brokerName}</h2>
          <h3 className="font-roboto text-xs text-center">Broker</h3>
        </div>
      </div>
      <div className="h-fit flex flex-col pt-[18px] gap-y-2">
        {
          sideBar?.map(
            ({ icon: Icon, label, url, hasSubMenu, submenu }, i) => {
              return (
                <div key={label} className={`w-full h-[75px] flex align-center justify-center ${subMenuVisible[i] ? "bg-mainMenuRed" : ""}`}>
                  <Link
                    key={`menu_item${label}_${i}`}
                    to={url}
                    onClick={e => handleMenuClick(e, i, hasSubMenu)}
                    className="sticky top-0 z-[9999] flex items-center lg:relative lg:grid lg:py-0 lg:justify-items-center text-white text-sm uppercase font-roboto">
                    <span
                      className={`w-5 h-5`}
                    >
                      {Icon ? <Icon /> : null}
                    </span>
                    {label}
                  </Link>
                  {hasSubMenu && submenu && (
                  <SideSubMenu
                    {...submenu}
                    subMenuVisible={subMenuVisible}
                    index={i}
                    onClick={() => setSubMenuVisible([0, 0, 0, 0, 0, 0, 0])}
                  />
                )}
                </div>)
            })
        }
      </div>
    </div>
  )
}

export default NavSideBar;