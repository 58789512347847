export const Modal = ({
  onClose,
  children,
  classes,
}: {
  onClose: () => void;
  children: React.ReactNode;
  classes?: string;
}) => {
  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const insideModal = (e.target as HTMLElement).closest('#modal');

    if (!insideModal) {
      onClose();
    }
  };

  return (
    <div
      className='fixed top-0 right-0 bottom-0 left-0 bg-darkBlue/30 flex items-center justify-center z-[2000]'
      onClick={(e) => onOverlayClick(e)}
    >
      <div
        className={`bg-white rounded py-6 px-4 shadow-md w-4/5 md:w-1/2 max-h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto ${classes}`}
        id='modal'
      >
        <div>{children}</div>
      </div>
      <button onClick={() => onClose()}></button>
    </div>
  );
};
