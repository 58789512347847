import { Search } from 'components/Icons'
import { FilterType } from '.'

export const SearchFilter = ({
  filter,
  formik,
  extraClasses,
}: {
  filter: FilterType
  formik: any
  extraClasses?: string
}) => {
  return (
    <div key={filter.id} className={`relative ${extraClasses}`}>
      <label
        htmlFor={filter.id}
        className="absolute -top-2 left-4 inline-block bg-lightGrey px-1 text-xs font-medium text-gray-900"
      >
        {filter.name}
      </label>
      <div
        id={filter.id}
        className={`group inline-flex justify-between items-center border border-gray-400 rounded-xl px-4 py-2 w-full text-gray-900 text-base font-medium font-lato`}
      >
        <input
          type="text"
          name={filter.id}
          id={filter.id}
          className={`bg-transparent w-full focus:shadow-none focus:ring-0 focus:outline-none ${
            formik && formik.errors[filter.id]
              ? 'border-error'
              : 'border-gray-200'
          }`}
          placeholder={`Search...`}
          value={formik && formik.getFieldProps(filter.id).value}
          onChange={e =>
            formik && formik.setFieldValue(filter.id, e.currentTarget.value)
          }
        />
        <p className="absolute text-xs text-error -ml-4 pt-16">
          {formik && formik.errors[filter.id]}
        </p>
        <span className="ml-2">
          <Search className="w-3 text-gray-400" />
        </span>
      </div>
    </div>
  )
}
