import { useContext } from 'react'

import { AuthContext } from '../../contexts/AuthContext'
import { InvoicesBroker } from './invoicesBroker'
import { InvoicesClient } from './invoicesClient'
import { Loader } from 'components'

export const Invoices = () => {
  const { currentUser } = useContext(AuthContext)

  if (!currentUser) return <Loader />
  return <>{currentUser?.role ? <InvoicesBroker /> : <InvoicesClient />}</>
}
