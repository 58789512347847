import { useNotification } from 'components'
import React, { useState, useEffect, useCallback } from 'react'
import { getContent } from 'services/content'
import { getProducts } from 'services/products'
import { getSettings } from 'services/settings'
import { getCategories } from 'services/trainings'

import { AppContextType } from 'types'

export const AppContext = React.createContext<AppContextType>({
  loading: false,
  settings: null,
  categories: [],
  products: [],
  process: null,
  getPageSettings: () => Promise.resolve(),
})

export const AppProvider = ({ children }: { children: any }) => {
  const { notify } = useNotification()

  const getSettingsCallback = useCallback(async () => {
    setLoading(true)
    try {
      const settings = await getSettings()
      setSettings(settings)
    } catch (error) {
      notify()
    } finally {
      setLoading(false)
      setIsLoaded(true)
    }
  }, [])

  const getPageSettings = useCallback(async () => {
    const categories = await getCategories()
    const { products } = await getProducts({
      params: {
        status: 'published',
      },
    })
    const process = await getContent({
      params: {
        type: 'process',
        status: 'published',
      },
    })

    setCategories(categories)
    setProducts(products)
    setProcess(process)
  }, [])

  const [isLoaded, setIsLoaded] = useState(false)
  const [loading, setLoading] = useState<AppContextType['loading']>(false)

  const [settings, setSettings] = useState<AppContextType['settings']>(null)
  const [categories, setCategories] = useState<AppContextType['categories']>([])
  const [products, setProducts] = useState<AppContextType['products']>([])
  const [process, setProcess] = useState<AppContextType['process']>(null)

  useEffect(() => {
    if ((!isLoaded && !settings?.length) || !categories.length) {
      getSettingsCallback()
    }
  }, [])

  return (
    <AppContext.Provider
      value={{
        settings,
        loading,
        categories,
        products,
        process,
        getPageSettings,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
