import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  FilledButton,
  FormInput,
  NotificationContainer,
  useNotification,
} from 'components'

import { createClient } from 'services/clients'
import { useMutation } from '@tanstack/react-query'
/* import { UserRegistrationEmail } from 'emails/invites'
import { sendUserInviteEmail } from 'services/email'
import { render } from '@react-email/components' */

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
  nif: Yup.number().required('Campo obrigatório'),
})

export const RegisterClient = () => {
  const { notifications, notify } = useNotification()

  const { mutateAsync: registerClient, isPending } = useMutation({
    mutationKey: ['registerClient'],
    mutationFn: createClient,
    /*  onSuccess: data => {
      
    },*/
    onError: err => notify(`Erro ao registar cliente, ${err}`, 'error'),
  })

  const formik = useFormik({
    initialValues: {
      nif: '',
      phone: '',
      email: '',
      name: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      await registerClient({ ...values })

      //   gtag('event', 'action', {
      //     event_category: 'click',
      //     event_label: 'Cliente fez registo',
      //   })
    },
  })

  return (
    <div className="w-full flex-1 py-12">
      <div className="flex w-full px-9 justify-center">
        <NotificationContainer notifications={notifications} />
        <div className="flex flex-col w-[560px]">
          <h1 className="font-mont font-medium text-xxxl leading-4 text-brand pb-8">
            Registo
          </h1>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-y-2"
          >
            <p className="font-mont font-normal text-base">
              Por favor insira o seu NIF, email e número de telemóvel
            </p>
            <FormInput
              label="Nome"
              name="name"
              formik={formik}
              type="text"
              placeholder="Nome"
            />
            <FormInput
              label="Email"
              name="email"
              formik={formik}
              type="text"
              placeholder="Email"
            />
            <div className="grid grid-cols-2 gap-6 items-center w-full">
              <FormInput
                label="Telemóvel"
                name="phone"
                formik={formik}
                type="text"
                placeholder="Telemóvel"
              />
              <FormInput
                label="NIF"
                name="nif"
                formik={formik}
                type="text"
                placeholder="NIF"
              />
            </div>
            <div className="flex justify-end">
              <FilledButton
                label="Submeter"
                type="submit"
                disabled={isPending}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
