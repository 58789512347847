import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'YYYY/MM/DD';

export const formatDate = (date: string | undefined) => {
  let dashed: string = '';
  let slashed: string = '';

  if (date === undefined) {
    dashed = '---';
    slashed = '---';
  } else {
    const newDate = moment(date, 'YYYY-MM-DD');
    dashed = newDate.format('YYYY-MM-DD');
    slashed = newDate.format(DEFAULT_DATE_FORMAT);
  }

  return {
    dashed,
    slashed,
  };
};
