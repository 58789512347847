import { useContext } from 'react'
import { AuthContext } from 'contexts/AuthContext'
import {
  FilledButton,
  Loader,
  LoaderInline,
  NotificationContainer,
  Wrapper,
  useNotification,
} from 'components'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getInvoices, getInvoicesDocumentation } from 'services/invoices'
import { formatInvoice } from 'utils/invoices'
import { useParams } from 'react-router-dom'

export const InvoiceBroker = () => {
  const { notifications, notify } = useNotification()
  const { currentUser } = useContext(AuthContext)
  const { policyNo, receiptNo } = useParams()
  const brokerNo = currentUser?.broker?.broker_no!
  const { isLoading, data: invoiceDetail } = useQuery({
    queryKey: ['getInvoiceDetail', { policyNo, receiptNo, brokerNo }],
    retry: false,
    queryFn: () =>
      getInvoices({
        params: {
          receiptNo,
          brokerNo,
        },
      }),
  })

  const { mutateAsync: handleGetDocs, isPending } = useMutation({
    mutationKey: ['getInvoicesDocumentation'],
    mutationFn: async () => getInvoicesDocumentation({ receiptNo }),
    onError: () => notify('Ocorreu um erro ao obter o recibo', 'error'),
    onSuccess: data => {
      const blob = new Blob([data], { type: 'application/pdf' })
      window.open(URL.createObjectURL(blob))
    },
  })

  if (isLoading) {
    return <Loader />
  }

  const renderInvoiceData = () => {
    const invoice = invoiceDetail[0]
    const item = formatInvoice(invoice)

    return (
      <>
        {item.map((e, index) => (
          <tr key={`row-${index}`} className="cursor-default h-8">
            <td className="text-grey font-lato text-sm pl-4">{e?.label}</td>
            <td className="text-end text-black font-lato text-sm font-semibold pr-4 border-t border-lightGrey">
              {e?.value}
            </td>
          </tr>
        ))}
      </>
    )
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue pb-10">
          Recibo
        </h2>
        <div className="grid md:grid-cols-2 gap-8 md:gap-0 md:divide-x-2 divide-cloudyBlue justify-center items-start">
          <div className="pr-8">
            <table className="border-collapse w-full border-none">
              <tbody>
                {invoiceDetail.length > 0 ? (
                  renderInvoiceData()
                ) : (
                  <tr
                    key={`row_empty`}
                    className="cursor-default h-8 hover:bg-lightGrey"
                  >
                    <td className="text-grey font-lato text-sm pl-4 uppercase tracking-widest leading-10">
                      Não existem dados
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {invoiceDetail.length && (
            <div className="pl-8">
              <div className="mb-10 bg-lightGrey p-6">
                <h4 className="text-lato text-sm text-darkBlue pb-4 font-medium uppercase">
                  Recibo
                </h4>
                {isPending ? (
                  <LoaderInline />
                ) : (
                  <FilledButton label="Download" action={handleGetDocs} />
                )}
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  )
}
