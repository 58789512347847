import { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { locale } from 'locale'
import { AuthContext } from 'contexts/AuthContext'

import {
  useNotification,
  Loader,
  Table,
  Filter,
  FilterType,
  Wrapper,
  NotificationContainer,
  FilledButton,
} from 'components'
import { formatCurrency } from 'utils/currency'
import { formatDate } from 'utils/dates'
import { AppContext } from 'contexts/AppContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { downloadInvoicesExcel, getInvoices } from 'services/invoices'
import { getNewQueryVars } from 'utils/queries'
import { InvoiceParams } from 'types/invoices'
import { useNavigate } from 'react-router-dom'

const validationSchema = Yup.object().shape({
  nif: Yup.string().matches(/^[0-9]{9}$/, locale.validation.digits9),
  policyno: Yup.string().matches(/^[0-9]+$/, locale.validation.onlyNumbers),
  valueFrom: Yup.string().matches(/^[0-9]+$/, locale.validation.onlyNumbers),
  receiptNo: Yup.string().matches(/^[0-9]+$/, locale.validation.onlyNumbers),
})

export const InvoicesBroker = () => {
  const navigate = useNavigate()

  const { notifications, notify } = useNotification()
  const { currentUser } = useContext(AuthContext)
  const { products } = useContext(AppContext)

  const brokerNo = currentUser?.broker?.broker_no!
  const invoicedFrom = moment().subtract(1, 'year').format('YYYY-MM-DD')

  const initialQueryVars = {
    brokerNo,
    invoicedFrom,
  }

  const [queryVars, setQueryVars] = useState<InvoiceParams>(initialQueryVars)

  const filters: FilterType[] = [
    {
      id: 'productCode',
      name: 'Produto',
      type: 'option',
      options: products.map(product => ({
        name: product.title,
        id: product.integration_code,
      })),
    },
    { id: 'nif', name: 'NIF', type: 'search', options: [] },
    { id: 'policyNo', name: 'Nº Apólice', type: 'search', options: [] },
    {
      id: 'stage',
      name: 'Estado',
      type: 'option',
      options: [
        { name: 'Pago', id: 'P' },
        { name: 'Pendente', id: 'O' },
      ],
    },
    {
      id: 'invoicedFrom',
      name: locale.invoicedFrom,
      type: 'date',
      options: [],
    },
    {
      id: 'invoicedTo',
      name: locale.invoicedTo,
      type: 'date',
      options: [],
    },
    { id: 'valueFrom', name: locale.valueFrom, type: 'search', options: [] },
    { id: 'receiptNo', name: locale.receiptNo, type: 'search', options: [] },
  ]

  const formik = useFormik({
    initialValues: {
      productCode: 0,
      nif: '',
      invoicedFrom: null,
      invoicedTo: null,
      stage: '',
      valueFrom: '',
      receiptNo: '',
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const newQueryVars = getNewQueryVars({
        ...values,
        brokerNo,
      }) as InvoiceParams

      setQueryVars(newQueryVars)
    },
  })

  const { isLoading, data: invoiceData } = useQuery({
    retry: false,
    queryKey: ['listReceipts', queryVars],
    queryFn: () => getInvoices({ params: queryVars }),
  })

  const { mutateAsync: handleExcelDownload, isPending } = useMutation({
    mutationKey: ['downloadInvoicesExcel'],
    mutationFn: async () => await downloadInvoicesExcel({ params: queryVars }),
    onError: () => notify('Ocorreu um erro ao obter o Excel', 'error'),
    onSuccess: data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      window.open(URL.createObjectURL(blob))
    },
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue">Recibos</h2>
        <h1 className="pb-10 text-xxxl font-medium text-brand">
          Consulte os seus recibos
        </h1>
        <div className="lg:pt-8">
          {products && products.length > 0 && (
            <Filter
              isLoading={isPending}
              filters={filters}
              formik={formik}
              actionLabel="Download Excel"
              action={handleExcelDownload}
            />
          )}
          <Table
            width="lg:w-full"
            columns={[
              {
                label: 'Recibo nº',
                property: 'Recibo nº',
                sortable: true,
                format: item => item['Recibo nº'],
              },
              {
                label: 'Apólice',
                property: 'Apólice',
                sortable: true,
                format: item => item['Apólice'],
              },
              {
                label: 'Tomador',
                property: 'Tomador',
                sortable: true,
                format: item => item['Tomador'],
              },
              {
                label: 'Produto',
                property: 'Produto',
                sortable: true,
                format: item => item['Produto'],
              },
              {
                label: 'Data de Emissão',
                property: 'Data de Emissão',
                sortable: true,
                format: item => formatDate(item['Data de Emissão']).dashed,
              },
              {
                label: 'Data Limite',
                property: 'Data de Cobrança',
                sortable: true,
                format: item => formatDate(item['Data de Cobrança']).dashed,
              },
              {
                label: 'Período',
                property: 'Periodo',
                sortable: true,
                format: item => item['Periodo'],
              },
              {
                label: 'Prémio Total',
                property: 'Prémio Total',
                sortable: true,
                format: item => formatCurrency(item['Prémio Total']),
              },
              {
                label: 'Com. Bruta',
                property: 'Comissão',
                sortable: true,
                format: item => formatCurrency(item['Comissão']),
              },
              {
                label: 'Pago por',
                property: 'Pago por',
                sortable: true,
                format: item => item['Pago por'],
              },
              {
                label: locale.actions,
                property: 'Accoes',
                sortable: false,
                format: item => (
                  <FilledButton
                    label="Detalhes"
                    action={() =>
                      navigate(
                        `/invoices/${item['Apólice']}/${item['Recibo nº']}`,
                      )
                    }
                  />
                ),
              },
            ]}
            data={invoiceData}
          />
        </div>
      </Wrapper>
    </div>
  )
}
