import TransparentButton from "components/Buttons/transparent";
import { Logo2 } from "components/Icons/logo"
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";
// import { NotificationContainer, useNotification } from "components/Notification";
import { Link } from "react-router-dom";
import { TopBarType } from "types/navbar";

type NavTopBarType = {
    barColor?: string
    topBar: TopBarType
}

export const NavNewTopBar = ({ barColor, topBar }: NavTopBarType) => {
    // const { notifications } = useNotification()
    const { currentUser } = useContext(AuthContext)
    const userName = currentUser && currentUser.forename ? currentUser.forename : "John Doe";

    return (
        <div className="w-full h-28 flex flex-col">
            <Link className="ml-28 mt-4 mb-4 h-16 w-60" to="/">
                <Logo2 />
            </Link>
            <div className="absolute right-[270px] bottom-2 bg-MenuRed flex flex-col w-fit">
                {/* <NotificationContainer notifications={notifications} /> */}
                {/* <TransparentButton
                className="rounded-t-md text-menuRedOne w-44 bg-menuGrey"
                action={undefined}
                label={userName}/> */}
                <TransparentButton
                className="rounded-t-md text-menuRedOne w-20 border-2 border-b-0"
                action={topBar ? topBar.onLogout : undefined}
                label="Sair"/>
            </div>
            <div className="bg-darkRed h-2 w-full mt-2"></div>
        </div>
    )
}

export default NavNewTopBar;