import { useFormik } from 'formik'
import * as Yup from 'yup'

import { locale } from 'locale'
import { FilledButton, FormInput, LoaderInline } from 'components'
import { useMutation } from '@tanstack/react-query'
import { updateClient } from 'services/clients'
import { Client } from 'types/clients'

const validationPersonal = Yup.object().shape({
  name: Yup.string().required(locale.required),
  email: Yup.string().email().required(locale.required),
  phone: Yup.string().required(locale.required),
})

export type ClientSubmitType = {
  email: string
  phone: string
  name: string
  id: number
  nif: string
}

export const EditProfileClient = ({
  initialValues,
  notify,
}: {
  initialValues: ClientSubmitType
  notify: (value?: string, type?: string) => void
}) => {
  const { mutateAsync: handleClientUpdate, isPending: updateClientPending } =
    useMutation({
      mutationKey: ['updateClientProfile'],
      mutationFn: async ({
        id,
        data,
      }: {
        id: number
        data: Partial<Client>
      }) => {
        await updateClient({
          data,
          id,
        })
      },
      onError: error => notify(error.message),
      onSuccess: () => notify('Perfil atualizado com sucesso', 'success'),
    })

  const formik = useFormik({
    initialValues,
    validationSchema: validationPersonal,
    onSubmit: ({ id, name, email, phone }: ClientSubmitType) => {
      handleClientUpdate({ id, data: { name, email, phone } })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="py-8 text-darkBlue text-xl">{locale.personalData}</h2>

      <p className="font-lato text-sm flex justify-between pb-3">
        <span>NIF:</span> <span>{initialValues.nif}</span>
      </p>

      <FormInput
        type="text"
        name="name"
        label={locale.forename}
        formik={formik}
      />
      <FormInput
        type="email"
        name="email"
        label={locale.email}
        formik={formik}
      />
      <FormInput
        type="text"
        name="phone"
        label={locale.phone}
        formik={formik}
      />

      {updateClientPending ? (
        <LoaderInline />
      ) : (
        <FilledButton type="submit" label={locale.submit} />
      )}
    </form>
  )
}
