import { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { locale } from 'locale'
import { formatCurrency } from 'utils/currency'
import { AuthContext } from 'contexts/AuthContext'
import {
  FilledButton,
  Filter,
  FilterType,
  Loader,
  NotificationContainer,
  Table,
  Wrapper,
  useNotification,
} from 'components'
import { AppContext } from 'contexts/AppContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { downloadPoliciesExcel, getPolicies } from 'services/policies'
import { PoliciesParams } from 'types/policies'
import { getNewQueryVars } from 'utils/queries'
import { Link } from 'react-router-dom'
import { Client } from 'types/clients'

const validationSchema = Yup.object().shape({
  policyNo: Yup.string().matches(/^[0-9]+$/, locale.validation.onlyNumbers),
  nif: Yup.string().matches(/^[0-9]{9}$/, locale.validation.digits9),
})

export const PoliciesClient = () => {
  const { notifications, notify } = useNotification()

  const { currentUser } = useContext(AuthContext)
  const { products } = useContext(AppContext)
  const user = currentUser as unknown as Client
  const nif = user?.nif

  const initialQueryVars = {
    nif,
  }

  const [queryVars, setQueryVars] = useState<PoliciesParams>(initialQueryVars)

  const filters: FilterType[] = [
    {
      id: 'periodFrom',
      name: locale.periodFrom,
      type: 'date',
      options: [],
    },
    {
      id: 'productCode',
      name: 'Produto',
      type: 'option',
      options: products.map(product => ({
        name: product.title,
        id: product.integration_code,
      })),
    },
    { id: 'policyNo', name: 'Nº Apólice', type: 'search', options: [] },
    {
      id: 'status',
      name: 'Estado',
      type: 'option',
      options: [
        { name: 'Activo', id: 'ACTIVE' },
        { name: 'Anulado', id: 'CANCEL' },
      ],
    },
  ]

  const formik = useFormik({
    initialValues: {
      productCode: '',
      policyNo: '',
      status: 0,
      periodFrom: null,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values: any) => {
      const newQueryVars = getNewQueryVars({
        ...values,
        nif,
      }) as PoliciesParams

      setQueryVars(newQueryVars)
    },
  })

  const { mutateAsync: handleExcelDownload, isPending: isPedingCsvDownload } =
    useMutation({
      mutationKey: ['downloadPoliciesExcel'],
      mutationFn: async ({ queryVars }: { queryVars: PoliciesParams }) =>
        await downloadPoliciesExcel({ params: { ...queryVars, nif } }),
      onSuccess: response => {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        window.open(URL.createObjectURL(blob))
      },
      onError: error => notify(error.message, 'error'),
    })

  const { data, isLoading } = useQuery({
    queryKey: ['getPolicies', { queryVars, nif }],
    initialData: [],
    queryFn: () => getPolicies({ params: { ...queryVars, nif } }),
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue">
          Apólices
        </h2>
        <h1 className="pb-10 text-xxxl font-medium text-brand">
          Consulte as suas apólices
        </h1>
        <div className="lg:pt-8">
          {products && products.length > 0 && (
            <Filter
              isLoading={isPedingCsvDownload}
              filters={filters}
              formik={formik}
              actionLabel="Download Excel"
              action={() => handleExcelDownload({ queryVars })}
            />
          )}
          <Table
            width=""
            columns={[
              {
                label: 'Nº da Apólice',
                property: 'Nº da Apólice',
                sortable: true,
                format: item => item['Nº da Apólice'],
              },
              {
                label: 'Produto',
                property: 'Produto',
                sortable: true,
                format: item => item['Produto'],
              },
              {
                label: 'Tomador',
                property: 'Tomador',
                sortable: true,
                format: item => item['Tomador'],
              },
              {
                label: 'Prémio Anual',
                property: 'Prémio Anual',
                sortable: true,
                format: item => formatCurrency(item['Prémio Anual']),
              },
              {
                label: 'Data da Renovação',
                property: 'Data da Renovação',
                sortable: true,
                format: item => item['Data da Renovação'],
              },
              {
                label: 'Estado',
                property: 'Estado',
                sortable: true,
                format: item => item['Estado'],
              },
              {
                label: locale.actions,
                property: 'Ações',
                sortable: false,
                format: item => (
                  <div className="grid grid-cols-2 justify-center items-start gap-2">
                    <Link
                      className=" decoration-cloudyBlue hover:decoration-brand"
                      to={`/policies/${item['Nº da Apólice']}`}
                    >
                      <FilledButton className="w-full" label="Detalhes" />
                    </Link>
                    <Link
                      className="w-full"
                      to={`/invoices/${item['Nº da Apólice']}`}
                    >
                      <FilledButton className="w-full" label="Recibos" />
                    </Link>
                  </div>
                ),
              },
            ]}
            data={data}
          />
        </div>
      </Wrapper>
    </div>
  )
}
