import NavTopBar from './topBar'
import NavBottomBar from './bottomBar'
import { BottomBarType, SideBarType, TopBarType } from 'types/navbar'
import { useContext } from 'react'
import { AuthContext } from 'contexts/AuthContext'
import NavSideBar from './sideBar'
import NavNewTopBar from './newTopBar'

type NavBarType = {
  activeItem: string
  topBar: TopBarType
  bottomBar: BottomBarType
  sideBar?: SideBarType
  barColor?: string
}

export const Navbar = ({ topBar, bottomBar, sideBar, barColor }: NavBarType) => {
  const { isSignedIn } = useContext(AuthContext)
  const bottomBarItems = isSignedIn && bottomBar ? bottomBar : null
  const sideBarItems = isSignedIn && sideBar ? sideBar : []
  return (
    <div className="relative">
      <NavNewTopBar topBar={topBar}></NavNewTopBar>
      <NavSideBar barColor={barColor} sideBar={sideBarItems}></NavSideBar>
    </div>
  )
  return (
    <div className="w-full relative">
      {isSignedIn && topBar && (
        <NavTopBar barColor={barColor} topBar={topBar} />
      )}
      {bottomBar &&
        <NavBottomBar barColor={barColor} bottomBar={bottomBarItems}></NavBottomBar>
      }
    </div>
  )
}
