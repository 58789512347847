import { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { locale } from 'locale'

import { formatCurrency } from 'utils/currency'
import { AuthContext } from 'contexts/AuthContext'
import {
  FilledButton,
  Filter,
  FilterType,
  Loader,
  NotificationContainer,
  Table,
  Wrapper,
  useNotification,
} from 'components'
import { useQuery } from '@tanstack/react-query'
import { getPolicies } from 'services/policies'
import { AppContext } from 'contexts/AppContext'
import { getNewQueryVars } from 'utils/queries'
import { PoliciesParams } from 'types/policies'
import { useNavigate } from 'react-router-dom'

const validationSchema = Yup.object().shape({
  policyNo: Yup.string().matches(/^[0-9]+$/, locale.validation.onlyNumbers),
  nif: Yup.string().matches(/^[0-9]{9}$/, locale.validation.digits9),
})

export const PoliciesBroker = () => {
  const navigate = useNavigate()
  const { notifications } = useNotification()
  const { currentUser } = useContext(AuthContext)
  const { products } = useContext(AppContext)
  const [queryVars, setQueryVars] = useState<PoliciesParams>()

  const brokerNo = currentUser?.broker?.broker_no
  const contactNo = currentUser?.contact_no

  const { data, isLoading } = useQuery({
    queryKey: ['getPolicies', { queryVars, brokerNo, contactNo }],
    initialData: [],
    retry: false,
    queryFn: () =>
      getPolicies({ params: { ...queryVars, brokerNo, contactNo } }),
  })

  const filters: FilterType[] = [
    {
      id: 'productCode',
      name: 'Produto',
      type: 'option',
      options: products.map(e => ({
        name: e.title,
        id: e.integration_code,
      })),
    },
    {
      id: 'periodFrom',
      name: locale.periodFrom,
      type: 'date',
      options: [],
    },
    { id: 'nif', name: 'NIF', type: 'search', options: [] },
    { id: 'policyNo', name: 'Nº Apólice', type: 'search', options: [] },
    {
      id: 'status',
      name: 'Estado',
      type: 'option',
      options: [
        { name: 'Activo', id: 'ACTIVE' },
        { name: 'Anulado', id: 'CANCEL' },
      ],
    },
  ]

  const formik = useFormik({
    initialValues: {
      productCode: 0,
      policyNo: '',
      nif: '',
      status: 0,
      periodFrom: null,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values: any) => {
      const newQueryVars = getNewQueryVars(values) as PoliciesParams
      setQueryVars(newQueryVars)
    },
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue">
          Apólices
        </h2>
        <h1 className="pb-10 text-xxxl font-medium text-brand">
          Consulte as suas apólices
        </h1>
        <div className="lg:pt-8">
          <Filter filters={filters} formik={formik} />
          <Table
            columns={[
              {
                label: 'Nº da Apólice',
                property: 'Nº da Apólice',
                sortable: true,
                format: item => item['Nº da Apólice'],
              },
              {
                label: 'Produto',
                property: 'Produto',
                sortable: true,
                format: item => item['Produto'],
              },
              {
                label: 'Tomador',
                property: 'Tomador',
                sortable: true,
                format: item => item['Tomador'],
              },
              {
                label: 'Prémio Anual',
                property: 'Prémio Anual',
                sortable: true,
                format: item => formatCurrency(item['Prémio Anual']),
              },
              {
                label: 'Data da Renovação',
                property: 'Data da Renovação',
                sortable: true,
                format: item => item['Data da Renovação'],
              },
              {
                label: 'Estado',
                property: 'Estado',
                sortable: true,
                format: item => item['Estado'],
              },
              {
                label: locale.actions,
                property: 'Ações',
                sortable: false,
                format: item => {
                  const policyNo = item['Nº da Apólice']
                  return (
                    <div className="flex gap-2">
                      <FilledButton
                        className="min-w-[135px]"
                        label="Gerir Apólice"
                        action={() => navigate(`/policies/${policyNo}`)}
                      />
                      <FilledButton
                        label="Recibos"
                        action={() => navigate(`/invoices/${policyNo}`)}
                      />
                    </div>
                  )
                },
              },
            ]}
            data={data}
          />
        </div>
      </Wrapper>
    </div>
  )
}
