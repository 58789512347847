import { useContext, useState } from 'react'
import { locale } from 'locale'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { formatDate } from 'utils/dates'
import {
  Loader,
  useNotification,
  Filter,
  Table,
  Wrapper,
  NotificationContainer,
  FilterType,
} from 'components'

import { TrainingCategory, type TrainingParams } from 'types/training'
import { useTrainings } from 'hooks/useTrainings'
import { AppContext } from 'contexts/AppContext'
import { Link } from 'react-router-dom'

const validationSchema = Yup.object().shape({
  categoryId: Yup.number().required(),
})

export const PastTrainings = () => {
  const { notifications } = useNotification()
  const { categories } = useContext(AppContext)
  const today = moment().format('YYYY-MM-DD')
  const currentHour = moment().subtract(2, 'hour').format('HH:mm')

  const [whereClause, setWhereClause] = useState<TrainingParams>({
    status: 'published',
    tutorial: false,
    date: today,
    hour: currentHour,
    type: 'past',
  })

  const { trainings, loading, formatTrainingCategories } = useTrainings({
    params: whereClause,
  })

  const filters: FilterType[] = [
    {
      id: 'categoryId',
      name: 'Category',
      type: 'option',
      options: categories,
    },
  ]

  const formik = useFormik({
    initialValues: {
      categoryId: 0,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: (values: any) => {
      const trainingCategories =
        values.categoryId === 0 ? [] : [values.categoryId]
      setWhereClause({
        status: 'published',
        tutorial: false,
        training_categories: trainingCategories,
        date: today,
        hour: currentHour,
        type: 'past',
      })
    },
  })

  if (loading) {
    return <Loader />
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue">
          Formação
        </h2>
        <h1 className="pb-10 text-xxxl font-medium text-brand">
          Formações Passadas
        </h1>
        <div className="pt-4 lg:pt-8">
          <Filter formik={formik} filters={filters} />
          <Table
            title="Mapa de formações passadas"
            columns={[
              {
                label: locale.title,
                property: 'name',
                sortable: false,
                format: item => item.name,
              },
              {
                label: locale.teacher,
                property: 'teacher',
                sortable: false,
                format: item => item.teacher,
              },
              {
                label: locale.date,
                property: 'date',
                sortable: false,
                format: item => formatDate(item.date).dashed,
              },
              {
                label: 'Vídeo',
                property: 'conference_video',
                sortable: false,
                format: item =>
                  item.conference_video ? (
                    <button onClick={() => {}}>
                      <img
                        src={`http://img.youtube.com/vi/${item.conference_video}/sddefault.jpg`}
                        width="100px"
                        alt={item.conference_video}
                      />
                    </button>
                  ) : (
                    <p>---</p>
                  ),
              },
              {
                label: 'Apresentação',
                property: 'conference_pdf',
                sortable: false,
                format: item =>
                  item?.attachments && item.attachments[0].url ? (
                    <Link
                      className="underline underline-offset-4 decoration-cloudyBlue hover:decoration-brand"
                      to={item.attachments[0].url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Descarregar (PDF)
                    </Link>
                  ) : (
                    <p>---</p>
                  ),
              },
              {
                label: 'Categorias',
                property: 'training_categories',
                sortable: false,
                format: item =>
                  formatTrainingCategories(
                    item.training_category.map(
                      (e: TrainingCategory) => e.category_id,
                    ),
                  ),
              },
            ]}
            data={trainings}
          />
        </div>
      </Wrapper>
    </div>
  )
}
