import { Text } from '@react-email/components'
import { EmailTemplate } from '../template'

export const SimulatorEmail = ({
  name,
  surname,
}: {
  name: string
  surname: string
}) => {
  return (
    <EmailTemplate>
      <>
        <Text className="text-base font-bold text-darkGrey py-6">
          Assunto: Simulação de seguro Innova | GO
        </Text>
        <Text className="text-base text-darkGrey m-0">
          Caro/a {name} {surname !== null ? surname : ''}
        </Text>
        <Text className="text-base text-darkGrey m-0 pb-6">
          consulte o resultado da simulação anexado a este email.
        </Text>
      </>
    </EmailTemplate>
  )
}
