import { Wrapper } from 'components'
import { Download } from 'components/Icons'
import { Link } from 'react-router-dom'

export const ClientBanner = ({ data, title }: { data: any; title: string }) => {
  if (!data) {
    return null
  }

  const bannerFileList = data.filter((c: any) => c.type === 'fileList')[0]
  const bannerImage = bannerFileList?.data?.items[0]?.file

  const bannerData = data.filter((c: any) => c.type !== 'fileList') || []

  return (
    <div className="py-8 bg-dashboardCampaign">
      <Wrapper>
        <div>
          <div className="grid grid-cols-2 bg-transparent">
            <div>
              <h3 className="text-brand text-sm font-mont font-medium pb-1">
                Protocolos
              </h3>
              <h1 className="text-xxxl text-mango font-mont font-medium pb-8">
                {title}
              </h1>
              {bannerData.map((item: any, index: number) => {
                switch (item.type) {
                  case 'header':
                    return (
                      <h3
                        key={`campaign${index}`}
                        className="text-white font-mont text-lg font-medium pb-4"
                      >
                        {item.data.text}
                      </h3>
                    )

                  case 'fileList':
                    return (
                      <div
                        key={`campaign${index}`}
                        className="flex items-center justify-between w-full"
                      >
                        <p className="font-lato leading-7 text-white whitespace-nowrap">
                          {item.data.title}
                        </p>
                        <Link
                          to={item.data.items[0].file}
                          target="_blank"
                          rel="noreferrer"
                          className="inline-block"
                        >
                          <div className="flex text-brand">
                            <Download />
                            <p className="font-mont text.sm uppercase px-4">
                              {item.data.items[0].label}
                            </p>
                          </div>
                        </Link>
                      </div>
                    )

                  default:
                  case 'paragraph':
                    return (
                      <p
                        key={`campaign${index}`}
                        className="text-white font-lato leading-7 pb-4"
                        dangerouslySetInnerHTML={{ __html: item.data.text }}
                      ></p>
                    )
                }
              })}
            </div>
            <div>
              <div className="ml-[30px]">
                <img src={bannerImage} />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
