import { useContext } from 'react'
import { HeaderBroker } from './headerBroker'

import { AuthContext } from '../../contexts/AuthContext'
import HeaderClient from './headerClient'
import { HeaderPublic } from 'components/HeaderPublic'

export const Header = () => {
  const { currentUser } = useContext(AuthContext)
  if (!currentUser) return <HeaderPublic/>

  return <>{currentUser?.role ? <HeaderBroker /> : <HeaderClient />}</>
}
