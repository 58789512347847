import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { locale } from 'locale'

import {
  FormInput,
  FormTextArea,
  Dropzone,
  FileType,
  FilledButton,
  FileGroup,
  LoaderInline,
} from 'components'
import { OccurrencyData, sendOccurenceEmail } from 'services/email'
import { useMutation } from '@tanstack/react-query'
import { render } from '@react-email/components'
import { OccurencyEmail } from 'emails/occurency'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(locale.required),
  email: Yup.string().email().required(locale.required),
  policyNo: Yup.string()
    .matches(/^[0-9]+$/, locale.validation.onlyNumbers)
    .required(locale.required),
  occurrenceDate: Yup.string().required(locale.required),
  occurenceLocal: Yup.string().required(locale.required),
  occurenceDescription: Yup.string().required(locale.required),
  measuresTaken: Yup.string().required(locale.required),
})

const initialFiles: File[] = []

export const ClausesForm = ({ notify }: { notify?: any }) => {
  const [files, setFiles] = useState(initialFiles)
  const onAddFile = async (acceptedFiles: FileType[]) => {
    acceptedFiles.map((file: FileType) => {
      formik.setFieldValue('attachments', [...files, file])
      setFiles((prevState: any) => [...prevState, file])
    })
  }

  const onRemoveFile = (item: FileType) => {
    const newFiles = files.filter((file: FileType) => file.name !== item.name)
    setFiles(newFiles)
  }

  const { mutateAsync: createOccurenceEmailMutation, isPending } = useMutation({
    mutationKey: ['sendOccurenceEmail'],
    mutationFn: (values: OccurrencyData) => sendOccurenceEmail(values),
    onSuccess: () => notify('Email enviado com sucesso', 'success'),
    onError: () => notify('Erro ao enviar ocorrência', 'error'),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      policyNo: '',
      occurrenceDate: '',
      occurenceLocal: '',
      occurenceDescription: '',
      measuresTaken: '',
      attachments: files,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const htmlMessage = render(<OccurencyEmail claim={values} />, {
        pretty: true,
      })
      const insertData = {
        email: values.email,
        htmlMessage,
        attachments: files,
      }
      await createOccurenceEmailMutation(insertData)
    },
  })
  return (
    <>
      <h2 className="pb-4 font-lato text-xl font-medium text-brand">
        Participação de Sinistro/Circunstância
      </h2>
      <form
        onSubmit={() => formik.handleSubmit}
        className="flex flex-col gap-y-4"
      >
        <FormInput
          formik={formik}
          name="name"
          label="Nome Completo do Segurado"
          type="text"
          required
          extraClasses="font-semibold"
        />
        <FormInput
          formik={formik}
          name="email"
          label="Email de Contacto"
          type="text"
          required
          extraClasses="font-semibold"
        />

        <div className="grid grid-cols-2 gap-x-8 items-center justify-center">
          <FormInput
            formik={formik}
            name="policyNo"
            label="Nº de Apólice"
            type="text"
            required
            extraClasses="font-semibold"
          />
          <FormInput
            formik={formik}
            name="occurrenceDate"
            label="Data da Ocorrência"
            type="date"
            required
            extraClasses="font-semibold"
          />
        </div>
        <FormInput
          formik={formik}
          name="occurenceLocal"
          label="Local da Ocorrência"
          type="text"
          required
          extraClasses="font-semibold"
        />
        <FormTextArea
          formik={formik}
          name="occurenceDescription"
          label="Breve Descrição do Sinistro/Circunstância"
          required
          extraClasses="font-semibold"
        />
        <FormTextArea
          formik={formik}
          name="measuresTaken"
          label="Medidas já Adotadas"
          required
          extraClasses="font-semibold"
        />
        <Dropzone
          maxFiles={2}
          maxSize={5242880}
          fileTypes={{ 'image/*': ['.jpeg', '.jpg', '.png', '.pdf'] }}
          onAddFile={onAddFile}
        />
        <FileGroup files={files} onRemove={file => onRemoveFile(file)} />
        <div className="flex justify-end">
          {isPending ? (
            <LoaderInline />
          ) : (
            <FilledButton
              type="button"
              action={() => formik.handleSubmit()}
              label="Enviar"
            />
          )}
        </div>
      </form>
    </>
  )
}
