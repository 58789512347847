export const Loader = () => (
  <div className=" h-screen">
    <span className="fixed bg-[rgba(0,0,0,.2)] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <svg
        viewBox="0 0 18 18"
        width="30"
        height="30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 9h2a7 7 0 1 1 7 7v2a9 9 0 1 0-9-9z">
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 9 9"
            to="360 9 9"
            dur="0.7s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </span>
  </div>
)

export const LoaderInline = () => (
  <div>
    <svg
      viewBox="0 0 18 18"
      width="30"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 9h2a7 7 0 1 1 7 7v2a9 9 0 1 0-9-9z">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 9 9"
          to="360 9 9"
          dur="0.7s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
)
