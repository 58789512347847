import { Text } from '@react-email/components'
import { EmailTemplate } from 'emails/template'

export const OccurencyEmail = ({
  claim,
}: {
  claim: {
    name: string
    email: string
    policyNo: string
    occurrenceDate: string
    occurenceLocal: string
    occurenceDescription: string
    measuresTaken: string
  }
}) => {
  return (
    <EmailTemplate>
      <>
        <Text className="text-xl font-bold text-darkGrey">
          Participação de Sinistro/Circunstância
        </Text>
        <Text className="text-base text-darkGrey">Nome: {claim.name}</Text>
        <Text className="text-base text-darkGrey">Email: {claim.email}</Text>
        <Text className="text-base text-darkGrey">
          N.º de apólice: {claim.policyNo}
        </Text>
        <Text className="text-base text-darkGrey">
          Data da ocorrência: {claim.occurrenceDate}
        </Text>
        <Text className="text-base text-darkGrey">
          Local da ocorrência: {claim.occurenceLocal}
        </Text>
        <Text className="text-base text-darkGrey">
          Descrição do sinistro/circunstância: {claim.occurenceDescription}
        </Text>
        <Text className="text-base text-darkGrey">
          Medidas já adotadas: {claim.measuresTaken}
        </Text>
      </>
    </EmailTemplate>
  )
}
