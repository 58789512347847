import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'

export type contentParams = {
  type: 'process' | 'campaign' | 'information' | 'newsletter'
  slug?: string
  status?: 'published' | 'draft'
  publish_at?: string
  limit?: number
  offset?: number
}
export const getContent = async ({ params }: { params: contentParams }) => {
  try {
    const { data } = await axiosClient.get('/content', { params })
    return data
  } catch (error) {
    return handleServerError(error)
  }
}
