import { FilledButton } from 'components/Buttons'
import { Add, Close, Pdf, Upload } from 'components/Icons'
import { useCallback } from 'react'
import { Accept, useDropzone } from 'react-dropzone'

export type FileType = {
  path?: string
  lastModified: number
  name: string
  size: number
  type: string
}

export const Dropzone = ({
  maxFiles,
  maxSize,
  fileTypes,
  compact,
  label,
  onAddFile,
}: {
  maxFiles: number
  maxSize: number
  fileTypes: Accept
  compact?: boolean
  label?: string
  onAddFile: (files: FileType[]) => void
}) => {
  const onDrop = useCallback(
    (acceptedFiles: FileType[]) => {
      onAddFile(acceptedFiles)
    },
    [onAddFile],
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      maxFiles: maxFiles,
      maxSize: maxSize,
      accept: fileTypes,
    })

  if (compact) {
    return (
      <div className="container">
        <div
          {...getRootProps({ className: 'dropzone' })}
          className="flex flex-col justify-center items-center border-2 border-dashed border-brand rounded-xl p-4 cursor-pointer h-12"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            isDragAccept ? (
              <p>Largue os ficheiros aqui.</p>
            ) : (
              <p>Ficheiro(s) inválido(s)</p>
            )
          ) : (
            <div className="flex w-full justify-between items-center">
              <p className="font-lato font-medium text-md text-darkBlue">
                {label}
              </p>
              <div className="flex items-center gap-x-2">
                <span className="w-6">
                  <Upload />
                </span>
                <p className="font-medium text-brand uppercase">Anexar</p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div
        {...getRootProps({ className: 'dropzone' })}
        className="flex flex-col justify-center items-center border-2 border-dashed border-brand rounded-xl p-4 mb-6 cursor-pointer text-center font-mont text-base leading-7"
      >
        <input {...getInputProps()} />
        <span className="w-10 text-brand">
          <Add />
        </span>
        {isDragActive ? (
          isDragAccept ? (
            <p>Largue os ficheiros aqui.</p>
          ) : (
            <>
              <p>Ficheiro(s) inválido(s)</p>
              <p>Verifque a quantidade e tipo de ficheiros</p>
            </>
          )
        ) : (
          <div className="flex flex-col pt-5">
            <FilledButton label="Selecionar ficheiro" />
            <p className="hidden lg:block">
              ou arraste os ficheiros para aqui.
            </p>
            <p className="text-darkGrey/40">
              ( Máximo de ficheiros: {maxFiles} )
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export const FileGroup = ({
  files,
  onRemove,
}: {
  files: FileType[]
  onRemove: (file: FileType) => void
}) => {
  return (
    <>
      {files.map((item: FileType, index: number) => (
        <div
          key={`file_${index}_${item.name}`}
          className={`flex relative px-8 py-1 rounded-md border-cloudyBlue border-solid border-4 h-12`}
        >
          <label
            className="flex items-center justify-between w-full h-12font-lato text-sm font-semibold text-darkBlue leading-10"
            htmlFor={item.name}
          >
            <>
              <div className="w-4 h-4">
                <Pdf />
              </div>
              <span className="w-44 overflow-hidden text-ellipsis text-nowrap">
                {item.name}
              </span>
              <span>{(item.size / 1024).toFixed(0)}kb</span>
              <span
                className="w-4 text-darkBlue"
                onClick={() => onRemove(item)}
              >
                <Close />
              </span>
            </>
          </label>
        </div>
      ))}
    </>
  )
}
