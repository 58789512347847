export const Excel = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
    >
      <g>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
          <path
            fill="#fff"
            d="M3099.3,4505c-1497.1-285.2-2747.2-532.2-2779.7-545.6c-32.5-13.4-72.7-51.7-90-84.2c-30.6-57.4-32.6-342.7-26.8-3769.5l5.7-3710.2l57.4-55.5c57.4-57.4,84.2-63.2,2848.7-587.7L5905.8-4780l63.2,34.5c36.4,19.1,74.7,55.5,86.2,80.4c13.4,32.5,21,222.1,21,553.3v505.4l1709.6,5.7l1709.6,5.7l93.8,49.8c67,34.5,113,74.7,153.2,141.7l57.4,91.9V128.6v3440.2l-57.4,91.9c-40.2,67-86.2,107.2-153.2,141.7l-93.8,49.8l-1709.6,5.7l-1707.7,5.8l-5.7,524.5l-5.7,526.5l-49.8,49.8c-32.5,30.6-76.6,51.7-124.4,55.5C5848.4,5023.8,4728.5,4818.9,3099.3,4505z M5693.3,128.6c0-3266-5.7-4460.6-21-4460.6c-36.4,0-5057.9,959.1-5075.2,968.7c-7.6,5.8-15.3,1577.5-15.3,3493.8c0,2909.9,3.8,3484.3,26.8,3493.8c36.4,13.4,4990.9,961,5042.6,962.9l42.1,1.9V128.6z M9407.3,128.6v-3331.1H7741.8H6076.2v488.2v488.2h392.5h392.5v201v201h-392.5h-392.5v583.9v583.9h392.5h392.5v201v201h-392.5h-392.5v488.2v488.2h392.5h392.5v191.4V1105h-392.5h-392.5v488.2v488.2h392.5h392.5v201v201h-392.5h-392.5v488.2v488.2h1665.6h1665.5V128.6z"
          />
          <path
            fill="#fff"
            d="M1563.9,1866.9c24.9-45.9,260.4-448,518.8-892.1c260.4-446.1,478.6-823.2,482.4-836.6c5.7-11.5-248.9-428.8-566.7-924.7c-315.9-495.8-574.3-907.4-574.3-913.2c0-5.7,218.3-9.6,486.3-9.6h484.3l315.9,589.6c174.2,323.6,335,649,359.9,721.7l46,134l32.5-111c17.2-59.3,178-384.8,354.2-721.7l323.5-612.6H4313c379.1,0,482.4,5.7,474.8,24.9c-3.8,11.5-254.6,426.9-557.1,918.9c-300.6,492-547.5,899.8-547.5,903.6c0,7.7,582,974.4,982.1,1633l109.1,176.1l-463.3-3.8l-463.3-5.7l-283.4-526.5c-155.1-289.1-310.1-601.1-342.7-693c-34.5-93.8-63.2-168.5-65.1-168.5c-1.9,0-26.8,63.2-53.6,139.8c-28.7,78.5-170.4,390.6-315.9,694.9l-264.2,553.3l-503.5,5.7l-503.5,3.8L1563.9,1866.9z"
          />
          <path
            fill="#fff"
            d="M7263.2,2282.3v-201l781.1,3.8l779.2,5.7v191.4v191.4l-779.2,5.7l-781.1,3.8V2282.3z"
          />
          <path
            fill="#fff"
            d="M7263.2,913.5V722.1h784.9H8833v191.4V1105h-784.9h-784.9V913.5z"
          />
          <path
            fill="#fff"
            d="M7263.2-455.3v-201h784.9h786.8l-5.7,197.2l-5.7,195.3l-779.2,5.7l-781.1,3.8V-455.3z"
          />
          <path
            fill="#fff"
            d="M7263.2-2025.1v-201l781.1,3.8l779.2,5.7v191.4v191.4l-779.2,5.7l-781.1,3.8V-2025.1z"
          />
        </g>
      </g>
    </svg>
  )
}
