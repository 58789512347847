import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AuthContext } from '../../contexts/AuthContext'
import { AppContext } from 'contexts/AppContext'
import { getProduct } from 'services/products'
import { NotificationContainer, useNotification } from 'components/Notification'
import {
  FilledButton,
  FormInput,
  FormTextArea,
  Loader,
  ContentEditor,
  Wrapper,
} from 'components'
import { BodyItem } from 'types/editor'
import { LocaleType, locale } from 'locale'
import { getProductBodyContent } from 'utils/product'

const validationSchema = Yup.object().shape({
  nif: Yup.string()
    .matches(/^[0-9]{9}$/, locale.validation.digits9)
    .required(locale.required),
  name: Yup.string().required(locale.required),
  address1: Yup.string().required(locale.required),
  postal_code: Yup.string().required(locale.required),
  content: Yup.string().min(10, locale.min10Chars).required(locale.required),
})

export const ProductBroker = ({ slug }: { slug: string }) => {
  const { notifications, notify } = useNotification()

  const { currentUser } = useContext(AuthContext)
  const { products } = useContext(AppContext)
  const id = products.find(product => product.slug === slug)?.id || -1

  const { data, isLoading } = useQuery({
    queryKey: ['getProduct', { id }],
    queryFn: () => getProduct({ id }),
  })

  // const [sendProductForm] = useMutation(SendProductForm, {
  //   onCompleted: data => {
  //     if (!data.sendProductForm.error) {
  //       notify('Pedido enviado com sucesso', 'success')
  //     } else {
  //       notify('Ocorreu um erro, por favor tente mais tarde.', 'error')
  //     }
  //     /* setShowModal(false) */
  //   },
  //   onError: () => notify(),
  // })
  const formik = useFormik({
    initialValues: {
      nif: '',
      name: '',
      address1: '',
      postal_code: '',
      content: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      // sendProductForm({
      //   variables: {
      //     productId: product.id,
      //     content: JSON.stringify({
      //       'Nif Tomador': values.nif,
      //       'Nome Cliente': values.name,
      //       'Morada 1': values.address1,
      //       'Código Postal': values.postal_code,
      //       Resposta: values.content,
      //     }),
      //   },
      // })
    },
  })
  if (isLoading || !id) {
    return <Loader />
  }

  const product = data.product
  const attachments = product.attachments
  const productBody = product.body?.length > 0 ? JSON.parse(product.body) : []
  const columns: Array<Array<BodyItem>> = getProductBodyContent(productBody)

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase font-mont text-sm font-medium text-darkBlue">
          {locale[data.type as LocaleType] as string}
        </h2>
        <h1 className="pb-10 font-mont text-xxxl font-medium text-brand">
          {product.title}
        </h1>
        <div className="grid grid-cols-[1fr_1px_1fr] grid-rows-[auto_1fr] gap-x-12 items-start justify-between">
          {columns.map((column, index) => {
            return (
              <div key={`editor_col_${index}`} className="h-full">
                {column.map((item, index) => {
                  return (
                    <ContentEditor
                      key={`editor_${index}`}
                      attachments={attachments as any}
                      index={index}
                      item={item}
                    />
                  )
                })}
                {index === 2 && (
                  <div className="mt-8">
                    {data.has_simulator ? (
                      <FilledButton
                        label="Simular"
                        url={`/simuladores/${product.slug}`}
                      />
                    ) : (
                      // <FilledButton
                      //   label="Pedir Cotação"
                      //   action={() => setShowModal(true)}
                      // />
                      <>
                        <h2 className="pb-4 font-mont text-xxxl font-medium text-brand">
                          Pedido de Cotação
                        </h2>
                        <form
                          onSubmit={formik.handleSubmit}
                          className="flex flex-col gap-y-4"
                        >
                          <div className="grid grid-cols-2 gap-x-8 items-center justify-center">
                            <div className="font-mont text-xs font-bold leading-7 text-black">
                              <p>Broker</p>
                              <p className=" font-normal">
                                {currentUser?.broker?.name}
                              </p>
                              <p>Produto</p>
                              <p className="font-normal">{product.title}</p>
                            </div>

                            <div className="font-mont text-xs font-bold leading-7 text-black">
                              <p>Utilizador</p>
                              <p className=" font-normal">
                                {currentUser?.forename}
                              </p>
                              <p>Email</p>
                              <p className="font-normal">
                                {currentUser?.email}
                              </p>
                            </div>
                          </div>

                          <div className="grid grid-cols-2 gap-x-8 items-center justify-center">
                            <div>
                              <FormInput
                                formik={formik}
                                name="nif"
                                label="NIF Tomador"
                                type="text"
                                required
                                extraClasses="font-semibold"
                              />
                              <FormInput
                                formik={formik}
                                name="name"
                                label="Nome Cliente"
                                type="text"
                                required
                                extraClasses="font-semibold"
                              />
                            </div>
                            <div>
                              <FormInput
                                formik={formik}
                                name="address1"
                                label="Morada"
                                type="text"
                                required
                                extraClasses="font-semibold"
                              />
                              <FormInput
                                formik={formik}
                                name="postal_code"
                                label="Código Postal"
                                type="text"
                                required
                                extraClasses="font-semibold"
                              />
                            </div>
                          </div>
                          <div className="w-full">
                            <FormTextArea
                              formik={formik}
                              name="content"
                              label="Dados para o pedido de cotação"
                              required
                              extraClasses="font-semibold"
                            />
                          </div>
                          <div className="flex justify-end">
                            <FilledButton type="submit" label="Enviar" />
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </Wrapper>
    </div>
  )
}
