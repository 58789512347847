import { FilledButton, Modal, Wrapper } from 'components'
import { useState } from 'react'
import { RegisterIntro } from './registerIntro'
import { BrokerForm } from '../../../components/Form/brokerForm'
import { locale } from 'locale'

export const Register = () => {
  const [email, setEmail] = useState('')

  return (
    <>
      {email ? (
        <div className="w-full flex-1 py-12">
          <Wrapper>
            <Modal onClose={() => {}}>
              <h2 className="font-mont font-medium text-xl leading-4 text-brand py-4">
                {locale.accountRequest}
              </h2>
              <div className="flex flex-col gap-y-4 font-lato font-normal text-base text-darkGrey">
                <p>{locale.accountRequestReply}</p>
                <p>{email.toLowerCase()}</p>
                <div className="flex justify-end">
                  <FilledButton url="/" label={locale.backToStart} />
                </div>
              </div>
            </Modal>
          </Wrapper>
        </div>
      ) : (
        <div className="w-full flex-1 py-12">
          <Wrapper>
            <div className="flex flex-col gap-y-8 justify-center items-start divide-y-2 divide-cloudyBlue md:grid md:grid-cols-2 md:grid-rows-none md:divide-y-0 md:divide-x-2">
              <RegisterIntro />
              <BrokerForm
                type="register"
                onSuccess={email => email && setEmail(email.toLowerCase())}
              />
            </div>
          </Wrapper>
        </div>
      )}
    </>
  )
}
