import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  FilledButton,
  FormInput,
  FormSelect,
  Loader,
  LoaderInline,
  NotificationContainer,
  useNotification,
} from 'components'
import { locale } from 'locale'
import { createUserInvite } from 'services/invites'
import { Broker } from 'types/broker'
import { UserInvite } from 'types/invite'
import { UserRole } from 'types/user'
import { useMutation } from '@tanstack/react-query'
import { render } from '@react-email/components'
import { UserRegistrationEmail } from 'emails/invites'
import { APP_BASE_CONFIG } from 'config'
import { sendEmail } from 'services/email'
import { queryClient } from 'App'

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(locale.required),
  name: Yup.string().required(locale.required),
  role: Yup.string().required(locale.required),
})

export const CreateInvite = ({
  broker,
  setModayVisibility,
}: {
  broker: Broker
  setModayVisibility: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { notify, notifications } = useNotification()

  const { mutateAsync: sendInviteEmail, isPending: isPendingInviteEmail } =
    useMutation({
      mutationKey: ['sendInviteEmail'],
      mutationFn: async (user: UserInvite) => {
        const htmlMessage = render(
          <UserRegistrationEmail
            name={user.name}
            url={`${APP_BASE_CONFIG.BASE_URL}/register-user/${user.id}`}
          />,
        )
        await sendEmail({
          email: user.email,
          htmlMessage,
          subject: '[EXT]Convite de acesso ao Portal Innova | GO',
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['getBrokerDetails'] })
        setModayVisibility(false)
      },
      onError: () => notify('Erro ao enviar convite', 'error'),
    })
  const { mutateAsync: createInvite, isPending: isPendingCreateInvite } =
    useMutation({
      mutationKey: ['createUserInvite'],
      mutationFn: (answers: UserInvite) => createUserInvite(answers),
      onSuccess: data => sendInviteEmail(data),
      onError: () => notify('Erro ao criar convite', 'error'),
    })

  const formik = useFormik({
    initialValues: { email: '', name: '', role: 'admin' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const inviteData: Partial<UserInvite> = {
        broker_id: broker.id,
        name: values.name,
        email: values.email.toLowerCase(),
        role: values.role as UserRole,
        status: 'inactive',
        metadata: { brokerName: broker.name },
      }

      createInvite(inviteData as UserInvite)
    },
  })

  if (!broker) {
    return <Loader />
  }

  const isPending = isPendingCreateInvite || isPendingInviteEmail
  return (
    <form onSubmit={formik.handleSubmit}>
      <NotificationContainer notifications={notifications} />
      <h2 className="font-mont font-medium text-xxxl pb-8">
        {locale.invite} {locale.user}
      </h2>

      <FormInput
        type="email"
        name="email"
        label={locale.email}
        formik={formik}
      />
      <FormInput type="text" name="name" label={locale.name} formik={formik} />
      <FormSelect
        formik={formik}
        label={locale.role}
        name="role"
        options={[
          { label: locale.user, value: 'user' },
          { label: locale.admin, value: 'admin' },
        ]}
        allowEmpty={false}
      />
      <div className="flex justify-end">
        {isPending ? (
          <LoaderInline />
        ) : (
          <FilledButton type="submit" label={locale.invite} />
        )}
      </div>
    </form>
  )
}
