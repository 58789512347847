import { useFormik } from 'formik'
import * as Yup from 'yup'

import { locale } from 'locale'
import { FilledButton, FormDate, FormInput, LoaderInline } from 'components'
import { useMutation } from '@tanstack/react-query'
import { updateUserDetails } from 'services/user'
import { useContext } from 'react'
import { AuthContext } from 'contexts/AuthContext'

const validationPersonal = Yup.object().shape({
  forename: Yup.string().required(locale.required),
  id_card: Yup.string().nullable(),
  id_card_expiricy: Yup.string()
    .nullable()
    .matches(/^\d{4}[./-]\d{2}[./-]\d{2}$/, {
      message: 'A data deve ser inserida no formato AAAA-MM-DD',
      excludeEmptyString: true,
    }),
  birthdate: Yup.string()
    .nullable()
    .matches(/^\d{4}[./-]\d{2}[./-]\d{2}$/, {
      message: 'A data deve ser inserida no formato AAAA-MM-DD',
      excludeEmptyString: true,
    }),
})

export type UserSubmitType = {
  forename: string
  id_card: string
  id_card_expiricy: string | null
  birthdate: string
}

export const EditProfile = ({
  initialValues,
  notify,
}: {
  initialValues: UserSubmitType
  notify: (value?: string, type?: string) => void
}) => {
  const { currentUser } = useContext(AuthContext)
  const { mutateAsync: updateUser, isPending } = useMutation({
    mutationKey: ['updateUser'],
    mutationFn: ({ id, values }: { id: number; values: any }) =>
      updateUserDetails(id, values),
    onError: () => notify(),
    onSuccess: () => notify('Actualizado com successo', 'success'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationPersonal,
    onSubmit: (values: UserSubmitType) => {
      updateUser({ id: currentUser?.id!, values })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="py-8 text-darkBlue text-xl">{locale.personalData}</h2>

      <FormInput
        type="text"
        name="forename"
        label={locale.forename}
        formik={formik}
      />
      <FormInput
        type="email"
        name="email"
        label={locale.email}
        formik={formik}
        disabled={true}
      />
      <FormInput
        type="text"
        name="id_card"
        label={locale.idCard}
        formik={formik}
      />

      <FormDate
        formik={formik}
        name="id_card_expiricy"
        label={locale.idCardValidity}
      />

      <FormDate formik={formik} name="birthdate" label={locale.birthdate} />

      {isPending ? (
        <LoaderInline />
      ) : (
        <FilledButton type="submit" label={locale.submit} />
      )}
    </form>
  )
}
