export const passwordValidator = (password: string) => {
  let caps = false
  let normal = false
  let number = false
  let length = false
  let special = false

  if (password) {
    caps = password.replace(/[^A-Z]/g, '').length > 0 ? true : false
    normal = password.replace(/[^a-z]/g, '').length > 0 ? true : false
    number = password.replace(/[^0-9]/g, '').length > 0 ? true : false
    length = password.length >= 10 ? true : false
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    special = specialChars.test(password)
  }

  return { caps, normal, number, length, special }
}

export const PasswordHelper = ({ password }: { password: string }) => {
  const { caps, normal, number, length, special } = passwordValidator(password)

  return (
    <div className="my-8 bg-lightGrey p-4">
      <h3 className="text-darkBlue text-lg uppercase pb-4">
        Ajudante de escolha de password
      </h3>

      <p className="pb-2">Crie uma password segura com as seguintes regras:</p>

      <ul className="m-0 pl-4">
        <li
          className={`font-mont text-sm leading-7 font-bold ${
            length ? 'text-success' : 'text-error'
          }`}
        >
          Mínimo de 10 caractéres
        </li>
        <li
          className={`font-mont text-sm leading-7 font-bold ${
            caps ? 'text-success' : 'text-error'
          }`}
        >
          Pelo menos uma maiúscula
        </li>
        <li
          className={`font-mont text-sm leading-7 font-bold ${
            normal ? 'text-success' : 'text-error'
          }`}
        >
          Pelo menos uma minúscula
        </li>
        <li
          className={`font-mont text-sm leading-7 font-bold ${
            number ? 'text-success' : 'text-error'
          }`}
        >
          Pelo menos um algarismo
        </li>
        <li
          className={`font-mont text-sm leading-7 font-bold ${
            special ? 'text-success' : 'text-error'
          }`}
        >
          Pelo menos um caracter especial (por exemplo: $-_!)
        </li>
      </ul>
    </div>
  )
}
