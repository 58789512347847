import { handleServerError } from 'utils/handleError'
import { axiosClient } from './axiosClient'
import { NifParams } from 'types/nif'

export const validateNif = async ({ brokerNo, nif }: NifParams) => {
  try {
    const response = await axiosClient.get(`/isaac/idCheck/${brokerNo}/${nif}`)
    return response.data
  } catch (error) {
    return handleServerError(error)
  }
}
