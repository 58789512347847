type ListType = {
  id: string
  type?: string
  items?: string[]
  /* | {
        label: string
        name: string
        file: string
        id: string
        title?: string
      }[] */
}

export const List = ({ id, type, items }: ListType) => {
  if (type === 'ordered') {
    return (
      <ol className="block list-disc mb-4 ps-10">
        {items &&
          items.map((item, index) => {
            return (
              <li
                key={`ol_item${id}${index}`}
                className="font-lato text-base font-normal text-black leading-7"
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
              />
            )
          })}
      </ol>
    )
  } else {
    return (
      <ul className="block list-disc mb-4 ps-10">
        {items &&
          items.map((item, index) => {
            return (
              <li
                key={`ul_item${id}${index}`}
                className="font-lato text-base font-normal text-black leading-7"
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
              />
            )
          })}
      </ul>
    )
  }
}
