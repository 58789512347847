import { Link } from 'react-router-dom'

type FilledButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string
  action?: () => void
  url?: string
  target?: string
  extraClasses?: string
}

export const FilledButton = ({
  label,
  action,
  type = 'button',
  disabled,
  url,
  children,
  target,
  extraClasses,
  className = '',
}: FilledButtonProps) => {
  const btnClasses = `${className} rounded uppercase px-2 xl:px-4 py-2 border-none h-fit text-sm font-medium text-white bg-brand shadow-md focus:bg-rustyRedTwo hover:bg-rustyRedTwo active:bg-melon`

  if (url) {
    return (
      <Link
        to={url}
        className={`${btnClasses} inline-block ${
          extraClasses ? extraClasses : ''
        }`}
        target={target ? target : '_self'}
      >
        {label}
      </Link>
    )
  }

  return (
    <button
      className={`${btnClasses} ${
        children ? ' flex items-center justify-between' : ''
      } disabled:opacity-40`}
      onClick={action ? action : () => {}}
      type={type}
      disabled={disabled}
    >
      {label}
      {children}
    </button>
  )
}
