import { Arrow } from 'components/Icons'
import { FilterType } from '.'
import { useState } from 'react'

export const SelectFilter = ({
  filter,
  formik,
  className,
  onChange,
}: {
  filter: FilterType
  formik?: any
  className?: string
  onChange: (optionidx: number | string) => void
}) => {
  const defaultValue = formik?.getFieldProps(filter.id).value
  const [value, setValue] = useState(defaultValue)

  const handleChange = (option: string) => {
    const validatedValue = Number.isInteger(+option) ? +option : option
    onChange(validatedValue)
    setValue(validatedValue)
  }
  return (
    <div key={filter.id} className={`relative ${className}`}>
      <label
        htmlFor={filter.id}
        className="absolute -top-2 left-4 inline-block bg-lightGrey px-1 text-xs font-medium text-gray-900"
      >
        {filter.name}
      </label>
      <div className="flex items-center">
        <select
          className="appearance-none bg-transparent group inline-flex justify-between items-center border border-gray-400 rounded-xl px-4 py-2 w-full text-gray-900 text-base font-medium font-lato focus:shadow-none focus:ring-0 focus:outline-none"
          id={filter.id}
          value={formik?.getFieldProps(filter.id).value ?? value}
          onChange={e => handleChange(e.target.value)}
        >
          {filter.options.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <span
          className={`absolute right-3 pointer-events-none text-gray-400 group-hover:text-gray-500}`}
        >
          <Arrow className="ml-1 h-4 w-4" aria-hidden="true" />
        </span>
      </div>
    </div>
  )
}
