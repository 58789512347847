import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { locale } from 'locale'
import {
  FilledButton,
  FormPassword,
  Loader,
  LoaderInline,
  Modal,
  NotificationContainer,
  PasswordHelper,
  WrapperSmall,
  passwordValidator,
  useNotification,
} from 'components'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getInvite } from 'services/invites'
import { useParams } from 'react-router-dom'
import { registerUser } from 'services/user'

const validationSchema = Yup.object().shape({
  password: Yup.string().min(6, locale.min6Chars).required(locale.required),
  password2: Yup.string()
    .oneOf([Yup.ref('password')], locale.passwordsMustMatch)
    .required(locale.required),
})

export const RegisterUser = () => {
  const { id } = useParams() as { id: string }

  const [accountCreated, setAccountCreated] = useState<boolean>(false)
  const [registryButtonDisabled, setRegistryButtonDisabled] =
    useState<boolean>(true)
  const [newPassword, setNewPassword] = useState<string>('')
  const { notifications, notify } = useNotification()

  const { data, isLoading } = useQuery({
    queryKey: ['getInvite', id],
    queryFn: () => getInvite(id),
  })

  const { mutateAsync: handleRegisterUser, isPending } = useMutation({
    mutationKey: ['registerUser'],
    mutationFn: async ({
      invite_id,
      password,
    }: {
      invite_id: string
      password: string
    }) => await registerUser({ invite_id, password }),
    onSuccess: () => setAccountCreated(true),
    onError: () => notify('Ocorreu um erro ao registar o utizador', 'error'),
  })

  useEffect(() => {
    const { caps, length, normal, number, special } =
      passwordValidator(newPassword)
    if (caps && length && normal && number && special) {
      setRegistryButtonDisabled(false)
    }
  }, [newPassword])

  const formik = useFormik({
    initialValues: {
      password: '',
      password2: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: validationSchema,
    validate: values => {
      setNewPassword(values.password)
    },
    onSubmit: async values =>
      await handleRegisterUser({ invite_id: id, password: values.password }),
  })

  if (isLoading) {
    return <Loader />
  }

  if (data) {
    return (
      <WrapperSmall>
        <NotificationContainer notifications={notifications} />
        {accountCreated ? (
          <Modal onClose={() => {}}>
            <h2 className="font-mont text-xxxl font-medium pb-2">
              {locale.accountCreated}
            </h2>
            <p>{locale.accountCreatedInfo}</p>
            <div className="flex justify-end">
              <FilledButton url="/" label={locale.goToLogin} />
            </div>
          </Modal>
        ) : (
          <></>
        )}
        <div className="py-20">
          <h1 className="font-mont text-xxxl font.medium pb-2">
            {locale.registerInnovagoPortal}
          </h1>
          <p className="font-lato pb-2 text-black">
            Foi convidado através da conta {data.metadata.brokerName}
            &nbsp; para colaborador no portal Innova|GO. Para concluir o
            processo de acesso, só falta criar a sua conta:
          </p>
          <p className="font-lato pb-8 font-semibold text-xl">
            {data.email.toLowerCase()}
          </p>

          <form onSubmit={formik.handleSubmit}>
            <div>
              <FormPassword
                name="password"
                label={locale.password}
                formik={formik}
              />
              <FormPassword
                name="password2"
                label={locale.confirmPassword}
                formik={formik}
              />
              <PasswordHelper password={newPassword} />
            </div>
            <div className="flex justify-end">
              {isPending ? (
                <LoaderInline />
              ) : (
                <FilledButton
                  disabled={registryButtonDisabled}
                  type="submit"
                  label={locale.submit}
                />
              )}
            </div>
          </form>
        </div>
      </WrapperSmall>
    )
  } else {
    return (
      <WrapperSmall>
        <div className="py-20">
          <h1 className="font-mont text-xxxl font.medium pb-2">
            {locale.accountCreationRequired}
          </h1>
          <p className="font-lato text-black">
            Ocorreu um erro, por favor tente mais tarde e em caso de
            persistência do erro por favor contacte-nos.
          </p>
        </div>
      </WrapperSmall>
    )
  }
}

export default RegisterUser
