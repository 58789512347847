import { useContext, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { EditBrokerProfile } from './editBrokerProfile'
import { CreateInvite } from './createInvite'

import { AuthContext } from '../../contexts/AuthContext'

import {
  Badge,
  FilledButton,
  Loader,
  Modal,
  NotificationContainer,
  Status,
  Table,
  Wrapper,
  useNotification,
} from 'components'
import { formatDate } from 'utils/dates'
import { locale } from 'locale'
import { getBrokerDetails, updateUserDetails } from 'services/user'
import { Pdf } from 'components/Icons'
import { queryClient } from 'App'
import { UserInvite } from 'types/invite'
import { deleteInvite, updateInvite } from 'services/invites'
import { Link } from 'react-router-dom'

type BrokerUserType = {
  email: string
  forename: string
  id: number
  role: 'admin' | 'user'
  status: Status
}

export const ProfileBroker = () => {
  const { currentUser } = useContext(AuthContext)
  const { notifications, notify } = useNotification()
  const [modalVisibility, setModayVisibility] = useState<boolean>(false)
  const [invitePopin, setViewInvitePopin] = useState<boolean>(false)
  const [editProfile, setViewEditProfile] = useState<boolean>(false)

  const brokerId = currentUser?.broker?.id!

  const { data: brokerData, isLoading } = useQuery({
    queryKey: ['getBrokerDetails'],
    queryFn: async () => await getBrokerDetails(brokerId),
  })

  const {
    attachments,
    vat,
    name,
    phone,
    correspondance_address_line_1,
    correspondance_address_line_2,
    correspondance_address_line_3,
    correspondance_address_line_4,
  } = brokerData || {}

  const closeModal = () => {
    setModayVisibility(false)
  }

  const isAdmin = currentUser?.role !== 'user'
  const isBrokerActive = brokerData?.status === 'active'
  const brokerStatusString = brokerData?.status
    ? locale.statusObj[
        brokerData.status as keyof typeof locale.statusObj
      ].toLocaleLowerCase()
    : ''

  const { mutate: handleUserStatus } = useMutation({
    mutationKey: ['changeUserStatus'],
    mutationFn: ({ id, status }: { id: number; status: string }) =>
      updateUserDetails(id, { status }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['getBrokerDetails'] }),
  })

  const { mutate: handleDeleteInvite } = useMutation({
    mutationKey: ['deleteInvite'],
    mutationFn: async (id: string) => await deleteInvite(id),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['getBrokerDetails'] }),
    onError: () => notify('Erro ao eliminar convite', 'error'),
  })

  const { mutate: handleInviteStatus } = useMutation({
    mutationKey: ['changeInviteStatus'],
    mutationFn: ({
      id,
      status,
    }: {
      id: string
      status: 'active' | 'inactive'
    }) => updateInvite(id, { status }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['getBrokerDetails'] }),
    onError: () => notify('Erro ao alterar estado do convite', 'error'),
  })

  if (isLoading || !brokerData) {
    return <Loader />
  }

  return (
    <div className="pt-12 pb-[150px]">
      <Wrapper>
        <NotificationContainer notifications={notifications} />

        {modalVisibility && invitePopin ? (
          <Modal onClose={closeModal}>
            <CreateInvite
              broker={brokerData}
              setModayVisibility={setModayVisibility}
            />
          </Modal>
        ) : (
          <></>
        )}

        {modalVisibility && editProfile ? (
          <Modal onClose={closeModal}>
            <EditBrokerProfile
              updateBrokerProfileStatus={() => {}}
              broker={brokerData as any}
            />
          </Modal>
        ) : (
          <></>
        )}

        <div>
          <h2 className="text-sm font-mont text-darkBlue pb-2 uppercase">
            perfil empresa
          </h2>
          <h1 className="text-xxxl font-medium font-mont text-brand tracking-widest pb-10">
            {name}
          </h1>
        </div>

        <div className="pb-12">
          <h3 className="text-xl font-mont text-darkBlue pb-6 font-medium">
            {locale.companyIdentification}
          </h3>
          <div className="grid grid-cols-3 items-start">
            <div>
              <h4 className="text-sm font-mont text-darkBlue pb-1 font-medium leading-5 uppercase">
                {locale.companyName}
              </h4>
              <p className="pb-6 leading-7 font-lato">{name}</p>
              <h4 className="text-sm font-mont text-darkBlue pb-1 font-medium leading-5 uppercase">
                {locale.vat}
              </h4>
              <p className="pb-6 leading-7 font-lato">{vat}</p>
            </div>
            <div>
              <h4 className="text-sm font-mont text-darkBlue pb-1 font-medium leading-5 uppercase">
                {locale.phone}
              </h4>
              <p className="pb-6 leading-7 font-lato">{phone}</p>
              <h4 className="text-sm font-mont text-darkBlue pb-1 font-medium leading-5 uppercase">
                {locale.address}
              </h4>
              <p className="pb-6 leading-7 font-lato">
                {correspondance_address_line_1}
                <br />
                {correspondance_address_line_2}
                <br />
                {correspondance_address_line_3}
                <br />
                {correspondance_address_line_4}
              </p>
            </div>
            <div>
              <h4 className="text-sm font-mont text-darkBlue pb-1 font-medium leading-5 uppercase">
                {locale.documents}
              </h4>
              <div className="flex flex-col gap-3">
                {attachments && attachments?.length > 0 ? (
                  attachments.map((attachment: any, index: number) => {
                    const filesTitle = [
                      'Ficha de nomeação',
                      'Apólice de responsabilidade Civil',
                      'Relatório de Contas',
                      'Certificado de ASF de mediador',
                      'IBAN',
                    ]
                    return (
                      <Link
                        key={attachment.id}
                        to={attachment.url}
                        className="flex items-center text-brand"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="size-5 mr-2">
                          <Pdf />
                        </span>
                        <p className="text-sm align-middle ">
                          {filesTitle[index]}
                        </p>
                      </Link>
                    )
                  })
                ) : (
                  <p className="font-lato">Sem Documentos</p>
                )}
              </div>
            </div>
          </div>
          {isBrokerActive && (
            <div className="pt-2 pb-8">
              <FilledButton
                disabled={!isAdmin}
                label={locale.profile}
                action={() => {
                  setModayVisibility(true)
                  setViewEditProfile(true)
                  setViewInvitePopin(false)
                }}
              />
            </div>
          )}
        </div>

        <>
          {!isBrokerActive && (
            <>
              <h1 className="text-xxl font-medium font-mont text-red-500">
                {`O perfil da empresa encontra-se em estado ${brokerStatusString} no portal`}
              </h1>
            </>
          )}
          {isBrokerActive && (
            <>
              <div className="pb-12">
                <h3 className="font-mont text-darkBlue pb-6 font-medium text-xl">
                  {locale.users}
                </h3>

                <Table
                  columns={[
                    {
                      label: locale.name,
                      property: 'forename',
                      sortable: false,
                      format: (user: BrokerUserType) => user.forename,
                    },
                    {
                      label: locale.email,
                      property: 'email',
                      sortable: false,
                      format: (user: BrokerUserType) => user.email,
                    },
                    {
                      label: locale.role,
                      property: 'role',
                      sortable: false,
                      format: (user: BrokerUserType) =>
                        user.role === 'admin' ? 'Administrador' : 'Utilizador',
                    },
                    {
                      label: locale.status,
                      property: 'status',
                      sortable: false,
                      format: (user: BrokerUserType) => (
                        <Badge
                          status={user.status as Status}
                          label={user.status}
                        />
                      ),
                    },
                    {
                      label: locale.actions,
                      property: 'status1',
                      sortable: false,
                      format: (user: any) => (
                        <>
                          <FilledButton
                            disabled={!isAdmin}
                            label={
                              isBrokerActive
                                ? locale.inactivate
                                : locale.activate
                            }
                            action={() =>
                              handleUserStatus({
                                id: user.id,
                                status: isBrokerActive ? 'inactive' : 'active',
                              })
                            }
                          />
                        </>
                      ),
                    },
                  ]}
                  data={brokerData.user as any}
                />
                <div className="flex pt-4 pb-8 justify-end">
                  <FilledButton
                    disabled={!isAdmin}
                    label={`${locale.invite} ${locale.user}`}
                    action={() => {
                      setModayVisibility(true)
                      setViewEditProfile(false)
                      setViewInvitePopin(true)
                    }}
                  />
                </div>
              </div>

              <div className="pb-12">
                <h3 className="font-mont text-darkBlue pb-6 font-medium text-xl">
                  {locale.invites}
                </h3>

                <Table
                  columns={[
                    {
                      label: locale.name,
                      property: 'name',
                      sortable: false,
                      format: (invite: UserInvite) => invite.name,
                    },
                    {
                      label: locale.email,
                      property: 'email',
                      sortable: false,
                      format: (invite: UserInvite) => invite.email,
                    },
                    {
                      label: locale.created_at,
                      property: 'accountCreatedOn',
                      sortable: false,
                      format: (invite: UserInvite) =>
                        formatDate(invite.created_at).dashed,
                    },
                    {
                      label: locale.role,
                      property: 'role',
                      sortable: false,
                      format: (invite: UserInvite) =>
                        invite.role === 'admin'
                          ? 'Administrador'
                          : 'Utilizador',
                    },
                    {
                      label: locale.status,
                      property: 'status',
                      sortable: false,
                      format: (invite: UserInvite) => (
                        <Badge
                          status={invite.status as Status}
                          label={
                            invite.status === 'active'
                              ? 'submitted'
                              : invite.status
                          }
                        />
                      ),
                    },
                    {
                      label: locale.actions,
                      property: 'Action',
                      sortable: false,
                      format: (invite: UserInvite) => (
                        <span className="flex gap-2">
                          <FilledButton
                            disabled={!isAdmin}
                            label={
                              invite.status === 'active'
                                ? locale.inactivate
                                : locale.activate
                            }
                            action={() =>
                              handleInviteStatus({
                                id: invite.id,
                                status:
                                  invite.status === 'active'
                                    ? 'inactive'
                                    : 'active',
                              })
                            }
                          />
                          <FilledButton
                            disabled={!isAdmin}
                            label="Eliminar"
                            action={() => handleDeleteInvite(invite.id)}
                          />
                        </span>
                      ),
                    },
                  ]}
                  data={brokerData.invite}
                />
              </div>
            </>
          )}
        </>
      </Wrapper>
    </div>
  )
}
