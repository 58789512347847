import { FilledButton, LoaderInline } from 'components'
import { Search } from 'components/Icons'
import { Link } from 'react-router-dom'

export const ClientPolicies = ({ policies }: { policies: any }) => {
  /* 
  {
    "Nº da Apólice": 2512202,
    "NIF": "194538753",
    "Tomador": "Sandro Martins",
    "Morada": "Rua Dr. Germano Sousa Vieira nº21, Nogueira da Maia, 4475-471 Nogueira da Maia, Portugal",
    "Frequência": "Anual",
    "Forma de Pagamento": "Aviso de Recibo",
    "Estado": "Active",
    "Produto": "Intermediação de Crédito (excepto Imobiliário)",
    "Moeda": "EUR",
    "Prémio Anual": 180,
    "Data de Inicio": "2018-09-27",
    "Data da Renovação": "2025-09-27",
    "Capital Seguro": 250000,
    "LastEvent": 33
} */

  if (!policies) return <LoaderInline />

  const sortedPolicies = policies.sort((a: any, b: any) =>
    b['Data de Inicio'].localeCompare(a['Data de Inicio']),
  )

  return (
    <div>
      <h3 className="text-darkBlue text-lg font-mont font-medium pb-8">
        Tem {policies.length} {policies.length === 1 ? 'apólice' : 'apólices'}{' '}
        em vigor
      </h3>

      {sortedPolicies.slice(0, 3).map((policy: any) => {
        return (
          <div
            key={`police_${policy['Nº da Apólice']} grid grid-cols-[1fr_auto] items-center top-0 left-0 sticky z-10 gap-4`}
          >
            <div className="flex flex-wrap items-center gap-4">
              <Link to={`/policies/${policy['Nº da Apólice']}`}>
                <FilledButton
                  extraClasses="!text-darkBlue !w-[34px] !h-full"
                  label=""
                >
                  <Search className="size-5" />
                </FilledButton>
              </Link>
              <div>
                <h4 className="text-sm font-medium text-darkBlue uppercase tracking-widest">
                  {policy['Produto']}
                </h4>
                <p className=" text-black ">
                  Nº contrato {policy['Nº da Apólice']}
                </p>
              </div>
            </div>
          </div>
        )
      })}

      {policies.length > 3 && (
        <div className="flex items-center justify-end">
          <Link to="/policies">
            <FilledButton
              label="Ver mais"
              extraClasses="!bg-transparent !text-brand"
            />
          </Link>
        </div>
      )}
    </div>
  )
}
