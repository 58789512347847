import * as Yup from 'yup'

export const INSTALLMENTS_OPTIONS = [
  {
    value: 0,
    label: 'Mensal',
  },
  {
    value: 1,
    label: 'Trimestral',
  },
  {
    value: 2,
    label: 'Semestral',
  },
  {
    value: 3,
    label: 'Anual',
  },
]

export const PAYMENT_OPTIONS = [
  {
    value: 0,
    label: 'Multibanco',
  },
  {
    value: 1,
    label: 'Transferência Bancária',
  },
  {
    value: 2,
    label: 'Débito Direto',
  },
]

export const ADDRESSFIELDS = [
  'ADDRESS1',
  'ADDRESS2',
  'ADDRESS3',
  'ADDRESS4',
  'POSTCODE',
]

export const validationSchema = Yup.object().shape({
  ADDRESS1: Yup.string().required('Obrigatório'),
  ADDRESS3: Yup.string().required('Obrigatório'),
  POSTCODE: Yup.string().required('Obrigatório'),
})
