import { Eye } from 'components/Icons/eye'
import { EyeSlash } from 'components/Icons/eyeslash'
import { useState } from 'react'

export const NewFormInput = ({
    formik,
    label,
    required,
    placeholder,
    name,
    type,
    extraClasses,
    inline,
    disabled,
    onChange,
}: {
    formik: any
    label: string
    required?: boolean
    placeholder?: string
    name: string
    type: string
    extraClasses?: string
    inline?: boolean
    disabled?: boolean
    onChange?: () => void
}) => {
    const [visible, setVisible] = useState(false)
    const { error, touched } = formik.getFieldMeta(name)
    const { id } = formik.getFieldProps(name)

    const typeFunc = (type: string) => {
        if(type === "password" && visible)
            return "text"
        return type
    }

    return (
        <div className={`flex flex-col ${extraClasses}`}>
            <div
                className="relative"
            >
                <input
                    id={id || name}
                    className={`peer block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent placeholder-transparent border-0 border-b-2 border-gray-300 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600`}
                    type={typeFunc(type)}
                    placeholder={label}
                    disabled={disabled ? disabled : false}
                    onChange={
                        onChange
                            ? onChange
                            : value => formik.setFieldValue(name, value.target.value)
                    }
                />
                <label
                    htmlFor={id || name}
                    className={`absolute left-0 -top-3.5 transition-all peer-placeholder-shown:top-2 peer-focus:-top-3.5`}
                >
                    {label}
                    {required && <span className="text-brand">*</span>}
                </label>
                <button
                className="absolute top-3.5 right-1 bg-transparent border-none outrline-none cursor-pointer [&_svg]:size-4"
                hidden={type !== 'password'}
                onClick={e => {
                    e.preventDefault()
                    setVisible(!visible)
                }}
                >
                {!visible ? <EyeSlash /> : <Eye />}
                </button>
            </div>
            {touched && error ? (
                <span className="text-red text-xs mt-1">{error}</span>
            ) : null}
        </div>
    )
}