import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import pt from 'date-fns/locale/pt'
registerLocale('pt', pt)
import { FilterType } from '.'
import { Calendar } from 'components/Icons/calendar'

export const DateFilter = ({
  filter,
  formik,
  extraClasses,
}: {
  filter: FilterType
  formik: any
  extraClasses?: string
}) => {
  const field = formik.getFieldProps(filter.id)
  return (
    <div key={filter.id} className={`relative ${extraClasses}`}>
      <label
        htmlFor={filter.id}
        className="absolute -top-2 left-4 inline-block bg-lightGrey px-1 text-xs font-medium text-gray-900 z-[99]"
      >
        {filter.name}
      </label>
      <div
        id={filter.id}
        className={`group inline-flex justify-between items-center border border-gray-400 rounded-xl px-4 py-2 w-full text-gray-900 text-base font-lato focus:shadow-none focus:ring-0 focus:outline-none`}
      >
        <DatePicker
          locale={pt}
          dateFormat="dd/MM/yyyy"
          selected={field.value}
          onChange={(date: any) =>
            formik && formik.setFieldValue(filter.id, date)
          }
          className={`bg-transparent focus:shadow-none focus:ring-0 focus:outline-none ${
            formik && formik.errors[filter.id]
              ? 'border-error'
              : 'border-gray-400'
          }`}
          todayButton="Hoje"
          isClearable={true}
          clearButtonClassName="!mx-auto !p-0 after:!bg-gray-400"
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={15}
          useShortMonthInDropdown
        />
        {formik && formik.getFieldProps(filter.id).value === null ? (
          <span className="w-3 text-gray-400">
            <Calendar />
          </span>
        ) : null}
      </div>
    </div>
  )
}
