export type Status =
  | 'draft'
  | 'published'
  | 'deleted'
  | 'submitted'
  | 'pendent'
  | 'accepted'
  | 'pending'
  | 'rejected'
  | 'active'
  | 'inactive'
  | 'scheduled'
  | 'expired'

export const Badge = ({ status, label }: { status: Status; label: string }) => {
  let style: string
  switch (status) {
    case 'published':
    case 'accepted':
    case 'active':
      style = 'bg-success text-white border-success'
      break
    case 'rejected':
    case 'inactive':
    case 'deleted':
      style = 'bg-error text-white border-error'
      break
    case 'submitted':
    case 'scheduled':
      style = 'bg-info text-white border-info'
      break
    case 'pendent':
    case 'pending':
      style = 'bg-yellow text-white border-yellow'
      break
    default:
      style = 'bg-white text-black border-darkBlue'
  }
  return (
    <div
      className={`inline-block p-2 rounded-md border uppercase font-lato text-sm leading-3 ${style}`}
    >
      {label}
    </div>
  )
}
