import { FormDate, FormInput, FormSelect } from 'components'
import { locale } from 'locale'
import { Question } from 'types/simulators'

export const buildSimulatorForm = (
  type: string,
  question: Question,
  className: string,
  formik: any,
) => {
  switch (type) {
    case 'Alpha':
      return (
        <FormInput
          type="text"
          name={question.InputId}
          label={question.QuestionText ?? ''}
          formik={formik}
          inline
          extraClasses={className}
        />
      )
    case 'Number':
      return (
        <FormInput
          type="number"
          name={question.InputId}
          label={question.QuestionText ?? ''}
          formik={formik}
          inline
          extraClasses={className}
        />
      )
    case 'List':
      return (
        <FormSelect
          name={question.InputId}
          label={question.QuestionText ?? ''}
          allowEmpty
          placeholder={locale.selectPlaceholder}
          options={question.Answers?.map(answer => {
            return {
              label: answer.AnswerText,
              value: answer.AnswerCode,
            }
          })}
          formik={formik}
          inline
          extraClasses={className}
        />
      )
    case 'Date':
      return (
        <FormDate
          name={question.InputId}
          label={question.QuestionText ?? ''}
          formik={formik}
          inline
          extraClasses={className}
        />
      )
  }
}
