export const Send = ({ extraClasses }: { extraClasses?: string }) => (
  <svg
    className={extraClasses}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 18"
  >
    <g transform="translate(-1 -2)" fill="none">
      <mask id="w" fill="#fff">
        <path
          id="a"
          d="M1.675 17.5L19.167 10 1.675 2.5l-.008 5.833L14.167 10l-12.5 1.667z"
        />
      </mask>
      <g mask="url(#w)" fill="currentcolor">
        <path d="M-10.833-10.833h41.666v41.666h-41.666v-41.666z" />
      </g>
    </g>
  </svg>
)
