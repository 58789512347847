import { useState, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { AuthContext } from '../../contexts/AuthContext'

import {
  FilledButton,
  FormPassword,
  Modal,
  NotificationContainer,
  PasswordHelper,
  WrapperSmall,
  useNotification,
} from 'components'
import { locale } from 'locale'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { resetPassword } from 'services/auth'

const validationSchema = Yup.object().shape({
  password: Yup.string().min(6, locale.min6Chars).required(locale.required),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], locale.passwordsMustMatch)
    .required(locale.required),
})

export const ChangePassword = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const { notifications, notify } = useNotification()
  const [accountCreated, setAccountCreated] = useState<boolean>(false)

  const { isSignedIn } = useContext(AuthContext)

  const { mutateAsync: handleResetPassword } = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: async ({
      token,
      newPassword,
    }: {
      token: string
      newPassword: string
    }) => await resetPassword({ token, newPassword }),
    onSuccess: () => navigate('/login'),
    onError: error => notify(error.message),
  })

  const { mutateAsync: handleAccountCreation } = useMutation({
    mutationKey: ['createAccount'],
    mutationFn: async ({
      token,
      newPassword,
    }: {
      token: string
      newPassword: string
    }) => await resetPassword({ token, newPassword }),
    onSuccess: () => navigate('/login'),
    onError: error => notify(error.message),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      if (token) {
        await handleResetPassword({ token, newPassword: values.password })
      } else {
        alert('create account!')
      }
    },
  })

  if (isSignedIn) {
    navigate('/dashboard')
  }

  if (accountCreated) {
    return (
      <Modal onClose={() => {}}>
        <h2 className="font-mont text-brand font-medium pb-2">
          Password mudada com sucesso.
        </h2>

        <p className="pb-8">
          Pode fazer login com o email e a password que definiu.
        </p>

        <div className="flex justify-end">
          <FilledButton url="/" label={locale.goToLogin} />
        </div>
      </Modal>
    )
  }

  return (
    <div className="pt-4 pb-20">
      <NotificationContainer notifications={notifications} />
      <WrapperSmall>
        <form onSubmit={formik.handleSubmit}>
          <div className="pt-12 py-10 px-8 shadow-md">
            <h1 className="font-mont font-medium text-xl pb-4">
              {token ? 'Recuperar palavra-passe' : 'Criar conta'}
            </h1>
            <p className="font-lato text-lg pb-8">
              Defina a sua palavra-passe para ter acesso ao Portal Innova|GO.
            </p>

            <FormPassword
              label="Palavra-passe"
              name="password"
              formik={formik}
            />

            <FormPassword
              label="Repetir palavra-passe"
              name="passwordConfirmation"
              formik={formik}
            />

            <PasswordHelper password={formik.values.password}></PasswordHelper>

            <div className="flex items-end">
              <FilledButton type="submit" label="Submeter" />
            </div>
          </div>
        </form>
      </WrapperSmall>
    </div>
  )
}
