import { useFormik } from 'formik'
import * as Yup from 'yup'

import { locale } from 'locale'

import { FilledButton, FormInput, Loader } from 'components'

type BrokerType = {
  id: number
  name: string
  email: string
  vat: string
  phone: string
  correspondance_address_line_1: string
  correspondance_address_line_2: string
  correspondance_address_line_3: string
  correspondance_address_line_4: string
  correspondance_city: string
  correspondance_post_code: string
  created_at: string
  f1: any[]
  f2: any[]
  f3: any[]
  f4: any[]
  f5: any[]
  invites: {
    email: string
    name: string
    role: string
    status: string
  }[]
  users: {
    email: string
    forename: string
    id: number
    role: string
    status: string
  }[]
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(locale.required),
  phone: Yup.string().required(locale.required),
  vat: Yup.string()
    .matches(/^[0-9]+$/, locale.validation.onlyNumbers)
    .required(locale.required),
  correspondance_address_line_1: Yup.string().nullable(),
  correspondance_address_line_2: Yup.string().nullable(),
  correspondance_address_line_3: Yup.string().nullable(),
  correspondance_address_line_4: Yup.string().nullable(),
})

export const EditBrokerProfile = ({
  broker,
  updateBrokerProfileStatus,
}: {
  broker: BrokerType
  updateBrokerProfileStatus: any
}) => {
  const formik = useFormik({
    initialValues: {
      name: broker.name,
      email: broker.email?.toLowerCase(),
      phone: broker.phone,
      vat: broker.vat,
      correspondance_address_line_1: broker.correspondance_address_line_1,
      correspondance_address_line_2: broker.correspondance_address_line_2,
      correspondance_address_line_3: broker.correspondance_address_line_3,
      correspondance_address_line_4: broker.correspondance_address_line_4,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      updateBrokerProfileStatus({
        variables: {
          set: values,
          id: broker.id,
        },
      })
    },
  })

  if (!broker) {
    return <Loader />
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="font-mont font-medium text-xxxl pb-8">{locale.profile}</h2>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 items-end">
        <FormInput
          type="text"
          name="name"
          label={locale.name}
          formik={formik}
        />
        <FormInput type="text" name="vat" label={locale.vat} formik={formik} />
        <FormInput
          type="text"
          name="phone"
          label={locale.phone}
          formik={formik}
        />
        <div></div>
        <FormInput
          type="text"
          name="correspondance_address_line_1"
          label={locale.address}
          formik={formik}
        />
        <FormInput
          type="text"
          name="correspondance_address_line_2"
          formik={formik}
          label=""
        />
        <FormInput
          type="text"
          name="correspondance_address_line_3"
          formik={formik}
          label=""
        />
        <FormInput
          type="text"
          name="correspondance_address_line_4"
          formik={formik}
          label=""
        />
      </div>

      <div className="flex justify-end">
        <FilledButton type="submit" label={locale.submit} />
      </div>
    </form>
  )
}
