export const getNewQueryVars = (queryVariables: Record<string, unknown>) => {
  const keys = Object.keys(queryVariables)
  const newQueryVars = keys.reduce((acc, key) => {
    if (Boolean(queryVariables[key as keyof typeof queryVariables])) {
      return {
        ...acc,
        [key]: queryVariables[key as keyof typeof queryVariables],
      }
    }
    return acc
  }, {})

  return { ...newQueryVars }
}
