import { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'contexts/AuthContext'
import { LocaleType, locale } from 'locale'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  FilledButton,
  FormInput,
  Loader,
  LoaderInline,
  NotificationContainer,
  Wrapper,
  useNotification,
} from 'components'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  getPolicies,
  getPolicyAddress,
  getPolicyDocumentation,
  updatePolicyAddress,
} from 'services/policies'
import { PolicyAddress } from 'types/policies'
import { ADDRESSFIELDS } from './helpers/helper-broker-policy'
import { formatCurrency } from 'utils/currency'
import TransparentButton from 'components/Buttons/transparent'
import { useParams } from 'react-router-dom'
import { Client } from 'types/clients'

const validationSchema = Yup.object().shape({
  ADDRESS1: Yup.string().required('Obrigatório'),
  ADDRESS3: Yup.string().required('Obrigatório'),
  POSTCODE: Yup.string().required('Obrigatório'),
})

type ClientPoliciesFormProps = {
  ADDRESS1: string
  ADDRESS2: string
  ADDRESS3: string
  ADDRESS4: string
  POSTCODE: string
}

type RenderButtonsProps = {
  type: 'address'
  title: string
  condition: boolean
  callback: (arg: boolean) => void
}

export const PolicyClient = () => {
  const { policyNo } = useParams() as unknown as { policyNo: number }
  const { notifications, notify } = useNotification()

  const { currentUser } = useContext(AuthContext)
  const user = currentUser as unknown as Client
  const nif = user?.nif
  const [eventNo, setEventNo] = useState(0)
  const [changeAddress, setChangeAddress] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['getPolicy', { policyNo }],
    enabled: !!nif && !!policyNo,
    queryFn: () => getPolicies({ params: { nif, policyNo } }),
  })

  useEffect(() => {
    if (!!data) {
      setEventNo(data[0].LastEvent)
    }
  }, [data])

  const { data: policyAddress, isLoading: loadingPolicyAddress } = useQuery({
    queryKey: ['getPolicyAddress', { policyNo }],
    enabled: !!policyNo,
    queryFn: () => getPolicyAddress({ policyNo }),
  })

  const { mutateAsync: handleDownload, isPending: isPendingDownload } =
    useMutation({
      mutationKey: ['getPolicyDocs'],
      mutationFn: async () =>
        await getPolicyDocumentation({ policyNo: policyNo, eventNo: eventNo }),
      onError: () => notify('Ocorreu um erro', 'error'),
      onSuccess: data => {
        const blob = new Blob([data], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob))
      },
    })

  const {
    mutateAsync: updatePolicyAddressMutation,
    isPending: isPendingUpdateAddress,
  } = useMutation({
    mutationKey: ['updatePolicyAddress'],
    mutationFn: async ({
      id,
      data,
    }: {
      id: number
      data: { nif: string; address: PolicyAddress[] }
    }) =>
      await updatePolicyAddress({
        policyNo: id,
        address: data.address,
        nif: data.nif,
      }),
    onError: () => notify('Ocorreu um erro', 'error'),
    onSettled: () =>
      notify('O endereço foi actualizado correctamente', 'success'),
  })

  const getAddressInitialValues = (
    addressArray: PolicyAddress[],
  ): {
    ADDRESS1: string
    ADDRESS2: string
    ADDRESS3: string
    ADDRESS4: string
    POSTCODE: string
  } => {
    const fields = ADDRESSFIELDS.reduce((acc, field) => {
      const address = addressArray?.find(
        (item: { Question: string }) => item.Question === field,
      )
      return {
        ...acc,
        [field]: address?.Answer || '',
      }
    }, {})

    return fields as ClientPoliciesFormProps
  }

  const formik = useFormik<ClientPoliciesFormProps>({
    initialValues: {
      ...getAddressInitialValues(policyAddress!),
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      const adressValues = {
        ADDRESS1: values.ADDRESS1,
        ADDRESS2: values.ADDRESS2,
        ADDRESS3: values.ADDRESS3,
        ADDRESS4: values.ADDRESS4,
        POSTCODE: values.POSTCODE,
      }
      const address: PolicyAddress[] = Object.keys(adressValues).map(item => ({
        Question: item,
        Answer: values[item as keyof ClientPoliciesFormProps] as string,
        InputId: item,
      }))

      updatePolicyAddressMutation({
        id: policyNo,
        data: { address, nif: nif?.toString()! },
      })
    },
  })
  if (isLoading || loadingPolicyAddress) return <Loader />

  const renderPolicyData = () => {
    const policy = data[0]
    const policyDetails = Object.keys(policy).map(key => {
      // we don't want to show the LastEvent key
      if (key === 'LastEvent') return

      const premiumKeys = ['Prémio Anual', 'Capital Seguro']
      const value = premiumKeys.includes(key)
        ? formatCurrency(policy[key])
        : policy[key]

      return {
        label: key,
        value,
      }
    })
    return (
      <table className="border-collapse w-full border-none">
        <tbody>
          {policyDetails
            //need to filter out the undefined values (LastEvent key)
            .filter(Boolean)
            .map(({ label, value }: any, index: number) => (
              <tr key={`row${index}`} className="cursor-default h-8">
                <td className="text-grey font-lato text-sm pl-4">{label}</td>
                <td className="text-end text-black font-lato text-sm font-semibold pr-4 border-t border-lightGrey">
                  {value}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    )
  }

  const buttonsData: RenderButtonsProps[] = [
    {
      type: 'address',
      title: 'Alterar Dados Pessoais e de Pagamento',
      condition: changeAddress,
      callback: setChangeAddress,
    },
  ]

  const renderButtons = ({
    title,
    condition,
    callback,
  }: RenderButtonsProps) => (
    <div key={title} className="bg-lightGrey p-6 mb-10">
      <h4 className="text-lato text-sm text-darkBlue pb-6 font-medium uppercase">
        {title}
      </h4>
      <div>
        {Object.keys(formik.values).map(item => {
          if (!ADDRESSFIELDS.includes(item)) return
          return (
            <FormInput
              extraClasses={`${condition ? 'block' : 'hidden'}`}
              label={locale[item as LocaleType] as string}
              key={`key${item}`}
              formik={formik}
              name={item}
              type="text"
            />
          )
        })}
      </div>

      <div
        className={`flex justify-between items-center ${
          condition ? 'block' : 'hidden'
        }`}
      >
        {isPendingUpdateAddress ? (
          <LoaderInline />
        ) : (
          <FilledButton type="submit" label="Gravar Alterações" />
        )}
        <TransparentButton
          type="button"
          className="!text-brand border-brand !rounded h-fit !py-2 !text-sm font-medium"
          label="Cancelar"
          action={() => callback(false)}
        />
      </div>
      <FilledButton
        className={`${condition ? 'hidden' : 'block'}`}
        label="Alterar"
        action={() => callback(true)}
      />
    </div>
  )

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue pb-10 pl-4">
          Apólice
        </h2>
        <div className="grid md:grid-cols-2 md:divide-x-2 divide-cloudyBlue justify-center items-start">
          <div className="md:pr-8">{renderPolicyData()}</div>
          <div className="md:pl-8">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-10 bg-lightGrey p-6">
                <h4 className="text-lato text-sm text-darkBlue pb-4 font-medium uppercase">
                  Documentação da Apólice
                </h4>

                {isPendingDownload ? (
                  <LoaderInline />
                ) : (
                  <FilledButton
                    label="Descarregar PDF"
                    action={handleDownload}
                  />
                )}
              </div>
              {buttonsData.map(renderButtons)}
            </form>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
